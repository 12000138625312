import React, { useState, useRef } from 'react'
import { useIntl } from 'react-intl'
import Modal from '@/components/Modal'
import Loading from '@/components/Loading'
import Toast from '@/components/Toast'
import commonApi from '@/api/commonApi'
import css from './main.module.scss'

import iosCode from '@/assets/images/ios.png'
import androidCode from '@/assets/images/android.png'

const Footer: React.FC = () => {
  const intl = useIntl()
  const [ contactVisible, setContactVisible ] = useState<boolean>(false)
  const [ loading, setLoading ] = useState<boolean>(false)
  const [ codeVisible, setCodeVisible ] = useState<boolean>(false)
  const [ agreementVisible, setAgreementVisible ] = useState<boolean>(false)
  const [ content, setContent ] = useState<string>('')
  
  const toMail = ()=>{
    let param = {
      "mails": "aaronw@autobondinc.com",
      "subject": "partsbond",
      "content": content
    }
    setLoading(true)
    commonApi.mail(param).then((result: any)=>{
      setLoading(false)
      if(result.code === 1){
        Toast('succeeded！')
        setContactVisible(false)
      }
    })
  }

  return (
    <>
      <div className={css['footer']}>
        {intl.formatMessage({id: 'copyright'})}. <a href='https://api.partsbond.com'>{intl.formatMessage({id: 'api'})}</a>, <span onClick={()=>setCodeVisible(true)}>{intl.formatMessage({id: 'app'})}</span>, <a href='https://autobondinc.com/about.html'>{intl.formatMessage({id: 'about'})}</a>, <span onClick={()=>setContactVisible(true)}>{intl.formatMessage({id: 'contact'})}</span>, {intl.formatMessage({id: 'and'})} <span onClick={()=>setAgreementVisible(true)}>{intl.formatMessage({id: 'privacy'})}</span>. 
      </div>

      <Modal
        title={intl.formatMessage({id: 'feedback'})}
        visible={contactVisible}
        cancel={()=>setContactVisible(false)}
        width={420}
      >
        <div className={css.contact}>
          <textarea rows={6} placeholder={intl.formatMessage({id: 'writeOpinion'})} onChange={(e)=>setContent(e.target.value)}></textarea>
          <div className={css.email}>{intl.formatMessage({id: 'sentUsEmail'})}</div>
          <div className={css.but}>
            <button onClick={()=>toMail()}>
              {
                loading ? <span style={{width: 60, height: 20}}><Loading visible={true} type='mini' color='#fff' /></span> : null
              }
              {intl.formatMessage({id: 'sentUs'})}
            </button>
          </div>
        </div>
      </Modal>

      <Modal
        title=''
        visible={codeVisible}
        cancel={()=>setCodeVisible(false)}
        width={420}
      >
        <div className={css.code}>
          <div>
            <img src={iosCode} />
            <p>{intl.formatMessage({id: 'iosDownload'})}</p>
          </div>
          <div>
            <img src={androidCode} />
            <p>{intl.formatMessage({id: 'androidDownload'})}</p>
          </div>
        </div>
      </Modal>

      <Modal
        title='Privacy Agreement and Terms'
        visible={agreementVisible}
        cancel={()=>setAgreementVisible(false)}
        width={1000}
      >
        <div className={css.agreement}>
          <h6>“Part sbond”™ EPC Query System User Agreement"</h6>
          <p>This agreement is between you and the Part sbond Parts Technology Co., Ltd. (hereinafter referred to as "Cloud Auto Parts™"), the owner of the Part sbond Parts Query System (hereinafter referred to as "Part sbond Parts™"). Please read this registration agreement carefully. After you click the "I have read and accept the "Part sbond™ EPC Query System User Agreement" button, this agreement will be binding on both parties. legal documents.</p>

          <h6>“Part sbond” Confirmation and Acceptance of Terms of Service</h6>
          <p>The ownership and operation rights of various data query services of Part sbond are owned by Cloud Auto Parts™. The user agrees to all the terms of the registration agreement and completes the registration and product activation procedures to become an official user of Part sbond™. The user confirms that the rights and obligations of both parties are dealt with in the terms of this agreement, which is always valid. If there are other mandatory provisions of the law or other special agreements between the two parties, the provisions shall be followed.</p>
          <p>If the user clicks to agree to this agreement, it is deemed that the user has confirmed that he has the corresponding rights and behavioral capacity to enjoy the ZEROQI data query service, and can independently assume legal responsibilities.</p>
          <p>Cloud Auto Parts™ reserves the right to independently decide on/off the brand, refuse service, close user accounts, delete or edit content within the scope permitted by the laws implemented in the mainland of the People's Republic of China.</p>

          <h6>“Part sbond”services</h6>
          <p>Part sbond provides users with Internet information and other services through the Internet in accordance with the law. Users have the right to use Part sbond’s related services only if they fully agree with this agreement and the relevant provisions of Part sbond.</p>
          <p>All the information provided by Part sbondo™ is for reference only, please use it with caution. If the user suffers (but is not limited to) transaction losses in the process of using this data, Part sbond shall not be held responsible.</p>
          <p>Part sbond™ currently supports running in a variety of system environments, and it is recommended to use Firefox and Google Chrome browsers.</p>
          <p>The data coverage supported by Part sbond™ query is subject to the official announcement.</p>
          <p>In order to ensure the healthy operation of Part sbond™ service environment, Part sbond™ system has operational flow analysis and restriction rules, and there are certain restrictions on the daily operation frequency of users. Part sbond™ can be registered and purchased. If you maliciously capture data or improperly use Part sbond         Vehicle™, the user will bear the consequences of being banned. Part sbond has the right to terminate the service if it refuses to make corrections after being warned.</p>

          <h6>User Info</h6>
          <p>In order to protect your rights, please ensure the authenticity, accuracy and completeness of the registration information. Cloud Auto Parts™ has the right to review the user information. If the registration information provided is inconsistent, untrue, inaccurate or incomplete , the user shall bear the corresponding responsibilities and consequences arising therefrom, and Cloud Auto Parts™ reserves the right to terminate the user's use of </p>
          <p>User Info Parts™ services.</p>
          <p>Cloud Auto Parts™ will keep the registration information filled in by the user, including the user's real name/name, mobile phone number, e-mail and other private information strictly confidential. Unless authorized by the user or otherwise provided by law, Cloud Auto Parts will not disclose it to the outside world User Info.</p>
          <p>After the user registers and successfully activates the ZeroVehicle™, the user should save and use his user name and password in a prudent and reasonable manner. If the user finds any illegal use of the user account or the existence of security loopholes, please contact Cloud Auto Parts™ immediately to change the authentication information.</p>
          <p>The user agrees that Cloud Auto Parts™ has the right to notify the users registered on this site of related promotional activities and other information of Part sbond Parts™ by email, text message, telephone, etc.</p>
          <p>The user agrees that Cloud Auto Parts™ has the right to use the user's registration information, accessories query data and other information, log in to the user's registered account, and carry out evidence preservation, including but not limited to notarization, witness, etc.</p>

          <h6>The user's obligation of words and deeds according to law</h6>
          <p>This agreement is formulated in accordance with relevant national laws and regulations, and the user agrees to strictly abide by the following business:</p>
          <p>Do not transmit or publish: speeches that incite resistance, undermine the implementation of the Constitution, laws, and administrative regulations, incite subversion of state power, overthrow the socialist system, incite speeches to split the country, undermine national unity, incite ethnic hatred, ethnic discrimination, and destroy ethnic groups a speech of solidarity;</p>
          <p>Users are not allowed to perform reverse engineering, malicious multi-frequency inquiries and other behaviors on Zero Zero Auto™ to capture and reorganize data</p>
          <p>not instigate others to engage in conduct prohibited by this article;</p>
          <p>Users should pay attention to and abide by the various legal rules and regulations published or modified by this site from time to time.</p>
          <p>This site reserves the right to delete all kinds of information content that does not comply with legal policies or untrue information on the site without notifying users.</p>
          <p>If the user fails to comply with the above provisions, Cloud Auto Parts™ has the right to make an independent judgment and take measures such as suspending or closing the user account. Users must take legal responsibility for their own inquiries when using Zero Auto™.</p>

          <h6>Agreement Update and User Care Obligation</h6>
          <p>According to changes in national laws and regulations and the operational needs of Part sbond Parts, the mall has the right to modify the terms of this agreement from time to time. Once the revised agreement is posted on Part sbond or any official channel, it will take effect and replace the original agreement. Users can log in to check the latest agreement at any time; users are obliged to pay attention to and read the latest version of the agreement and any official announcement from time to time. If the user does not agree with the updated agreement, he can and should immediately stop accepting the services provided by RYTON® in accordance with this agreement; if the user continues to use the services provided by RYTON®, it is deemed to agree to the updated agreement. Cloud Auto Parts™ recommends that you read this agreement and the announcement of this site before using Part sbond Parts™. If any provision of this Agreement is deemed void, void or unenforceable for any reason, that provision shall be deemed severable and shall not affect the validity and enforceability of any remaining provisions.</p>

          <h6>Governing Law and Applicability</h6>
          <p>The conclusion, execution and interpretation of this agreement and the settlement of disputes shall be governed by the valid laws applicable in the mainland of the People's Republic of China (but not including its conflict of laws rules). In the event of a conflict between this Agreement and applicable law, these terms will be completely reinterpreted in accordance with the law, and other valid terms will remain in effect. If there is any dispute between the parties regarding the content of this agreement or its implementation, both parties shall try their best to resolve it through friendly negotiation; if the negotiation fails, either party may file a lawsuit with the competent court of the People's Republic of China.</p>
        </div>
      </Modal>
    </>
    
  )
}

export default Footer;