import React from 'react'
import { Route, Switch, Redirect } from 'react-router-dom'
import Home from '@/page/Home';
import Cars from '@/page/Cars'
import Catalog from '@/page/Catalog'
import PartsPrice from "@/page/PartsPrice"

import Vin, { VinDetailPage, PartSearchPage } from '@/page/Vin';
import { Account, Purchase, Favorite, NewPurchase, Coupon, Message, History, Paypal } from '@/page/My'
import { ForgetPwd, Login, Register } from '@/page/Login'

import { Gltf } from '@/page/Tools'

const nav :{[key: string]: any} = {
  "/home": {
    title: 'home',
    component: Home
  },
  "/login": {
    title: 'login',
    component: Login
  },
  "/register": {
    title: 'register',
    component: Register
  },
  "/forgetPwd": {
    title: 'Forget Pwd',
    component: ForgetPwd
  },
  "/car": {
    title: "Car",
    component: Cars
  },
  "/catalog": {
    title: "Catalog",
    component: Catalog
  },
  "/group": {
    title: 'group',
    component: Vin
  },
  "/group/detail": {
    title: 'vin detail',
    component: VinDetailPage
  },
  "/partsprice": {
    title: 'part detail',
    component: PartsPrice
  },
  "/vin": {
    title: 'vin',
    component: Vin
  },
  "/vin/detail": {
    title: 'vin detail',
    component: VinDetailPage
  },
  "/search/detail": {
    title: 'vin detail',
    component: PartSearchPage
  },
  "/my/account": {
    title: 'acount',
    component: Account
  },
  "/my/purchase": {
    title: 'purchase',
    component: NewPurchase
  },
  "/my/coupon": {
    title: 'coupon',
    component: Coupon
  },
  "/my/message": {
    title: 'message',
    component: Message
  },
  "/my/favorite": {
    title: 'favorite',
    component: Favorite
  },
  "/my/history": {
    title: 'history',
    component: History
  },
  "/my/paypal": {
    title: 'paypal',
    component: Paypal
  },
  "/tools/gltf": {
    title: 'gltf',
    component: Gltf
  }
  
}

function Routes(){
  return (
    <Switch>
      {
        Object.keys(nav).map((key, index)=>{
          let item = nav[key]
          return (<Route exact key={index} path={key} component={item.component} />)
        })
      }
      <Redirect to="/home" />
    </Switch>
  )
}

export default Routes

