const tr = {
  locale: 'tr-TR',
  messages: {
    "inputVinBrand": "Lütfen çerçeve numarasını/parçası numarasını/markayı girin",
    "history": "Tarihi",
    "home": "ev sayfası",
    "catalog": "Vehicle model inquiry",
    "search": "arama",
    "dragFile": "Dosyaları tanımak için bu alana yapıştır ya da sürükleyin",
    "selectImage": "Resim Seç",
    "howWorks": "Nasıl çalıştığını anlayın.",
    "more": "more",
    "helpSection": "yardım",
    "freeTrialDay": "3 günlük özgür dava.",
    "account": "hesap",
    "purchase": "Alın.",
    "message": "haberler",
    "favorites": "collection",
    "feedbackMessage": "Feedback mesajı",
    "watch": "Favorit Listesi",
    "coupon": "Kupon",
    "signOut": "Log out",
    "register": "kayıt",
    "subscribe": "abonelik",
    "freeSearch": "Özgür arama",
    "inviteTips": "Özgür yıllık kartları almak için 10 arkadaşını davet etmek için düğme tıklayın",
    "invite": "davet et",
    "freeTrialTips": "Partsbond kullanmaya devam etmek için lütfen özgür bir deney başlatın",
    "freeTrial": "Özgür dava",
    "helpVideos": "Yardım video",
    "copyright": "Copyright@2020 Öyle mi? AutoBond Inc.All Rights Reserved",
    "api": "API",
    "app": "App",
    "about": "Bizim hakkımızda.",
    "contact": "Bizimle iletişim kur",
    "and": "ve",
    "privacy": "Özel ve Sırtlar",
    "feedback": "Feedback",
    "writeOpinion": "Fikrini yaz.",
    "sentUsEmail": "Email address: aaronw@autobondinc.com",
    "sentUs": "Bizimle iletişim kur",
    "iosDownload": "IOS İndirme",
    "androidDownload": "Android İndirme",
    "inputPNMR": "Lütfen parça numara/isim/model/remarkler girin",
    "view3D": "3D modelini görüntülemek için tıklayın",
    "again": "Bir kez daha",
    "pictures": "fotoğraf",
    "partsNumber": "Bölüm numarası",
    "partsName": "Bölüm İsmi",
    "priceRange": "Ödül menzili",
    "operation": "operasyon",
    "email": "posta kutusu",
    "vCode": "Kontrol kodu",
    "nPassword": "Yeni parola",
    "cNPassword": "Confirm new password",
    "submit": "Submit",
    "getCode": "Denetim kodu alın",
    "countdown": "{num}s",
    "inputEmail": "Lütfen e-posta adresinizi girin",
    "emailCorrectly": "Lütfen e-postayı doğru doldurun",
    "inputPassword": "Lütfen şifre girin",
    "passwordsInconsistent": "İki parola eşleşmez",
    "sendCodeSuccess": "İletişim başarılı",
    "agreeUserAgreement": "Lütfen Kullanıcı Anlaşmasına katılın.",
    "tryFree": "Özgür mahkeme parçaları",
    "tryFreeDay": "İçeri girdikten sonra, 7 günlük bir dava almak için özgür davaya tıklayın.",
    "registrationCodeSentSuccess": "Kayıt kodu başarıyla gönderildi! Eğer 1-2 dakika içinde kodu almazsanız, lütfen giriş kutunuzu yenilemeye çalışın ve spam dizini kontrol edin.",
    "findUs": "Bizi nasıl buldun?",
    "iAgree": "I agree",
    "userAgreement": "Kullanıcı Anlaşması",
    "brand": "brand",
    "sampleSearch": "Örnek arama",
    "passwordChangedSuccess": "Parola başarıyla değişti",
    "passwordChangedFailed": "Parola değiştirmesi başarısız oldu",
    "enterNewName": "Lütfen yeni bir kullanıcı adı girin",
    "userChangedSuccess": "Kullanıcı değiştirmesi başarılı",
    "userChangedFailed": "Kullanıcı bilgisini değiştirmedi",
    "fillName": "Lütfen önce kullanıcı adınızı doldurun",
    "vinBindingSuccee": "Çerçive numarası bağlaması başarılı",
    "success": "başarılı",
    "brandPackage": "Araba marka soruşturma paketi",
    "combo": "combination",
    "validTime": "Efektiv zaman",
    "cancelRenewal": "Güncellemeyi iptal et",
    "packageContent": "Paket",
    "bindFrame": "Bir numara daha bağla",
    "remainingQueries": "Kalan sorgular",
    "personalInformation": "kişisel bilgi",
    "loginAccount": "Giriş hesabı",
    "nickName": "alias",
    "modify": "değiştir",
    "bindingEmail": "Bağlam e- posta",
    "loginPassword": "Giriş paroli",
    "enterContent": "Girdi içeriği",
    "noCoupons": "Kuponlar kullanılmaz",
    "discountOperation": "Discount operation",
    "preferentialContent": "Bilgisayarı keşfet",
    "serviceLife": "hizmet hayatı",
    "user": "kullanıcı",
    "remarks": "İşaretler",
    "noPartsCollection": "Hiçbir parçası mevcut değil",
    "selectAll": "Hepsini Seç",
    "delete": "Sil",
    "vin": "Çerçive numarası",
    "oe": "OE",
    "detail": "detaylar",
    "noNews": "Haberler yok.",
    "feedbackDetails": "Tekrar veri bilgisi",
    "feedbackStatus": "Feedback durumu",
    "feedbackTime": "Feedback time",
    "questionType": "Sorun tipi",
    "problemDescription": "Sorun tarifi",
    "solved": "çözülmüş",
    "pending": "Bekle",
    "supplementaryQuestion": "Ekstra sorular",
    "endFeedback": "Geri dönüşünü bitir",
    "cancel": "iptal et",
    "invalidCoupon": "Diskont kodu bulamadı",
    "bindVin": "Bağlam {num} fotoğraf numarası",
    "annualSubscribe": "yıllık abonelik",
    "currencySymbol": "$",
    "enterNumber": "Please enter individual numbers in sequence",
    "boundRemaining": "{hasBound}/{kalan Bound} Çerçive numarası bağı [{has}/{remaining} Çerçive numarası bağı]",
    "boundDescribe1": "Bir hesaba çoklu aboneler uygulanabilir",
    "boundDescribe2": "Abunelik 1 takvim yılı için geçerli (satın tarihinden)",
    "boundDescribe3": "Kayıtlı kullanıcılar ve kayıtlı kullanıcılar arasındaki bağ kalıcı; Eğer VIN'lerini sonra bağlamak istiyorsanız, hesap detaylarınızda bunu yapabilirsiniz.",
    "allInOne": "ALL-IN-ONE",
    "asiaPack": "ASIA PACK",
    "euroPack": "EURO PACK",
    "usPack": "US PACK",
    "daySubscription": "Günlük abonelik",
    "monthSubscription": "Aylık abonelik",
    "annualSubscription": "yıllık abonelik",
    "use": "uygulama",
    "howGetCode": "Diskont kodu nasıl alınacak",
    "payPalWith": "PayPal with",
    "openingPermission": "Open permissions",
    "availablePackages": "Mevcut paketler",
    "bestMatch": "En iyi eşleşme",
    "priceLowHigh": "Ödül düşük ve yüksek",
    "priceHighLow": "Yüksek ve düşük fiyat",
    "pleaseLoginFirst": "Lütfen ilk girin",
    "here3DArea": "Bu 3D bölgesi.",
    "applicableVehicles": "Uygulaşılabilir araç modelleri",
    "applicableVehicle": "Otomatik için yeterli",
    "interchangeableParts": "Değiştirme parçaları",
    "condition": "durum",
    "all": "Tüm",
    "channel": "channel",
    "location": "position",
    "partsFound": "Bölümler",
    "noData": "Veri mevcut değil",
    "carsModels": "Model (Yıl)",
    "year": "yıl",
    "market": "pazar",
    "oeNumber": "OE numarası",
    "partName": "Bölüm İsmi",
    "count": "Numara",
    "partsPrice": "Bölüm fiyatlarının karşılaştırması",
    "directReplacement": "Direct replacement",
    "indirectReplacement": "Yanlış yerine",
    "priceTrend": "Ödül trenleri",
    "partPicture": "Bölüm resimleri",
    "brands": "brand",
    "replacement": "Değiştir",
    "applicableVeh": "Applicable Veh",
    "supplier": "teminatçı",
    "getIt": "alın",
    "go": "Go",
    "findResults": "{num} hakkında sonuçları bulun",
    "partOe": "OE",
    "mainGroup": "Ana grup",
    "subGroup": "Ana vücudunu bölüyorum.",
    "group": "grup",
    "configDetails": "Configuration details",
    "selectGroup": "Usta seç",
    "conflg": "bölüm",
    "picture": "fotoğraf",
    "mg": "MG",
    "sg": "SG",
    "illNo": "ILL-NO",
    "name": "isim",
    "remark": "İşaretler",
    "model": "Araç modeli",
    "selectConfigurationInformation": "Yapılandırma bilgisini seç",
    "groupOne": "İlk grup!",
    "lastGroup": "Son grup!",
    "list": "liste",
    "back": "return",
    "switchGroup": "değiştir",
    "notFilter": "Unfiltered",
    "filter": "filtr",
    "config": "bölüm",
    "pos": "POS",
    "qty": "QTY",
    "addWatch": "En sevdiğim listeye ekle",
    "brandPart": "Marka parçaları",
    "component": "assembly",
    "disclaimer": "Kaçırıcı: Sorgulama sonuçları sadece referans için.",
    "partDetail": "Bölüm detayları",
    "vinNotAvailable": "Araç kimlik kodu mevcut değil",
    "partNotAvailable": "Bölüm numarası mevcut değil",
    "lackModels": "Missing model",
    "dataNotComplete": "Tamamlanmış veri",
    "dataError": "veri hatası",
    "dataNotNew": "Veri yeterince yeni değil.",
    "slowSpeed": "Yavaş hızla",
    "noUniqueness": "Benzersiz değil.",
    "other": "diğer",
    "selectQuestion": "Seçim soru",
    "close": "kapat",
    "howVin": "Rak numaralarını nasıl görmek?",
    "howVin1": "Şahiz numarasını bul ve araç kimliği kodunu araç vücudunun ön camının önünde kaydedin.",
    "howVin2": "Kutu numarasını girin ya da bir resim yüklemek için kutu numarasını belirlemek için tıklayın",
    "howVin3": "Click on the query icon to query the box number",
    "howVin4": "Etiket ismini girin ve aramak için arama simgesini tıklayın",
    "findParts": "Bölümleri nasıl bulacağız?",
    "findParts1": "Temel yapılandırma bilgilerini görürmek için fareyi park alanı numarasından geçirin",
    "findParts2": "Vücut komponentleri, şases komponentleri ve motor komponentleri sorgulamak için 3D modeli kullanmak için bu simgeyi tıklayın",
    "findParts3": "Bölüm listesini girmek için ana grup ve grup seçin",
    "findParts4": "Asıl soru için ana grup ve kutu numarasını tekrar seçmek için tıklayın",
    "findParts5": "Parçayı seçmek için patlatılmış görüntülerin parçasını tıklayın",
    "howPart": "Bölüm numaralı sorgulama nasıl kullanılacak?",
    "howPart1": "Görüntü tanıma parçası numarasını girin ya da yükleyin, sormak için arama tıklayın",
    "howPart2": "Click on the icon to collect the parts into my collection. When the price of the parts changes, you will receive an email notification",
    "howPart3": "Bölümün son fiyat trenini görmek için simge üzerine tıklayın",
    "noHelp": "Yardıma ihtiyacım yok.",
    "understood": "Anladım.",
    "nextStep": "Sonraki adım",
    "clickCheckInvitation": "Davet durumunu görüntülemek için buraya tıklayın",
    "inviteFriendsGetPackage": "Yıllık paketi 540 dolara değerli 10 arkadaşına davet et!",
    "emailAddress": "E-posta adresi",
    "status": "Durum",
    "redeem": "değiştirme",
    "inviteRewardTips": "<p>Lütfen <br/><em>Hepsini bir Yıllık Alıştırma</em>Geri Döndür",
    "currentSubscriptionExpires": "Şimdiki paketinizin sona ermek tarihiniz",
    "confirm": "onaylayın",
    "putAway": "Put Away",
    "seeAll": "See All",
    "pageCannotEmpty": "The number of pages cannot be empty",
    "pleaseRegisterSignin": "Daha fazla araç verisini aramak için lütfen kayıt/girin",
    "price": "fiyat",
    "quantity": "miktarda",
    "pleaseRL": "Lütfen kayıtlardan sonra girin",
    "sign": "İçeri gir",
    "stay": "Giriş kalın",
    "forget": "Parola unut",
    "password": "parola",
    "RFFVS": "Özgür arama vin için kayıt",
    "cName": "Şirket İsmi",
    "basic": "Temel bilgi",
    "updateTime": "Güncelleme zamanı",
    "english": "English",
    "russian": "русск",
    'indonesian': 'IndonesiaName',
    'vietnamese': 'Tiếng Việt',
    'thai': 'คนไทย',
    'malaysia': 'Malaysia',
    'arabia': 'بالعربية',
    'spain': 'Espagne',
    'portugal': 'Portugal',
    'france': 'France',
    'Turkey': 'Türkçe',
    'korean': '한국어',
    'japanese': '日本語'
  }
}

export default tr