import React, { useState, useEffect, useRef, useContext } from 'react'
import { v4 as uuidv4 } from 'uuid'
import Upload from '@/components/Upload'
import Toast from '@/components/Toast'
import ugcApi from '@/api/ugcApi'

import styles from './tools.module.scss'

const Gltf: React.FC = () => {
  const [ uploadLoading, setUploadLoading ] = useState<boolean>(false)
  const [ uuid, setUuid ] = useState<string>(uuidv4())
  const [ modelUrl, setModelUrl ] = useState<string>('')

  const beforeUpload = (file: File, FileList: File[]) => {
    return new Promise((resolve, reject) => {
      resolve(file)
    })
  }


  const start = (result: any) => {
    if(!result.name.endsWith('mtl')){
      setUuid(uuidv4())
    }
  
    setUploadLoading(true)
  }
  const success = (result: any, file: File) => {
    if(result.code === 1){
      if(result.data.img_url.endsWith('mtl')){
        return
      }
      window.open(result.data.img_url, "_blank")
      setModelUrl(result.data.img_url)
      Toast('导出成功！')
      setUploadLoading(false)

    }else {
      setUploadLoading(false)
    }
  }
  const error = (result: any) => {
    Toast('error', 'warning')
    setUploadLoading(false)
  }
  return (
    <div className={styles.gltf}>
      <div>
        <Upload
          multiple
          parse={ugcApi.upload}
          setLoading={setUploadLoading}
          loading={uploadLoading}
          action={ugcApi.uploadPath}
          name="img"
          data={{pid: 'tools', brandCode: 'tools', uuid: uuid.replace(/\-/g, '')}}
          beforeUpload={beforeUpload}
          start={start}
          success={success}
          error={error}
        >
          <div className={styles.upload}>
            <div>
              <i className="iconfont icontop" />
              <p>请同时上传obj与mtl文件且文件小于5M</p>
            </div>
          </div>
        </Upload>
        {
          modelUrl ? <p style={{fontSize: 18, width: 400, textAlign: 'center'}}><a href={modelUrl} target="_blank">转换成功，如未自动下载，请点击下载</a></p> : null
        }
      </div>
    </div>
  )
}

export default Gltf