import React, { useEffect, useState } from 'react'
import { withRouter, useHistory } from 'react-router-dom'
import { useIntl } from 'react-intl'
import styles from './my.module.scss'
import Menu from './Mymenu'
import accountApi from '@/api/account'
import Spin from '@/components/Spin'
import Checkbox from '@/components/Checkbox'
import Button from '@/components/Button'


const Favorite: React.FC = (props) => {
  const intl = useIntl()
  const history = useHistory()
  const [list, setList] = useState<any[]>([])
  const [loading, setLoading] = useState<boolean>(false)
  const [allChecked, setAllChecked] = useState<boolean>(false)
  const [change, setChange] = useState<boolean>(false)

  useEffect(() => {
    getFavorite()
  },[])
  useEffect(() => {
    list.forEach(item => {
      item.checked = allChecked
    })
    setChange(!change)
  },[allChecked])

  const goto = (pid: string) => {
    history.push(`/partsprice?pid=${pid}`)
  }
  const getFavorite = () => {
    setLoading(true)
    accountApi.getFavorite().then((res: { code : number, data: any[] }) => {
      setLoading(false)
      if(res.code === 1) {
        res.data.forEach(item => item.checked = false)
        setList(res.data)
      }
    })
  }
  const setIndexCheck = (index: number, value: boolean) => {
    list[index].checked = value
    setChange(!change)
  }
  const del = (item?: any) => {
    let pid: string = ''
    let brandCode: string = ''
    if(item) {
      pid = item.pid
      brandCode = item.brandCode
    }else {
      list.forEach(item => {
        if(item.checked) pid = pid + ',' + item.pid
      })
    }
    accountApi.delFavorite({pid, brandCode}).then((res: { code : number }) => {
      if(res.code === 1) {
        getFavorite()
      }
    })
  }
  const btnDisable = () => {
    let checked
    list.forEach(item => {
      if(item.checked) checked = true
    })
    return allChecked || checked
  }
  return (
    <Menu
      tab={3}
    >
      <div className={styles['favorite']}>
        <div className={styles['table']}>
        <table className={styles['table-header']}>
          <thead>
            <tr>
              <th style={{width: "8%"}}></th>
              <th style={{width: "12%"}}>{intl.formatMessage({id: 'pictures'})}</th>
              <th style={{width: "20%"}}>{intl.formatMessage({id: 'partsNumber'})}</th>
              <th style={{width: "20%"}}>{intl.formatMessage({id: 'partsName'})}</th>
              <th style={{width: "20%"}}>{intl.formatMessage({id: 'priceRange'})}</th>
              <th style={{width: "20%"}}>{intl.formatMessage({id: 'operation'})}</th>
            </tr>
          </thead>
        </table>
        <div className={styles['list']}>
          <Spin loading={loading}></Spin>
          {
            list.map((item: any, index: number) => {
              return (
                <div key={index} className={styles['item']}>
                  <span style={{width: "8%"}}>
                    <Checkbox checked={item.checked} onChange={(value) => setIndexCheck(index, value)} />
                  </span>
                  <span style={{width: "12%"}}>
                    <img src={item.img} alt="" />
                  </span>
                  <span style={{width: "20%"}}>{item.pid}</span>
                  <span style={{width: "20%"}}>{item.name}</span>
                  <span style={{width: "20%"}}>{item.old_price}</span>
                  <span style={{width: "20%"}}>
                    <span className={styles['eyes']} onClick={() => goto(item.pid)}><i className='iconfont iconeye'/></span>
                    <span className={styles['del']} onClick={() => del(item)}><i className='iconfont iconshanchu1'/></span>
                  </span>
                </div>
              )
            })
          }
          {
            !list.length && (
              <div className={styles['empty']}>
                <span className={'iconfont iconlingjiantu'}></span>
                <p>{intl.formatMessage({id: 'noPartsCollection'})}</p>
              </div>
            )
          }
        </div>
        </div>
        {
          list.length ? (
            <div className={styles['table-footer']}>
              
                <Checkbox checked={allChecked} onChange={(value) => setAllChecked(value)}>{intl.formatMessage({id: 'selectAll'})}</Checkbox>
              
                <Button disabled={!btnDisable()} onClick={() => del()}><i  className='iconfont iconshanchu1'/> &nbsp;{intl.formatMessage({id: 'delete'})}</Button>
              
            </div>
          ): null
        }
      </div>
    </Menu>
    
  )
}

export default withRouter(Favorite)