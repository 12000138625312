const ms = {
  locale: 'ms-BN',
  messages: {
    "inputVinBrand": "Sila masukkan nombor atau Tanda VIN atau Bahagian",
    "history": "Rekod sejarah",
    "home": "Home",
    "catalog": "Katalog",
    "search": "Cari",
    "dragFile": "Tampal atau seret fail ke kawasan ini untuk pengenalan",
    "selectImage": "Pilih Imej",
    "howWorks": "Lihat bagaimana ia berfungsi",
    "more": "Lebih",
    "helpSection": "Seksyen Bantuan",
    "freeTrialDay": "Percubaan percuma 3 hari",
    "account": "Akaun",
    "purchase": "Beli",
    "message": "Mesej",
    "favorites": "Kegemaran",
    "feedbackMessage": "Mesej balas balik",
    "watch": "Senarai Perhatian",
    "coupon": "Coupon",
    "signIn": "Daftar Masuk",
    "signOut": "Tandatangan keluar",
    "register": "Daftar",
    "subscribe": "Tanggalkan",
    "freeSearch": "Carian bebas tersedia",
    "inviteTips": "Klik butang Panggilan 10 kawan untuk mendapatkan lulus tahun FREE",
    "invite": "Panggilan",
    "freeTrialTips": "Oh tidak! Nampaknya awak kehabisan carian percuma! Untuk terus menggunakan Partsbond, mula percubaan bebas anda",
    "freeTrial": "Percubaan bebas",
    "helpVideos": "Video Bantuan",
    "copyright": "Copyright@2020 AutoBond Inc.All Rights Reserved",
    "api": "API",
    "app": "Aplikasi",
    "about": "Tentang Kami",
    "contact": "Hubungi Kami",
    "and": "dan",
    "privacy": "& Terma Privasi",
    "feedback": "Beritahu kami apa yang anda fikir",
    "writeOpinion": "Tulis pendapat anda ...",
    "sentUsEmail": "dihantar kepada kita : aaronw@autobondinc.com",
    "sentUs": "Dihantar kepada kami",
    "iosDownload": "Muat turun IOS",
    "androidDownload": "Muat turun Android",
    "inputPNMR": "Sila masukkan Pid/Name/Model/Remark",
    "view3D": "Klik untuk Papar Model 3D",
    "again": "Sekali lagi",
    "pictures": "Gambar",
    "partsNumber": "",
    "partsName": "",
    "priceRange": "",
    "operation": "",
    "email": "",
    "vCode": "",
    "nPassword": "",
    "cNPassword": "",
    "submit": "",
    "getCode": "",
    "countdown": "{num}s ",
    "inputEmail": "",
    "emailCorrectly": "",
    "inputPassword": "",
    "passwordsInconsistent": "",
    "sendCodeSuccess": "",
    "agreeUserAgreement": "",
    "tryFree": "",
    "tryFreeDay": "",
    "registrationCodeSentSuccess": " ",
    "findUs": "",
    "iAgree": "",
    "userAgreement": "",
    "brand": "",
    "sampleSearch": "",
    "passwordChangedSuccess": "",
    "passwordChangedFailed": "",
    "enterNewName": "",
    "userChangedSuccess": "",
    "userChangedFailed": "",
    "fillName": "",
    "vinBindingSuccee": "",
    "success": "",
    "brandPackage": "",
    "combo": "",
    "validTime": "",
    "cancelRenewal": "",
    "packageContent": "",
    "bindFrame": "",
    "remainingQueries": "",
    "personalInformation": "",
    "loginAccount": "",
    "nickName": "",
    "modify": "",
    "bindingEmail": "",
    "loginPassword": "",
    "enterContent": "",
    "noCoupons": "",
    "discountOperation": "",
    "preferentialContent": "",
    "serviceLife": "",
    "user": "",
    "remarks": "",
    "noPartsCollection": "",
    "selectAll": "",
    "delete": "",
    "vin": "",
    "oe": "",
    "detail": "",
    "noNews": "",
    "feedbackDetails": "",
    "feedbackStatus": "",
    "feedbackTime": "",
    "questionType": "",
    "problemDescription": "",
    "solved": "",
    "pending": "",
    "supplementaryQuestion": "",
    "endFeedback": "",
    "cancel": "",
    "invalidCoupon": "",
    "bindVin": "",
    "annualSubscribe": "",
    "currencySymbol": "",
    "enterNumber": "",
    "boundRemaining": "",
    "boundDescribe1": "",
    "boundDescribe2": "",
    "boundDescribe3": " ",
    "allInOne": "",
    "asiaPack": "",
    "euroPack": "",
    "usPack": "",
    "daySubscription": "",
    "monthSubscription": "",
    "annualSubscription": "",
    "use": "",
    "howGetCode": "",
    "payPalWith": "",
    "openingPermission": "",
    "availablePackages": "",
    "bestMatch": "",
    "priceLowHigh": "",
    "priceHighLow": "",
    "pleaseLoginFirst": "",
    "here3DArea": "",
    "applicableVehicles": "",
    "applicableVehicle": "",
    "interchangeableParts": "",
    "condition": "",
    "all": "",
    "channel": "",
    "location": "",
    "partsFound": "",
    "noData": "",
    "carsModels": "",
    "year": "",
    "market": "",
    "oeNumber": "",
    "partName": "",
    "count": "",
    "partsPrice": "",
    "directReplacement": "",
    "indirectReplacement": "",
    "priceTrend": "",
    "partPicture": "",
    "brands": "",
    "replacement": "",
    "applicableVeh": "",
    "supplier": "",
    "getIt": "",
    "go": "",
    "findResults": "",
    "partOe": "",
    "mainGroup": "",
    "subGroup": "",
    "group": "",
    "configDetails": "",
    "selectGroup": "",
    "conflg": "",
    "picture": "",
    "mg": "",
    "sg": "",
    "illNo": "",
    "name": "",
    "remark": "",
    "model": "",
    "selectConfigurationInformation": "",
    "groupOne": "",
    "lastGroup": "",
    "list": "",
    "back": "",
    "switchGroup": "",
    "notFilter": "",
    "filter": "",
    "config": "",
    "pos": "",
    "qty": "",
    "addWatch": "",
    "brandPart": "",
    "component": "",
    "disclaimer": "",
    "partDetail": "",
    "vinNotAvailable": "",
    "partNotAvailable": "",
    "lackModels": "",
    "dataNotComplete": "",
    "dataError": "",
    "dataNotNew": "",
    "slowSpeed": "",
    "noUniqueness": "",
    "other": "",
    "selectQuestion": "",
    "close": "",
    "howVin": "",
    "howVin1": "",
    "howVin2": "",
    "howVin3": "",
    "howVin4": "",
    "findParts": "",
    "findParts1": "",
    "findParts2": "",
    "findParts3": "",
    "findParts4": "",
    "findParts5": "",
    "howPart": "",
    "howPart1": "",
    "howPart2": "",
    "howPart3": "",
    "noHelp": "",
    "understood": "",
    "nextStep": "",
    "clickCheckInvitation": "",
    "inviteFriendsGetPackage": " ",
    "emailAddress": "",
    "status": "",
    "redeem": "",
    "inviteRewardTips": "  ",
    "currentSubscriptionExpires": "",
    "confirm": "",
    "putAway": "",
    "seeAll": "",
    "pageCannotEmpty": "",
    "pleaseRegisterSignin": "",
    "price": "",
    "quantity": "",
    "pleaseRL": "",
    "sign": "",
    "stay": "",
    "forget": "",
    "password": "",
    "RFFVS": "",
    "cName": "",
    "basic": "",
    "updateTime": "",
    "english": "",
    "russian": "",
    'malaysia': 'Malaysia',
    'arabia': 'بالعربية',
    'spain': 'Espagne',
    'portugal': 'Portugal',
    'france': 'France',
    'Turkey': 'Türkçe',
    'korean': '한국어',
    'japanese': '日本語'
  }
}

export default ms