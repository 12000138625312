import React from "react";
import { clamp } from './utils'
import { SimplePageProps } from "./interface";
import PageInput from "./PageInput";
import styles from './simplePage.module.scss'

const SimplePage: React.FC<SimplePageProps> = (props) => {
  const { prefixCls = "page", current = 1, pageCount = 1, onChange } = props;
  const jump = (page: number) => {
    const num = clamp(page, 1, pageCount);
    if (onChange) {
      onChange(num);
    }
  }
  let prevClasses = styles[`${prefixCls}-simple-link`]
  if(current <= 1) {
    prevClasses += ' ' + styles['disabled']
  }
  let nextClasses = styles[`${prefixCls}-simple-link`]
  if(current === pageCount) {
    nextClasses += ' ' + styles['disabled']
  }
  return (
    <React.Fragment>
      <a className={prevClasses} onClick={() => jump(current - 1)}>
        <span className="icon iconfont">&#xe7ec;</span>
      </a>
      <span className={styles[`${prefixCls}-simple-jump`]}>
        <PageInput current={current} pageCount={pageCount} onChange={onChange} />
        <span className={styles[`${prefixCls}-slash`]}>／</span>
        {pageCount}
      </span>
      <a className={nextClasses} onClick={() => jump(current + 1)}>
        <span className="icon iconfont">&#xe7eb;</span>
      </a>
    </React.Fragment>
  );
}

export default React.memo(SimplePage);