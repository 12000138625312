import React from "react";
import { useControllableValue } from "ahooks";
import { CheckboxProps } from "./interface";
import styles from './radio.module.scss'

const CheckboxMini: React.FC<CheckboxProps> = (props) => {
  const { className, style, defaultChecked, indeterminate, children, type = "radio", ...rest } = props;
  const isControll = typeof(props.checked) == 'undefined' ? false : true
  const [checked, setChecked] = useControllableValue(props, {
    defaultValue: false,
    defaultValuePropName: 'defaultChecked',
    valuePropName: 'checked'
  });
  let prefixCls;
  if (!props.prefixCls) {
    prefixCls = `${type}`;
  }
  let classString = styles[`${prefixCls}`] + (className ? ` ${className}` : '')
  if(checked) {
    classString += ' ' + styles[`${prefixCls}-checked`]
  }
  if(props.disabled) {
    classString += ' ' + styles[`${prefixCls}-disabled`]
  }
  if(props.readOnly) {
    classString += ' ' + styles[`${prefixCls}-readonly`]
  }
  if(indeterminate) {
    classString += ' ' + styles[`${prefixCls}-indeterminate`]
  }

  function handleCheckChange(e: React.ChangeEvent<HTMLInputElement>) {
    if (props.disabled || props.readOnly || (type === "radio" && checked)) {
      return;
    }

    if (!isControll) {
      // 非受控组件将当前checked作为唯一数据源
      setChecked(e.target.checked);
    }
    if (props.onChange) {
      props.onChange(e.target.checked, props.value);
    }
  }

  function handleKeyPress(e: React.KeyboardEvent<HTMLInputElement>) {
    if (e.keyCode === 32) {
      handleCheckChange(e as any);
    }
  }

  return (
    <span className={classString} style={style}>
      <input {...rest} {...(isControll ? { checked } : { defaultChecked: checked })} type={type} aria-disabled={props.disabled} className={styles[`${prefixCls}-input`]} onChange={handleCheckChange} onKeyPress={handleKeyPress} />
      <span className={styles[`${prefixCls}-inner`]} />
    </span>
  );
}

export default React.memo(CheckboxMini);