import axios from 'axios'

const commonApi: any = {}

commonApi.vedioMark = (params: {}): Promise<{}> => {
  return axios.post("/v1/api/vedio/point", params)
}
commonApi.record = (params: {}): Promise<{}> => {
  return axios.post("/v1/api/operate/record", params)
}
commonApi.mail = (params: {}): Promise<{}> => {
  return axios.post("/v1/prob/send/prom/mails", params)
}

export default commonApi