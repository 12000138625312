import axios from 'axios';

const carApi = {
  getCList: (params: {}): Promise<{}> => {
    return axios.get('/v1/api/epc/cars/part/sort', {params})
  },
  getData: (params: {}): Promise<{}> => {
    return axios.post('/v1/api/epc/cars/search', params)
  },
  getFilterList: (params: {}): Promise<{}> => {
    return axios.get('/v1/api/epc/cars/part/class', {params})
  },
  getPartDetail: (params: {}): Promise<{}> => {
    return axios.get('/v1/api/epc/cars/part/detail', {params})
  },
  checkSingle: (params: {}):Promise<{}> => {
    return axios.get("/v1/epc/engine/parts/search/single", {params})
  },
  partBInfo: (params: {}): Promise<{}> => {
    return axios.get("/v1/epc/parts/baseinfo", {params})
  },
  otherPartInfo: (params: {}): Promise<{}> => {
    return axios.get("/v1/api/epc/cars/part/detail", {params})
  },
  polling: (params: {}): Promise<{}> => {
    return axios.get("/v1/parts/polling", {params})
  },
  single: (params: {}): Promise<{}> => {
    return axios.get("/v1/parts/single/polling", {params})
  },
}

export default carApi