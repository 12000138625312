import React from "react";
import { useControllableValue } from "ahooks";
import { GroupContext } from "./Context";
import { RadioGroupProps } from "./interface";
import styles from './radio.module.scss'

export const RadioGroup = React.forwardRef((props: RadioGroupProps, ref: React.MutableRefObject<any> | any) => {
  const { prefixCls = "radio-group", className, style, disabled, children, tabIndex, onBlur } = props;
  const isControll = typeof(props.value) == 'undefined' ? false : true
  const [value, setValue] = useControllableValue<any>(props);
  let classString = styles[`${prefixCls}`] + (className ? ` ${className}` : '')
  if(disabled) {
    classString += ' ' + styles[`${prefixCls}-disabled`]
  }

  function onChange(checked: boolean, val: any) {
    if (checked) {
      changeValue(val);
    } else {
      changeValue(null);
    }
  }

  function changeValue(val: any) {
    if (disabled) {
      return;
    }
    if (!isControll) {
      setValue(val);
    }
    if (props.onChange) {
      props.onChange(val);
    }
  }

  return (
    <GroupContext.Provider value={{ value, disabled, onChange }}>
      <div className={classString} style={style} tabIndex={tabIndex} onBlur={onBlur} ref={ref}>
        {children}
      </div>
    </GroupContext.Provider>
  );
});

export default React.memo(RadioGroup);