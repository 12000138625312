import axios from 'axios';

const ugcApi: any = {}

ugcApi.pics = (params: {}): Promise<{}>=>{
  return axios.get('/v1/api/user/ugc/pics', { params })
}

ugcApi.delPic = (params: {}): Promise<{}>=>{
  return axios.get('/v1/api/user/ugc/picdel', { params })
}
ugcApi.upload = (params: {}): Promise<{}>=>{
  return axios.post('/v1/api/user/ugc/picupload', params)
}
ugcApi.love = (params: {}): Promise<{}>=>{
  return axios.get('/v1/api/user/part/love', { params })
}
ugcApi.bad = (params: {}): Promise<{}>=>{
  return axios.get('/v1/api/user/part/bad', { params })
}
ugcApi.hasoperate = (params: {}): Promise<{}>=>{
  return axios.get('/v1/api/user/part/hasoperate', { params })
}
ugcApi.picdel = (params: {}): Promise<{}>=>{
  return axios.get('/v1/api/user/ugc/picdel', { params })
}

ugcApi.uploadPath = '/v1/api/user/ugc/picupload'

export default ugcApi