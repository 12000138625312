import React, { useReducer, useState, useEffect, useContext } from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import MainContext from '@/context/MainContext'
import { Header } from '@/page/Main';
import Button from '@/components/Button'
import Input, { InputPassword } from '@/components/Input'
import Checkbox from '@/components/Checkbox'
import Modal from '@/components/Modal'
import { useIntl } from 'react-intl'
import facebook from '@/assets/images/facebook.png'
import google from '@/assets/images/google.png'
import twitter from '@/assets/images/twitter.png'
import loginLogo from '@/assets/images/login_logo.png'
import accountApi from '@/api/account'
import { getUrlParams } from '@/utils/utils'
import styles from './lrf.module.scss'

interface PropsIn {
  type?: string
}

type LoginState = {
  username: string,
  password: string,
}

type LoginAction = 
| { type: "username", value: string }
| { type: "password", value: string }

type PositionState = {
  active_latitude: number | null,
  active_longitude: number | null
}
const reducer = (state: LoginState, action: LoginAction) => {
  switch(action.type) {
    case 'username':
      return {
        ...state,
        username: action.value
      };
    case 'password':
      return {
        ...state,
        password: action.value
      };
    default:
      return state
  }
}

const Login: React.FC<PropsIn> = (props) => {
  const { buy } = getUrlParams()
  const { pathname } = useLocation()
  const { type } = props
  const { setStore, store } = useContext(MainContext)
  const { loginVisible } = store
  let username = window.localStorage.getItem('username')
  let password = window.localStorage.getItem('password')
  const intl = useIntl()
  const history = useHistory();
  const [loginVal, setLoginVal] = useReducer(reducer, {
    username: username || '',
    password: password || ''
  })
  const [checked, setChecked] = useState<boolean>(true)
  const [loading, setLoading] = useState<boolean>(false)
  const [agreementVisible, setAgreementVisible] = useState<boolean>(false)
  
  const [position, setPosition] = useState<PositionState>({
    active_latitude: null,
    active_longitude: null
  })
  useEffect(() => {
    if(navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        function (position) {
          setPosition({
            active_longitude: position.coords.longitude,
            active_latitude: position.coords.latitude
          })
        }
      )
    }
  }, [])
  function loginSubmit(e: React.ChangeEvent<HTMLFormElement>) {
    e.preventDefault();
    if(!loginVal.username) {
      alert("Please input your email")
      return false
    }
    // if(loginVal.username.indexOf('@') === -1) {
    //   alert("Please fill in the email correctly")
    //   return false
    // }
    if(!loginVal.password) {
      alert('Please input your password')
      return false
    }
    if(checked) {
      window.localStorage.setItem('username', loginVal.username)
      window.localStorage.setItem('password', loginVal.password)
    }
    let params = {...loginVal, device: 'WEB'}
    if(typeof position.active_longitude === 'number' || typeof position.active_latitude === 'number') {
      Object.assign(params, position)
    }
    setLoading(true)
    accountApi.login(params).then((res: any) => {
      
      if(res.code === 1) {
        // getUserInfo()
        window.location.reload()
      }else {
        setLoading(false)
      }
    })
  }

  const getUserInfo = () => {
    accountApi.getUserInfo().then((result: {code: number, data: object}) => {
      setLoading(false)
      if(result.code === 1) {
        setStore({value: result.data, type: 'userInfo'})
        // let url = 'home'
        // if(buy){
        //   url += `?buy=${buy}`
        // }
        // getMsg(result.data)
        if(type === 'modal'){
          setStore({value: false, type: 'loginVisible'})
        }
        queryCount()
      }
    })
  }
  const getMsg = (userInfo: any) => {
    accountApi.msg().then((result: {code: number, data: object}) => {
      if(result.code === 1) {
        userInfo.msg = result.data
      }
      setStore({value: userInfo, type: 'userInfo'})
      if(type === 'modal'){
        setStore({value: false, type: 'loginVisible'})
      }
      queryCount()
    })
  }
  const queryCount = () => {
    accountApi.queryCount().then((result: any)=>{
      if(result.code === 1 && result.data?.freecount) {
        setStore({value: result.data.freecount, type: 'freecount'})
        if(type !== 'modal' || pathname.includes('/register')){
          history.push('/home')
        }
      }
    })
  }
  const cancel = () => {
    setStore({value: false, type: 'loginVisible'})
    if(pathname.includes('/vin')){
      history.push('/home')
    }
  }

  return (
    <div className={type !== 'modal' ? styles['LRF-page'] : styles['LRF-page'] + ' ' + styles.modal}>
      {
        type !== 'modal' ? <Header /> : null
      }
      
      <div className={styles['form-login']}>
        {
          type === 'modal' ? <i className='iconfont iconclose' style={{position: 'absolute', top: 10, right: 10, cursor: 'pointer', zIndex: 9}} onClick={()=>cancel()} /> : null
        }
        <div className={styles['form-wrap-img']}>
          <img src={loginLogo} alt="" />
        </div>
        <form className={styles['form-wrap']} name="login" onSubmit={loginSubmit}>
          {
            type === 'modal' ? <p className={styles.tips}>{(typeof(loginVisible) === 'string' ? <span dangerouslySetInnerHTML={{__html: loginVisible}}></span> : intl.formatMessage({id: 'pleaseRL'}))}</p> : null
          }
          <div className={styles['form-item']}>
            <Input type="text" size="large" placeholder={intl.formatMessage({id: 'email'})} defaultValue={loginVal.username} name="username" onChange={(value: string) => setLoginVal({value, type: 'username'})} />
          </div>
          <div className={styles['form-item']}>
            <InputPassword size="large" placeholder={intl.formatMessage({id: 'password'})} name="password" defaultValue={loginVal.password} onChange={(value: string) => setLoginVal({value, type: 'password'})}/>
          </div>
          <div className={styles['form-item-btns']}>
            <div>
              <Button loading={loading} type="primary" htmlType="submit" size="large" style={{width: "100%"}}>{intl.formatMessage({id: 'sign'})}</Button>
            </div>
            <div style={{marginTop: 12}}>
              <Button type="primary" ghost={true} size="large" style={{width: "100%"}} onClick={() => {history.push("/register"); setStore({value: false, type: 'loginVisible'})}}>{intl.formatMessage({id: 'register'})}</Button>
            </div>
          </div>
          <div className={styles['form-item-pop']}>
            <div style={{display: 'flex'}}>
              <Checkbox name="remember" checked={checked} onChange={checked => setChecked(checked)} ></Checkbox>
              <span className={styles['stay-login']} onClick={() => setAgreementVisible(true)}>{intl.formatMessage({id: 'stay'})}</span>
            </div>
            <span className={styles['to-forget-pwd']} onClick={() => {history.push("/forgetPwd"); setStore({value: false, type: 'loginVisible'})}}>{intl.formatMessage({id: 'forget'})}</span>
          </div>
        </form>
        {/* <div className={styles['auth-login-pop']}>Sign in with a social account</div>
        <div className={styles['auth-login-wrap']}>
          <span className={styles['auth-login-img-wrap']}>
            <img src={facebook} alt="" />
          </span>
          <span className={styles['auth-login-img-wrap']}>
            <img src={google} alt="" />
          </span>
          <span className={styles['auth-login-img-wrap']}>
            <img src={twitter} alt="" />
          </span>
        </div> */}
      </div>
      <Modal
        visible={agreementVisible}
        cancel={() => setAgreementVisible(false)}
        title="User Agreement"
      >
        <div className={styles['agreement']}>
          <pre>
            {
  `Partsbond EPC Inquiry System User Agreement

  This agreement is between you and Autobond technology co., LTD (hereinafter referred to as "Autobond") owner of Partsbond EPC inquiry system (hereinafter referred to as "Partsbond ") regarding Partsbond product services and related matters, please read this registration agreement carefully. After you click the button "I have read and accepted the 《Partsbond EPC inquiry system user agreement》", this agreement shall constitute a legal document binding on both parties.
  
  Version: October 2020 issued by Autobond

  § 1 Confirmation and acceptance of Partsbond terms of service
  1. All rights of copyright of Partsbond belongs to Autobond User agrees to all terms of the registration agreement and completes the registration and product activation procedures in order to become a formal user of Partsbond.User acknowledges that: the contract of rights and obligations between the parties shall remain valid at all times when the terms of this agreement are applied. If there are other mandatory provisions by law or special agreements between the parties, such provisions shall prevail.
  2. user clicks "agree" to this agreement, it shall be deemed that the user confirms that he/she has the corresponding rights and behavior ability to enjoy the Partsbond data query service and can bear legal responsibilities independently.
  3. Autobond reserves the right to make its own decisions regarding online/offline branding, denial of service, closing user accounts, deleting or editing content, to the extent permitted by law.
  
  § 2 Partsbond Services
  The Partsbond provides Internet information and other services to users through the Internet in accordance with the law. Users shall have the right to use the relevant services of Partsbond if they fully agree with this agreement and the relevant provisions of Partsbond.
  2.All information provided by Partsbond is for reference only, please use with caution. The Partsbond shall not be liable for any trading loss incurred by the user during the use of this data.
  3.Google Chrome are recommended to browse Partsbond website.
  4.The Partsbond supported data coverage is subject to the official notice.
  5.In order to ensure the healthy operation of Partsbond service environment, the Partsbond system has operation traffic analysis and restriction rules, which have certain restriction rules on the daily operation frequency of users. Users shall explicitly recognize and abide by this restriction before registration and purchase of Partsbond. In case of malicious grasping of data or improper use of Partsbond, the user will bear the consequences of being blocked. The Partsbond has the right to terminate the service if it refuses to correct after warning.
  
  § 3 User information
  1. In order to ensure your rights, please fill out the registration information authenticity, accuracy and completeness, Autobond has the right to the user data review, if not, the registration data provided untrue, inaccurate, not exhaustive, users need to assume corresponding responsibility and the resulting consequences, and Autobond reserves the right to terminate user USES Partsbond services.
  2.Autobond will keep the registration information entered by the user, including the user's real name, mobile phone number, email address and other private information, strictly confidential. Unless authorized by the user or otherwise stipulated by law, Autobond will not disclose user information to the outside world.  
  3.After the user registers and successfully activates Partsbond, the user should carefully and reasonably save and use the user name and password. If users find any illegal use of user accounts or security loopholes, please contact Autobond immediately to change the authentication information.
  4.The user agrees that Autobond has the right to send Partsbond related promotional activities and other information to the registered users in this site through email, SMS, telephone and other forms.
  5.The user agrees that Autobond has the right to use the user's registration information and accessories to query data and other information, log in and enter the user's registration account, and conduct evidence preservation, including but not limited to notarization and witness.
  
  § 4 Prices and Payment Conditions for Partsbond
  1. The royalty for Partsbond can be seen in the price list.
  2. All prices listed are including tax.
  3. Every subscription has a minimum duration as specified in the price list for the use of Partsbond. When users subscribe to Partsbond service for online payment, they shall pay through the third-party payment platform. Users should understand the risks of online payment and Autobond is not responsible for any theft of their payment account during payment.
  
  § 5 Users' obligations in accordance with the law
  This agreement is formulated in accordance with relevant national laws and regulations, and the user agrees to strictly abide by the following business:
  1. It shall not transmit or publish any speech that incites resistance to or destruction of the implementation of the laws or administrative regulations, incites subversion of state power, incites division of the state and destruction of national unity, incites ethnic hatred, ethnic discrimination;
  2. Users shall not perform reverse engineering or malicious multi-frequency query on Partsbond to grab the reconstructed data;
  3. Shall not instigate others to engage in ACTS prohibited by this article;
  4. Users should pay attention to and comply with the various legal rules and regulations published or modified by the website from time to time.
  5. The website reserves the right to delete any information that is not in accordance with laws and policies or is not true without notifying users.
  If the user fails to comply with the above provisions, CAPP has the right to make independent judgment and take measures such as suspending or closing the user account. The user shall bear legal responsibility for his/her inquiry in using Partsbond.
  
  § 6 Protocol updates and user care obligations
  1. Autobond reserves the right to modify the terms of this agreement from time to time in accordance with the changes of national laws and regulations and the operational requirements of Partsbond. The modified agreement shall become effective once it is posted on Partsbond or any official channel and shall replace the original agreement. Users can log in at any time to check the latest agreement; Users are obligated to follow and read the latest version of the agreement and any official announcements from time to time. If user does not agree to the updated agreement, it may and shall immediately cease to accept services provided by Partsbond pursuant to this agreement; If the user continues to use the service provided by Partsbond, he/she shall be deemed to agree to the updated agreement. Autobond recommends that you read this agreement and the announcement of this site before using Partsbond. If any provision of this agreement is deemed void, invalid or unenforceable for any reason, it shall be deemed separable and shall not affect the validity and enforceability of any of the remaining provisions.
  
  § 7 Consumers' Right of Withdrawal
  1. The Partsbond belongs to digital commodities sold online. When the user completes the payment, the contract relationship is immediately established, and the return request for any reason put forward by the user is not acceptable.
  
  § 8 Jurisdiction and application of law
  1. The conclusion, execution and interpretation of this agreement and the settlement of disputes shall be governed by the effective laws applicable in the USA (excluding the conflict of laws). In the event of any conflict between this agreement and the applicable law, these provisions shall be interpreted in full accordance with the provisions of the law and the other valid provisions shall remain in force. In the event of any dispute between the parties regarding the contents of this agreement or its implementation, the parties shall use their best efforts to settle it through friendly negotiation. If no agreement can be reached through negotiation, either party may file a lawsuit with the mainland regional court of USA which has jurisdiction.
  
  The agreement has been read and agreed`
            }
          </pre>
        </div>
      </Modal>
    </div>
  )
}
export default Login