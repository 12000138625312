import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { useIntl } from 'react-intl'
import accountApi from '@/api/account'
import { getUrlParams } from '@/utils/utils'
import styles from './my.module.scss'



const History: React.FC = (props) => {
  const intl = useIntl()
  const { type } = getUrlParams()
  const [vin, setVin] = useState<any[]>([])
  const [part, setPart] = useState<any[]>([])
  const [vinBrand, setVinBrand] = useState<any[]>([])
  const [partBrand, setPartBrand] = useState<any[]>([])
  const [active, setActive] = useState<string>(type || 'vin')
  const [vinBrandActive, setVinBrandActive] = useState<string>('')
  const [partBrandActive, setPartBrandActive] = useState<string>('')
  const typeData = [
    {
      name: intl.formatMessage({id: 'vin'}),
      key: 'vin'
    },
    {
      name: intl.formatMessage({id: 'oe'}),
      key: 'part'
    }
  ]
  useEffect(() => {
    getHistory()
  },[])

  useEffect(() => {
    if(vinBrandActive){
      getVinH(vinBrandActive)
    }
    
  },[vinBrandActive])

  useEffect(() => {
    if(partBrandActive){
      getPartH(partBrandActive)
    }
    
  },[partBrandActive])

  const getHistory = () => {
    accountApi.vinHistoryBrand().then((result: any)=>{
      if(result.code === 1){
        setVinBrandActive(result.data[0]?.brand || '')
        setVinBrand(result.data)
      }
    })
    accountApi.partHistoryBrand().then((result: any)=>{
      if(result.code === 1){
        setPartBrandActive(result.data[0]?.brand || '')
        setPartBrand(result.data)
      }
    })
  }
  const getVinH = (brandCode: string) => {
    setVin([])
    accountApi.vinHistoryAll({brandCode}).then((result: any)=>{
      if(result.code === 1){
        setVin(result.data)
      }
    })
  }
  const getPartH = (brandCode: string) => {
    setPart([])
    accountApi.partHistoryAll({brandCode}).then((result: any)=>{
      if(result.code === 1){
        setPart(result.data)
      }
    })
  }
  return (
    <div className={styles.history}>
      <div className={styles.border}>
        <ul className={styles.top}>
          {
            typeData.map((item)=>{
              return (
                <li key={item.key} onClick={()=>setActive(item.key)} className={active === item.key ? styles.active : ''}>{item.name}</li>
              )
            })
          }
        </ul>
        {
          active === 'vin' ? (
            <>
              <ul className={styles.tab}>
                {
                  vinBrand.map((item, index)=>{
                    return (
                      <li key={index} onClick={()=>setVinBrandActive(item.brand)} className={vinBrandActive === item.brand ? styles.active : ''}>{item.name}</li>
                    )
                  })
                }
              </ul>
              <ul className={styles.content}>
                {
                  vin.map((item, index)=>{
                    return (
                      <li key={index}><Link to={`/vin?vin=${item.vin}`}>{item.vin}</Link></li>
                    )
                  })
                }
              </ul>
            </>
          ): (
            <>
              <ul className={styles.tab}>
                {
                  partBrand.map((item, index)=>{
                    return (
                      <li key={index} onClick={()=>setPartBrandActive(item.brand)} className={partBrandActive === item.brand ? styles.active : ''}>{item.name}</li>
                    )
                  })
                }
              </ul>
              <ul className={styles.content}>
                {
                  part.map((item, index)=>{
                    return (
                      <li key={index}><Link to={`/partsprice?pid=${item.pid}`}>{item.pid}</Link></li>
                    )
                  })
                }
              </ul>
            </>
          )
        }

      </div>
    </div>
  )
}

export default History