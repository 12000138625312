import React, { useState, useContext, useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import MainContext from '@/context/MainContext'
import Loading from '@/components/Loading'
import accountApi from '@/api/account'
import styles from './invite.module.scss'


interface resultIn {
  code: number
  data: any
}

const Invited: React.FC = (props) => {
  const history = useHistory()
  const { store, setStore } = useContext(MainContext)
  const { userInfo } = store
  const [ loading, setLoading ] = useState<boolean>(false)
  
  const getCoupon = ()=> {
    setLoading(true)
    accountApi.getCoupon().then((result: resultIn) => {
      setLoading(false)
      if(result.code === 1) {
        if(result.data?.length){
          window.sessionStorage.setItem('code', result.data[0].coupon_num)
        }
        hide()
        history.push(`/my/purchase`)
      }
    })
  }
  
  const hide = () => {
    let temp = JSON.parse(JSON.stringify(userInfo))
    temp.data.isinvited = 0
    setStore({value: temp, type: 'userInfo'})
  }

  return (
    <div className={styles.invited}>
      <i className='iconfont icona-16pt1' style={{position: 'absolute', top: 4, right: 4, color: '#999', cursor: 'pointer'}} onClick={()=>hide()} />
      <div className={styles.content}>
        <i className="iconfont iconxingzhuang"></i>
        <h6>You have a coupon to use</h6>
        <p>Available on orders over $30</p>
        <button onClick={()=>getCoupon()}>
          {
            loading ? <Loading visible={true} type='mini' color="#fff" /> : null
          }
          Use it Now
        </button>
        <p onClick={()=>hide()} style={{cursor: 'pointer'}}>next time</p>
      </div>
    </div>
  )
}

export default Invited