import React, { useState, useEffect } from 'react'
import { useIntl } from 'react-intl'
import { VinDetail } from '@/page/Vin'
import Modal from '@/components/Modal'
import vinApi from '@/api/vinApi'
import css from './vin.module.scss'

interface PropsIn {
  keyword: string,
  vin?: string,
  mcid: string,
  brandcode: string,
  title?: string,
  cancelFn?: Function
}

const PartSearch: React.FC<PropsIn> = (props) => {
  const intl = useIntl()
  const { keyword, mcid, vin, brandcode, cancelFn, title='' } = props
  const [ partVisible, setPartVisible ] = useState<boolean>(true)
  const [ partListVisible, setPartListVisible ] = useState<boolean>(false)
  const [ active, setActive ] = useState<any>({})
  const [ data, setData ] = useState<any>([])

  useEffect(()=>{
    if(keyword){
      partSearch()
    }
  }, [keyword])
  const partSearch = () => {
    const param = {
      mcid,
      vin: vin || '',
      brandCode: brandcode,
      search_key: keyword,
      search_type: 'pid'
    }
    vinApi.partSearch(param).then((result: {code: number, data: object[]})=>{
      if(result.code === 1){
        setData(result.data)
        if(result.data.length === 1){
          showList(result.data[0])
        }
      }
    })
  }
  const showList = (item: object) => {
    setActive(item)
    setPartVisible(false)
    setPartListVisible(true)
  }
  const backFn = () => {
    setPartVisible(true)
    setPartListVisible(false)
  }
  const cancel = (val: boolean) => {
    setPartVisible(val)
    cancelFn && cancelFn({})
  }
  const cancelDetail = (val: boolean) => {
    setPartListVisible(val)
    cancelFn && cancelFn({})
  }
  return (
    <>
      <Modal
        title={(vin ? (vin + ' > ') : '') + title + keyword}
        visible={partVisible}
        cancel={cancel}
        width={1200}
      >
        <div className={css['part-search']}>
          <h6>{intl.formatMessage({id: 'findResults'}, {num: data.length})}</h6>
          <div className={css['content']}>
            {
              data.map((item: any, index: number)=>{
                return (
                  <div className={css['item']} key={index} onClick={()=>showList(item)}>
                    <div className={css['l']}>
                      <img src={item.url} alt={item.pid} />
                    </div>
                    <div className={css['r']}>
                      <h5>{item.label}</h5>
                      <p>
                        <label>{intl.formatMessage({id: 'partOe'})}</label>
                        {item.pid}
                      </p>
                      <p>
                        <label>{intl.formatMessage({id: 'mainGroup'})}</label>
                        {item.maingroupname}
                      </p>
                      <p>
                        <label>{intl.formatMessage({id: 'subGroup'})}</label>
                        {item.mid} {item.subgroupname}
                      </p>
                    </div>
                  </div>
                )
              })
            }
          </div>
        </div>
      </Modal>
      <Modal
        title={`${vin ? vin + ' > ' : ''} ${title ? (title + ' | ') : ''} ${active.num} ${active.maingroupname} > ${active.subgroup} ${active.subgroupname}`}
        visible={partListVisible}
        cancel={cancelDetail}
        width={1200}
      >
        <VinDetail
          type='partSearch'
          vin={vin}
          brandcode={active.brandCode}
          mcid={active.mcid}
          num={active.num}
          mid={active.mid}
          subgroup={active.subgroup}
          pid={active.pid}
          backFn={data.length > 1 ? backFn : undefined}
        />
      </Modal>
    </>
  )
}

export default PartSearch;