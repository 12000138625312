import Main from './Main';
import Header from './Header';
import Footer from './Footer';
import Search from './Search';
import Breadcrumb from './Breadcrumb';

export default Main;
export {
  Header,
  Footer,
  Search,
  Breadcrumb
}