import axios from 'axios'

const threeApi: any = {}

threeApi.getModel = (params: {}): Promise<{}> => {
  return axios.get("/v1/api/maintenance/chassis/check", {params})
}
threeApi.getDetail = (params: {}): Promise<{}> => {
  return axios.get("/v1/api/maintenance/label/details", {params})
}
threeApi.getLabel = (params: {}): Promise<{}> => {
  return axios.get("/v1/api/maintenance/getlabels", {params})
}
threeApi.getTree = (params: {}): Promise<{}> => {
  return axios.get("/v1/api/epc/model/tree", {params})
}

export default threeApi