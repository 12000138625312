import React from 'react';
import { Link } from 'react-router-dom';
import { useIntl } from 'react-intl'
import { ChildItemFormat, PartItemFormat } from './inerface'
import { getUrlParams } from '@/utils/utils'
import ebay from '@/assets/images/ebay.png'
import amazon from '@/assets/images/amazon.png'
import RockAuto from '@/assets/images/RockAuto.png'
import styles from './partList.module.scss'

import defaultPart from '@/assets/images/default_part.png'


const PartItem: React.FC<ChildItemFormat> = (props) => {
  const intl = useIntl()
  const { style = {}, item, from, channels, polling, index, showTrend, type } = props
  const { part_image, brand, pid, location, part_name, part_price, channel, url, update_time, logoimg } = item;
  const channelImg = channel === 'ebay' ? ebay : (channel === 'amazon' ? amazon : (channel === 'RockAuto' ? RockAuto : ''))
  const urlParams = JSON.stringify(getUrlParams())

  return (
    type === 'block' ? (
      <div className={styles['part-item-wrap']} style={style}>
        <div className={styles['part-item-img-wrap']}>
          <a href={url} target="_blank">
            <img src={part_image || defaultPart}/>
          </a>
          {
            brand ? <div className={styles['part-item-brand']}>{brand}</div> : null
          }
          
          <span onClick={()=>polling(urlParams, channel, pid)} className={channels[channel] && channels[channel].progress !== 5 ? 'iconfont iconzhongshuaxin trans' : 'iconfont iconzhongshuaxin'}></span>
        </div>
        <div className={styles['part-item-info-wrap']}>
          <div className={styles['part-item-des']}>
            {
              from === 'cars' ? (
                <Link to={`detail?pid=${pid}`}>
                  {part_name}
                </Link>
              ): (
                <a href={url} target="_blank">{part_name}</a>
              )
            }
            
          </div>
          <div className={styles['part-item-info-midl']}>
            <div className={styles['part-item-oe']}>
              <span className="icon iconfont" style={{fontSize: 14, color: "#1890FF"}}>&#xe66b;</span>
              <span style={{marginLeft: 4}}><a href={url} target="_blank">{pid}</a></span>
            </div>
            {
              location ? <div className={styles['part-item-location']}>
                <span className="icon iconfont" style={{fontSize: 12, color: "#333330"}}>&#xe6f2;</span>
                <span style={{marginLeft: 4}}>{location}</span>
              </div> : null
            }
            
          </div>
          <div className={styles['part-item-price-wrap']}>
            <span className={styles['part-item-price']}>{part_price} {showTrend ? <i className="iconfont iconlingjianzhishu" onClick={()=>showTrend({pid, part_name, url: item.url})} /> : ''}</span>
            <span className={styles['part-item-price-pop']}><a href={url} target="_blank"><img src={logoimg} /></a></span>
          </div>
          <p className={styles['part-item-price-pop']}>{part_price ? update_time : ''}</p>
          {/* {
            from === 'detail' ? (
              <a href={url} target="_blank">
                <div className={styles['part-item-btn']} >
                  <span><img src={logoimg} /></span>
                  <i></i>
                  <em>Go</em>
                </div>
              </a>
            ): null
          } */}
        </div>
      </div>
    ): (
      <div className={styles.list}>
        <div className={styles.img}>
          <img src={part_image || defaultPart}/>
          {
            brand ? <div className={styles['part-item-brand']}>{brand}</div> : null
          }
          <span onClick={()=>polling(urlParams, channel, pid)} className={channels[channel] && channels[channel].progress !== 5 ? 'iconfont iconzhongshuaxin trans' : 'iconfont iconzhongshuaxin'}></span>
        </div>
        <div className={styles.info}>
          <div className={styles['part-item-des']}>
            {part_name}
          </div>
          <div className={styles['part-item-info-midl']}>
            <div className={styles['part-item-oe']}>
              <span className="icon iconfont" style={{fontSize: 14, color: "#1890FF"}}>&#xe66b;</span>
              <span style={{marginLeft: 4}}>{pid}</span>
            </div>
            {
              location ? <div className={styles['part-item-location']}>
                <span className="icon iconfont" style={{fontSize: 12, color: "#333330"}}>&#xe6f2;</span>
                <span style={{marginLeft: 4}}>{location}</span>
              </div> : null
            }
            
          </div>
          <div className={styles['part-item-price-wrap']}>
            <span className={styles['part-item-price']}>{part_price} {showTrend ? <i className="iconfont iconlingjianzhishu" onClick={()=>showTrend({pid, part_name, url: item.url})} /> : ''}</span>
            {/* <span className={styles['part-item-price-pop']}></span> */}
          </div>
          <p className={styles['part-item-price-pop']}>{part_price ? update_time : ''}</p>
          
        </div>
        <a href={url} target="_blank">
          <div className={styles['part-item-btn']} >
            <span><img src={logoimg} /></span>
            <i></i>
            <em>{intl.formatMessage({id: 'go'})}</em>
          </div>
        </a>
      </div>
    )
    
    
  )
}

export default PartItem