import React, { Fragment, useContext } from 'react'
import Tooltip from 'rc-tooltip'
import MainContext from '@/context/MainContext'

import styles from './main.module.scss'

const Breadcrumb: React.FC = () => {
  const { store } = useContext(MainContext)
  const { crumb } = store
  return (
    <div className={styles.crumb}>
      {
        crumb[0]?.type === 'logo' ? <img src={crumb[0].img} /> : null
      }
      <ul>
        {
          crumb.map((item: any, index: number)=>{
            return (
              item.type !== 'logo' ? (
                <Fragment key={index}>
                  <li>
                    {
                      item.name?.length > 36 ? (
                        <Tooltip mouseLeaveDelay={0} placement="top" overlay={item.show_name || item.name} overlayStyle={{width: 222}} >
                          <span>{item.show_name? item.show_name.substring(0, 36) : item.name.substring(0, 36)}...</span>
                        </Tooltip>
                      ): (
                        <>{item.show_name || item.name}</>
                      )
                    }
                  </li>
                  {
                    crumb.length !== index + 1 ? <li>&gt;</li> : null
                  }
                </Fragment>
              ): null
            )
          })
        }
      </ul>
    </div>
  )
}

export default Breadcrumb;