import Vin from './Vin'
import PartSearch from './PartSearch'
import VinDetail from './VinDetail'
import VinDetailPage from './VinDetailPage'
import PartDetail from './PartDetail'
import PartSearchPage from './PartSearchPage'

export default Vin

export {
  VinDetail,
  PartSearch,
  VinDetailPage,
  PartDetail,
  PartSearchPage
}