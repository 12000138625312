import React, { useState, useEffect, useContext, useImperativeHandle } from 'react'
import { Link, useLocation, useHistory } from 'react-router-dom'
import { useIntl } from 'react-intl'
import Tooltip from 'rc-tooltip'
import MainContext from '@/context/MainContext'
import { PurchaseModal } from '@/page/My'
import { Login } from '@/page/Login'
import Feedback from '@/components/Feedback'
import HelpVideo from '@/components/HelpVideo'
import Invite, { Invited } from '@/components/Invite'
import accountApi from '@/api/account'
import { getUrlParams } from '@/utils/utils'
import css from './main.module.scss'
import logoImg from '@/assets/images/logo.png'

interface Parameter {
  type?: string,
  tipsVisible?: boolean,
  setTipsVisible?: Function,
  onRef?: any
}

const lang: any = {
  en: 'english',
  ru: 'russian',
  id: 'indonesian',
  vi: 'vietnamese',
  ms: 'malaysia',
  th: 'thai',
  ar: 'arabia',
  es: 'spain',
  pt: 'portugal',
  fr: 'france',
  tr: 'Turkey',
  jp: 'japanese',
  ko: 'korean'
}
const w: any = window

const Header: React.FC<Parameter> = (props: Parameter) => {
  const { tipsVisible, setTipsVisible, onRef } = props
  const intl = useIntl()
  const { buy } = getUrlParams()
  const { pathname } = useLocation()
  const history = useHistory()
  const { store, setStore } = useContext(MainContext)
  const { userInfo, purchaseVisible, loginVisible, freecount } = store
  const [ feedbackVisible, setFeedbackVisible ] = useState<boolean>(false)
  
  const [ vedioVisible, setVedioVisible ] = useState<boolean>(false)
  const [ vedioIndex, setVedioIndex ] = useState<number>(0)

  const [ inviteVisible, setInviteVisible ] = useState<boolean>(false)

  const [ numberVisible, setNumberVisible ] = useState<boolean>(false)
  const [ freeTips, setFreeTips ] = useState<boolean>(window.localStorage.getItem('freeTips') ? false : true)
  const menu = [
    {
      title: intl.formatMessage({id: 'home'}),
      path: '/home'
    },
    {
      title: intl.formatMessage({id: 'catalog'}),
      path: '/catalog',
      special: ['vin', 'search', 'group']
    },
    {
      title: intl.formatMessage({id: 'partsPrice'}),
      path: '/partsprice'
    }
  ]
  useEffect(()=>{
    if(!freeTips){
      window.localStorage.setItem('freeTips', '1')
    }
  }, [freeTips])
  
  const user = (
    <>
      <p><Link to="/my/account"><i className='iconfont iconwode1'/> {intl.formatMessage({id: 'account'})}</Link></p>
      <p><Link to="/my/purchase"><i className='iconfont icongoumai'/> {intl.formatMessage({id: 'purchase'})}</Link></p>
      <p><Link to="/my/message"><i className='iconfont iconxiaoxi'/> {intl.formatMessage({id: 'message'})}</Link></p>
      <p><Link to="/my/favorite"><i className='iconfont iconshoucangmoren'/> {intl.formatMessage({id: 'watch'})}</Link></p>
      <p><Link to="/my/coupon"><i className='iconfont iconbaojiadan'/> {intl.formatMessage({id: 'coupon'})}</Link></p>
      {/* <p><Link to="/my/account">{userInfo.data?.full_name || userInfo.data?.username}</Link></p> */}
      <p className="out" onClick={()=>logout()}><i className='iconfont icontuichu11'/> {intl.formatMessage({id: 'signOut'})}</p>
    </>
  )

  const language = (
    <>
      <p><a href="https://partsbond.com">{intl.formatMessage({id: 'english'})}</a></p>
      <p><a href="https://ru.partsbond.com">{intl.formatMessage({id: 'russian'})}</a></p>
      <p><a href="https://id.partsbond.com">{intl.formatMessage({id: 'indonesian'})}</a></p>
      <p><a href="https://vi.partsbond.com">{intl.formatMessage({id: 'vietnamese'})}</a></p>
      <p><a href="https://ms.partsbond.com">{intl.formatMessage({id: 'malaysia'})}</a></p>
      <p><a href="https://th.partsbond.com">{intl.formatMessage({id: 'thai'})}</a></p>
      <p><a href="https://ar.partsbond.com">{intl.formatMessage({id: 'arabia'})}</a></p>
      <p><a href="https://es.partsbond.com">{intl.formatMessage({id: 'spain'})}</a></p>
      <p><a href="https://pt.partsbond.com">{intl.formatMessage({id: 'portugal'})}</a></p>
      <p><a href="https://fr.partsbond.com">{intl.formatMessage({id: 'france'})}</a></p>
      <p><a href="https://tr.partsbond.com">{intl.formatMessage({id: 'Turkey'})}</a></p>
      <p><a href="https://jp.partsbond.com">{intl.formatMessage({id: 'japanese'})}</a></p>
      <p><a href="https://ko.partsbond.com">{intl.formatMessage({id: 'korean'})}</a></p>
    </>
  )

  const msg = (
    <div className='msg-content'>
      {
        userInfo.msg?.map((item: any, index: number)=>{
          return (
            item.code === 'pre_freetrail' ? (
              <p key={index}>{item.content} <button onClick={()=>setStore({value: true, type: 'purchaseVisible'})}>{intl.formatMessage({id: 'subscribe'})}</button></p>
            ): (
              <p key={index}>{item.content}</p>
            )
          )
        })
      }
    </div>
  )

  const logout = () => {
    accountApi.logout().then((result: {code: number}) => {
      if(result.code === 1) {
        window.location.href = '/'
      }
    })
  }
  const go = (path: string) => {
    // setStore({value: [], type: 'crumb'})
    // window.sessionStorage.removeItem('groupCrumb')
    // window.sessionStorage.removeItem('crumb')
    if(buy){
      window.sessionStorage.setItem('buycode', buy)
    }
    history.push(path)
  }

  
  useImperativeHandle(onRef, () => ({
    setInviteVisible
  }))
  return (
    <div>
      <div className={css['bg']}>
        <div className={css['header']}>
          <div className={css['logo']}>
            <Link to="/"><img src={logoImg} alt="logo" className={css['min']} /></Link>
            {
              menu.map((item, index)=>{
                return (
                  <span key={index} onClick={()=>go(item.path)} className={((pathname.indexOf(item.path) !== -1 || (item.special?.filter((special)=>pathname.indexOf(special) !== -1))?.length) && item.path !== '/') ? css['active'] : ''}>{item.title}</span>
                )
              })
            }
          </div>
          <div className={css['login']}>
            <Tooltip placement="bottom" overlay={language} overlayStyle={{width: 124}} overlayClassName="white">
              <span>
                {intl.formatMessage({id: (lang[w.hostname] || 'english')})} <i className="iconfont icondown"></i>
              </span>
            </Tooltip>
            {
              userInfo.userid ? (
                <>
                  {
                    freecount.status ? (
                      <div className={css.freecount}>
                        <em>{freecount.count}</em>
                        <p>{intl.formatMessage({id: 'freeSearch'})}</p>
                      </div>
                    ): null
                  }
                  
                  {
                    userInfo.data?.trialdesc ? <button className={css.tried}>{userInfo.data?.trialdesc}</button> : null
                  }
                  
                  {
                    !userInfo.data?.redeemed ? (
                      <Tooltip visible={tipsVisible} overlayClassName="newred" mouseLeaveDelay={0} placement="bottom" overlay={(<div className='tips'><p>{intl.formatMessage({id: 'inviteTips'})}</p><span className="iconfont icona-16pt1 btn" onClick={()=>{setTipsVisible && setTipsVisible(false)}}></span></div>)} overlayStyle={{width: 270}} >
                        <button onClick={()=>setInviteVisible(true)} id="invite" className={css.red}><i className='iconfont icona-16pt' /> {intl.formatMessage({id: 'invite'})} {numberVisible ? <span className={css.number}>1</span> : null}</button>
                      </Tooltip>
                    ): null
                  }
                  {
                    // !userInfo.data?.trialused ? (
                    //   <Tooltip visible={freecount.status === 0 && freeTips} overlayClassName="newred" mouseLeaveDelay={0} placement="bottomLeft" overlay={(<div className='tips'><p>{intl.formatMessage({id: 'freeTrialTips'})}</p><span className="iconfont icona-16pt1 btn" onClick={()=>{setFreeTips(false)}}></span></div>)} overlayStyle={{width: 220}} >
                    //     <button onClick={()=>setStore({value: true, type: 'purchaseVisible'})} className={css.red}><i className='iconfont iconVIPp' /> {intl.formatMessage({id: 'freeTrial'})}</button>
                    //   </Tooltip>
                    //   ): null
                  }
                  
                  <button onClick={()=>setVedioVisible(true)} style={{width: 110, marginRight: 10}}><i className='iconfont iconcaozuoshuoming' /> {intl.formatMessage({id: 'helpVideos'})}</button>
                  
                  {/* <Link to="/my/purchase">
                    <i className="iconfont iconxufei"></i> Purchase
                  </Link>
                  <u>|</u>  */}
                  <Tooltip placement="bottom" overlay={user} overlayStyle={{width: 124}} overlayClassName="white">
                    <span>
                      <i className="iconfont iconwode"></i> {userInfo.data?.full_name || userInfo.data?.username} <i className="iconfont icondown"></i>
                    </span>
                  </Tooltip>
                  {/* <u>|</u> 
                  <span onClick={()=>setFeedbackVisible(true)}><i className="iconfont iconxieyi"></i> Feedback</span> */}
                  {/* {
                    userInfo.msg?.length ? (
                      <>
                        <u>|</u>
                        <Tooltip defaultVisible={true} placement="bottomRight" overlay={msg} overlayClassName="white">
                          <span className={css['msg']}>
                            <i className="iconfont iconxiaoxi3"></i>
                          </span>
                        </Tooltip>
                      </>
                    ): null
                  } */}
                  
                </>
              ): (
                <>
                  {/* <span>Location</span> */}
                  {/* <button onClick={()=>setStore({value: true, type: 'loginVisible'})} className={css.red}><i className='iconfont icona-16pt' /> {intl.formatMessage({id: 'invite'})}</button> */}
                  {/* <button onClick={()=>setStore({value: true, type: 'loginVisible'})} className={css.red}><i className='iconfont iconVIPp' /> {intl.formatMessage({id: 'freeTrial'})}</button> */}
                  <button onClick={()=>setVedioVisible(true)} style={{width: 110, marginRight: 10}}><i className='iconfont iconcaozuoshuoming' /> {intl.formatMessage({id: 'helpVideos'})}</button>
                  <button onClick={()=>setStore({value: true, type: 'loginVisible'})}>{intl.formatMessage({id: 'sign'})}</button>
                  <button onClick={()=>go('/register')} className={css.hollow}>{intl.formatMessage({id: 'register'})}</button>
                </>
              )
            }
          </div>
        </div>
      </div>
      {
        purchaseVisible ? <PurchaseModal /> : null
      }
      {
        feedbackVisible ? <Feedback closeFn={setFeedbackVisible} /> : null 
      }
      {/* <Modal
        title='Help Videos'
        visible={vedioVisible}
        cancel={()=>setVedioVisible(false)}
        width={900}
      >
        <div className={css.vedio}>
          <ul>
            {
              vedioData.map((item: any, index: number)=>{
                return (
                  <li className={index === vedioIndex ? css.active : ''} onClick={()=>setVedioIndex(index)}>{item.title}</li>
                )
              })
            }
          </ul>
          <video ref={videoRef} width={880} height={464} src={vedioData[vedioIndex]?.vedio_url} controls onTimeUpdate={()=>setVedioTime()}>
            Your browser does not support video。
          </video>
        </div>
      </Modal> */}
      <HelpVideo visible={vedioVisible} setVisible={setVedioVisible} activeIndex={vedioIndex} setActiveIndex={setVedioIndex} />
      {
        userInfo.userid ? <Invite visible={inviteVisible} setVisible={setInviteVisible} setNumberV={setNumberVisible} /> : null
      }
      
      {
        userInfo.data?.isinvited ? <Invited /> : null
      }
      {
        loginVisible ? <Login type="modal" /> : null
      }
      
    </div>
  )
}

export default Header