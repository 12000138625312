import React, { useState, useEffect, useReducer } from 'react'
import { Link } from 'react-router-dom'
import { useIntl } from 'react-intl'
import Toast from '@/components/Toast'
import Copy from '@/components/Copy'
import vinApi from '@/api/vinApi'
import carApi from '@/api/carApi'
import styles from './vin.module.scss'

interface PropsIn {
  vin?: string,
  pid: string,
  brandCode: string,
  mcid: string,
  mid: string,
  num: string,
  queryType: string
}

const reducer = (state: any, action: any) => {
  console.info(state)
  switch(action.type) {
    case 'basic':
      return {
        ...state,
        basic: {
          name: action.name,
          data: action.value
        }
      };
    case 'replace':
      return {
        ...state,
        replace: {
          name: action.name,
          data: action.value
        }
      };
    case 'articles':
      return {
        ...state,
        articles: {
          name: action.name,
          data: action.value
        }
      };
    case 'component':
      return {
        ...state,
        component: {
          name: action.name,
          data: action.value
        }
      };
    case 'applicable':
      return {
        ...state,
        applicable: {
          name: action.name,
          data: action.value
        }
      };
    case 'supplier':
      return {
        ...state,
        supplier: {
          name: action.name,
          data: action.value
        }
      };
    default:
      return state
  }
}

let pollingIs = true
let pollingNum = 0

const PartDetail: React.FC<PropsIn> = (props) => {
  const intl = useIntl()
  const { vin, pid, brandCode, mcid, mid, num, queryType } = props
  const [ tabActive, setTabActive ] = useState<string>('basic')
  const [ tabBrandActive, setTabBrandActive ] = useState<string>('All')
  const [ progress, setProgress ] = useState<number>(0)
  
  const [ data, setData ] = useReducer(reducer, {
    basic: {
      name: intl.formatMessage({id: 'basic'})
    },
    replace: {
      name: intl.formatMessage({id: 'replacement'})
    },
    // articles: {
    //   name: 'Articles'
    // },
    // component: {
    //   name: 'Component'
    // },
    applicable: {
      name: intl.formatMessage({id: 'applicableVeh'})
    },
    supplier: {
      name: intl.formatMessage({id: 'supplier'})
    },
  })
  useEffect(()=>{
    getBaseinfo()
    supplier()
    
    return unmount
  }, [])

  useEffect(()=>{
    applicable(tabBrandActive)
  }, [tabBrandActive])

  const unmount = () => {
    if(pollingNum){
      pollingIs = false
    }
  }

  const getBaseinfo = () => {
    let param = {
      maintainVinInputValue: vin || '',
      pid,
      brandCode,
      mcid,
      mid,
      num,
      query_type: queryType
    }
    vinApi.baseinfo(param).then((result: any)=>{
      if(result.code === 1){
        const d = result.data
        let value = [
          {
            key: intl.formatMessage({id: 'oeNumber'}),
            value: d.pid,
            copy: true
          },
          {
            key: intl.formatMessage({id: 'partName'}),
            value: d.label
          },
          {
            key: intl.formatMessage({id: 'model'}),
            value: d.model
          },
          {
            key: 'Eng Pid',
            value: d.engpid
          },
          {
            key: intl.formatMessage({id: 'remark'}),
            value: d.remark
          },
          {
            key: intl.formatMessage({id: 'updateTime'}),
            value: d.updatetime
          }
        ]
        setData({value, name: intl.formatMessage({id: 'basic'}), type: 'basic'})
        replace()
      }
    })
  }
  const supplier = () => {
    carApi.getData({pid}).then((result: any) => {
      if(result.code === 1) {
        setData({value: result.data.parts, name: intl.formatMessage({id: 'supplier'}), type: 'supplier'})
      }
    })
  }
  const replace = () => {
    vinApi.replace({pid, brandCode}).then((result: any)=>{
      if(result.code === 1){
        let directly: any = []
        let indirect: any = []
        result.data.forEach((item: {isre: string}) => {
          if(item.isre === "1" || item.isre === "3"){
            directly.push(item)
          }
          if(item.isre === "0"){
            indirect.push(item)
          }
        })
        setData({value: {directly, indirect}, name: intl.formatMessage({id: 'replacement'}), type: 'replace'})
      }
    })
  }
  const applicable = (brand: string) => {
    let param: any = {
      part: pid
    }
    if(brand && brand !== 'All'){
      param.filter_brand = brand
    }
    vinApi.applicable(param).then((result: any)=>{
      if(result.code === 1){
        result.all_brands.unshift(['All'])
        setData({value: {title: result.all_brands, data: result.data}, name: intl.formatMessage({id: 'applicableVeh'}), type: 'applicable'})
      }
    })
  }
  const single = (index: number) => {
    let tempData = {...data}
    let supplier = tempData.supplier.data
    let item = supplier[index]
    const param = {
      pid: item.pid,
      source_code: item.channel,
      price: item.part_price
    }
    carApi.single(param).then((result: any)=>{
      if(result.code === 1){
        Toast('successfully')
        supplier[index].update_time = result.data
        setData({value: supplier, name: intl.formatMessage({id: 'supplier'}), type: 'supplier'})
      }
    })
  }
  const scrollToAnchor = (id: string) => {
    if (id) {
      let anchorElement = document.getElementById(id)
      if(anchorElement) { anchorElement.scrollIntoView() }
    }
  }
  const scroll = () => {
    let content = document.getElementById('content')
    let top = content?.scrollTop
    let contop = content?.offsetTop || 0
    let active = ''
    Object.keys(data).forEach((key)=>{
      const offsetTop = document.getElementById(key)?.offsetTop || 0
      if(offsetTop && (top || top === 0) && top >= offsetTop - contop - 10){
        if(top >= offsetTop - contop - 10){
          active = key
        }
      }
    })
    setTabActive(active)
  }

  const polling = (timing: number) => {
    carApi.polling({pid}).then((result: any) => {
      console.info(pollingIs)
      if(result.code === 1 && pollingIs){
        let is = false
        Object.keys(result.data).forEach((key)=>{
          const val = result.data[key]
          if(val === 1 || val === 3){
            is = true
            if(data.msg){
              Toast(data.msg)
            }
          }
        })
        if(is){
          timing = 0
          supplier()
        }else {
          if(timing < 4){
            timing++
          }
          setTimeout(()=>{
            if(pollingIs){
              polling(timing)
            }
          }, 5000)
        }
        if(pollingIs){
          pollingNum = timing
          setProgress(timing)
        }
        
      }
    })
  }
  return (
    <div className={styles.partDetail}>
      <ul className={styles.tab}>
        {
          Object.keys(data).map((key, index)=>{
            const item = data[key]
            return (
              <li key={index} className={tabActive === key ? styles.active : ''} onClick={()=>scrollToAnchor(key)}>{item.name}</li>
            )
          })
        }
      </ul>
      <div className={styles.content} id="content" onScroll={()=>scroll()}>
        <div className={styles.row} id="basic">
          <div className={styles.basic}>
            {
              data.basic.data?.map((item: any, index: number)=>{
                return (
                  <div className={styles.item} key={index}>
                    <label>{item.key}</label>
                    <span dangerouslySetInnerHTML={{ __html: item.value }} />
                  </div>
                )
              })
            }
          </div>
          {/* <div className={styles.supplier}>
            <h5>Supplier</h5>
            {
              data.supplier.data?.length ? (
                <>
                  <table>
                    <tbody>
                    {
                      data.supplier.data?.map((item: any, index: number)=>{
                        return (
                          index < 3 ? (
                            <tr key={index}>
                              <td style={{width: '15%'}}><img src={item.part_image} /></td>
                              <td style={{width: '32%'}}>
                                <p>{item.part_price}</p>
                                <p style={{fontSize: 12, color: '#777777'}}>{item.Cost}</p>
                              </td>
                              <td>
                                <p><span className={styles.link} onClick={()=>single(index)}>Get it</span></p>
                                <p style={{fontSize: 12, color: '#777777'}}>{item.update_time}</p>
                              </td>
                              <td style={{width: '15%'}}><img src={item.logoimg} /></td>
                              <td style={{width: '8%'}}><a href={item.url} target="_blank"><button>Go</button></a></td>
                            </tr>
                          ): null
                        )
                      })
                    }
                    </tbody>
                  </table>
                  <Link to={`/partsprice?pid=${pid}`}><button className={styles.more}>More Result &gt;</button></Link>
                </>
              ): (
                <div className={styles.empty}>
                  {
                    progress ? (
                      <div className={styles.polling}><span style={{width: progress * 20 + '%'}}></span></div>
                    ): (
                      <span className={styles.link} onClick={()=>polling(0)}>Get it</span>
                    )
                  }
                </div>
              )
            }
            
          </div> */}
        </div>
        <div className={styles.box} id="replace">
          <h5>{intl.formatMessage({id: 'replacement'})}</h5>
          <table className={styles['table-header']}>
            <thead>
              <tr>
                <th style={{width: '15%', textAlign: 'center'}}>{intl.formatMessage({id: 'brands'})}</th>
                <th style={{width: '25%', textAlign: 'center'}}>{intl.formatMessage({id: 'oeNumber'})}</th>
                <th style={{width: '20%', textAlign: 'center'}}>{intl.formatMessage({id: 'partName'})}</th>
                <th style={{width: '10%', textAlign: 'center'}}>{intl.formatMessage({id: 'count'})}</th>
                <th style={{width: '15%', textAlign: 'center'}}>{intl.formatMessage({id: 'detail'})}</th>
                <th style={{width: '15%', textAlign: 'center'}}>{intl.formatMessage({id: 'partsPrice'})}</th>
              </tr>
            </thead>
          </table>
          {
            data.replace?.data?.directly.length ? (
              <table>
                <caption>{intl.formatMessage({id: 'directReplacement'})}</caption>
                <tbody>
                  {
                    data.replace.data.directly.map((item: any, index: number)=>{
                      return (
                        <tr key={index}>
                          <td style={{width: '15%'}}>{item.brandcn}</td>
                          <td style={{width: '25%'}}>
                            {
                              item.pid.length > 1 ? (
                                <div className={styles.flex}>
                                  <div>
                                    {item.parentnum} <span className="iconfont icondengyu"></span>
                                  </div>
                                  <div>
                                    {
                                      item.pid?.map((pid: any, i: number)=>{
                                        return (
                                          <p key={i}><i className={styles.us}></i><Copy title={pid.pid} /></p>
                                        )
                                      })
                                    }
                                  </div>
                                </div>
                              ): (
                                item.pid?.map((pid: any, i: number)=>{
                                  return (
                                    <p key={i}><Copy title={pid.pid} /></p>
                                  )
                                })
                              )
                            }
                          </td>
                          <td style={{width: '20%'}}>
                            {
                              item.pid?.map((pid: any, i: number)=>{
                                return (
                                  <p key={i}>{pid.lable}</p>
                                )
                              })
                            }
                          </td>
                          <td style={{width: '10%'}}>
                            {
                              item.pid?.map((pid: any, i: number)=>{
                                return (
                                  <p key={i}>{pid.num}</p>
                                )
                              })
                            }
                          </td>
                          <td style={{width: '15%'}}>
                            {
                              item.pid?.map((pid: any, i: number)=>{
                                return (
                                  <p key={i}>{pid.remark}</p>
                                )
                              })
                            }
                          </td>
                          <td style={{width: '15%'}}>
                            {
                              item.pid?.map((pid: any, i:number)=>{
                                return (
                                  <p key={i}>{pid.cost_price}</p>
                                )
                              })
                            }
                          </td>
                        </tr>
                      )
                    })
                  }
                </tbody>
              </table>
            ): null
          }
          {
            data.replace?.data?.indirect.length ? (
              <table>
                <caption>{intl.formatMessage({id: 'indirectReplacement'})}</caption>
                <tbody>
                  {
                    data.replace.data.indirect.map((item: any, index: number)=>{
                      return (
                        <tr key={index}>
                          <td style={{width: '15%'}}>{item.brandcn}</td>
                          <td style={{width: '25%'}}>
                            {
                              item.pid.length > 1 ? (
                                <div className={styles.flex}>
                                  <div>
                                    {item.parentnum} <span className="iconfont icondengyu"></span>
                                  </div>
                                  <div>
                                    {
                                      item.pid?.map((pid: any, i: number)=>{
                                        return (
                                          <p key={i}><i className={styles.us}></i><Copy title={pid.pid} /></p>
                                        )
                                      })
                                    }
                                  </div>
                                </div>
                              ): (
                                item.pid?.map((pid: any, i: number)=>{
                                  return (
                                    <p key={i}><Copy title={pid.pid} /></p>
                                  )
                                })
                              )
                            }
                          </td>
                          <td style={{width: '20%'}}>
                            {
                              item.pid?.map((pid: any, i: number)=>{
                                return (
                                  <p key={i}>{pid.lable}</p>
                                )
                              })
                            }
                          </td>
                          <td style={{width: '10%'}}>
                            {
                              item.pid?.map((pid: any, i: number)=>{
                                return (
                                  <p key={i}>{pid.num}</p>
                                )
                              })
                            }
                          </td>
                          <td style={{width: '15%'}}>
                            {
                              item.pid?.map((pid: any, i: number)=>{
                                return (
                                  <p key={i}>{pid.remark}</p>
                                )
                              })
                            }
                          </td>
                          <td style={{width: '15%'}}>
                            {
                              item.pid?.map((pid: any, i: number)=>{
                                return (
                                  <p key={i}>{pid.cost_price}</p>
                                )
                              })
                            }
                          </td>
                        </tr>
                      )
                    })
                  }
                </tbody>
              </table>
            ): null
          }
        </div>
        <div className={styles.box} id="applicable">
          <h5>{intl.formatMessage({id: 'applicableVeh'})}</h5>
          <div className={styles.tabBrand}>
            <h6>{intl.formatMessage({id: 'brands'})}</h6>
            <ul>
              {
                data.applicable.data?.title.map((item: any, index: number)=>{
                  return (
                    <li key={index} onClick={()=>setTabBrandActive(item[0])} className={tabBrandActive === item[0] ? styles.active : ''}>{item[0]}</li>
                  )
                })
              }
            </ul>
          </div>
          <table className={styles['table-header']}>
            <thead>
              <tr>
                <th style={{width: '15%'}}>{intl.formatMessage({id: 'brands'})}</th>
                <th style={{width: '50%'}}>{intl.formatMessage({id: 'carsModels'})}</th>
                <th style={{width: '15%'}}>{intl.formatMessage({id: 'year'})}</th>
                <th style={{width: '20%'}}>{intl.formatMessage({id: 'market'})}</th>
              </tr>
            </thead>
            <tbody>
              {
                data.applicable.data?.data.map((item: any, index: number)=>{
                  return (
                    <tr key={index}>
                      <td>{item.brandname}</td>
                      <td>{item.cars_model}</td>
                      <td>{item.year}</td>
                      <td>{item.market}</td>
                    </tr>
                  )
                })
              }
            </tbody>
          </table>
        </div>
        <div className={styles.box} id="supplier">
          <h5>{intl.formatMessage({id: 'supplier'})}</h5>
          <table className={styles.supplierTable}>
            <tbody>
              {
                data.supplier.data?.map((item: any, index: number)=>{
                  return (
                    <tr key={index}>
                      <td style={{width: '15%'}}><img src={item.part_image} /></td>
                      <td style={{width: '32%'}}>
                        <p>{item.part_price}</p>
                        <p style={{fontSize: 12, color: '#777777'}}>{item.Cost}</p>
                      </td>
                      <td>
                        <p><span className={styles.link} onClick={()=>single(index)}>{intl.formatMessage({id: 'getIt'})}</span></p>
                        <p style={{fontSize: 12, color: '#777777'}}>{item.update_time}</p>
                      </td>
                      <td style={{width: '15%'}}><img src={item.logoimg} /></td>
                      <td style={{width: '8%'}}><a href={item.url} target="_blank"><button>{intl.formatMessage({id: 'go'})}</button></a></td>
                    </tr>
                  )
                })
              }
            </tbody>
          </table>
        </div>
      </div>
    </div>
  )
}

export default PartDetail