import React, { useState } from 'react';
import { useIntl } from 'react-intl'
import Checkbox, { CheckboxGroup } from '@/components/Checkbox'
import { MenuFormat } from './inerface'
import styles from './partList.module.scss'

const MenuTmpl: React.FC<MenuFormat> = ({ type, title, list, hasMore, keyProps, values, setValues}) => {
  const intl = useIntl()
  const nameKey = keyProps && keyProps.name ? keyProps.name : 'name'
  const numKey = keyProps && keyProps.num ? keyProps.num : 'num'
  const [isUnfold, setIsUnfold] = useState<boolean>(true)
  const [isMore, setIsMore] = useState<boolean>(false)
  // const [value, setValue] = useState<string[]>(values)
  // const [allChecked, setAllChecked] = useState<boolean>(true)
  function allCheckedChange(checked: boolean) {
    // setAllChecked(checked)
    // setValue([])
    setValues({type, value: []})
  }
  function onChange(options: any[], checkAll: boolean) {
    if(!options.length) {
      setValues({type, value: []})
      // setAllChecked(true)
    }else {
      // if(options.length === list.length) {
      //   setValues({type, values: []})
      // }else {
      //   setValues({type, values: options})
      // }
      setValues({type, value: options})

      // setAllChecked(checkAll)
    }
    // setValue([...options])
  }
  return (
    <div className={styles['tmpl-wrap']}>
      <div className={styles['lf-title']} onClick={() => setIsUnfold(!isUnfold)}>
        <span>
        {title}
        </span>
        <span className={styles['lf-title-arraw']}>
          {
            isUnfold ? <span className="icon iconfont" style={{fontSize: 12, color: "#333333"}}>&#xe7ed;</span> : <span className="icon iconfont" style={{fontSize: 12, color: "#333333"}}>&#xe7ee;</span>
          }
        </span>
      </div>
      <div className={`${styles['tmpl-con']}${!isUnfold ? ' ' + styles['tmpl-hide'] : ''}`}>
        <div 
        className={styles['menu-item']}
        >
          <Checkbox checked={values.length === 0 || values.length === list.length ? true : false} onChange={allCheckedChange}>All {/* ({list.length}) */}</Checkbox>
        </div>
        <CheckboxGroup value={values} onChange={onChange}>
          {
            list.map((item, index) => {
              return (
                <div 
                key={index} 
                className={`${styles['menu-item']} ${!hasMore ? '' : (isMore || index < 5 ? '' : styles['hide'])}`}
                >
                  <Checkbox value={item[nameKey]}>{item[nameKey]} {/* ({item[numKey]}) */}</Checkbox>
                </div>
                
              )
            })
          }
        </CheckboxGroup>
      </div>
      {
        (hasMore && list.length > 6) ? <div className={styles['is-more']} onClick={() => setIsMore(!isMore)}>
          {
            isMore ? intl.formatMessage({id: 'putAway'}) : intl.formatMessage({id: 'seeAll'})
          }
        </div> : null
      }
    </div>
  )
}
export default MenuTmpl