import React, { useContext } from "react";
import { useMount, useUnmount } from "ahooks";
import CheckboxMini from "./CheckboxMini";
import { CheckboxProps } from "./interface";
import { GroupContext } from "./Context";
import styles from './checkbox.module.scss'

const Checkbox = React.forwardRef((props: CheckboxProps, ref: React.MutableRefObject<any> | any) => {
  const { equal } = props;
  const context = useContext(GroupContext);
  const _props: CheckboxProps = Object.assign({}, props);
  if (context && context.value && context.onChange) {
    _props.checked = context.value.some((x) => (equal ? equal(x, props.value) : x === props.value));
    _props.onChange = context.onChange;
  }
  if (context && context.disabled !== undefined) {
    _props.disabled = context.disabled;
  }

  useMount(() => {
    if (context && context.onAdd) {
      context.onAdd(props.value);
    }
  });

  useUnmount(() => {
    if (context && context.onRemove) {
      context.onRemove(props.value);
    }
  });
  return (
    <label className={styles["checkbox-wrap"]} htmlFor={_props.id} ref={ref}>
      <CheckboxMini {..._props} type="checkbox" />
      <span className={styles["checkbox-label"]}>{props.children}</span>
    </label>
  );
});

export default Checkbox