import React, { useEffect, useState } from "react";
import { SimplePageProps } from "./interface";
import { clamp } from './utils'

const PageInput: React.FC<SimplePageProps> = (props) => {
  const { current = 1, pageCount = 1, onChange } = props;
  const isControll = "current" in props;
  const [currentInputValue, setCurrentInputValue] = useState(current + "");
  // 受控时候由外部更新输入框的值
  useEffect(() => {
    if (isControll) {
      setCurrentInputValue(current + "");
    }else {
      setCurrentInputValue(1 + "");
    }
  }, [current, isControll]);
  const isValid = (page: string | number) => {
    const num = parseInt(page + "");
    return !isNaN(num) && num >= 1 && num <= pageCount;
  }

  const jump = (page: number) => {
    if (isNaN(page)) {
      return;
    }
    const num = clamp(page, 1, pageCount);
    if (onChange) {
      onChange(num);
    }
  }

  const keyDownHandle = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.keyCode === 38 || e.keyCode === 40) {
      e.preventDefault();
    }
  }

  const keyUpHandle = (e: any) => {
    const inputValue = (e.target as any).value;
    let value = inputValue;
    if (isNaN(Number(inputValue))) {
      value = currentInputValue;
    }

    let page = Number(value);
    switch (e.keyCode) {
      // Enter
      case 13:
        jump(page);
        break;
      // Up
      case 38:
        if (page + 1 <= pageCount) {
            value = page += 1;
        }
        break;
      // Down
      case 40:
        if (page - 1 >= 1) {
            value = page -= 1;
        }
        break;
    }
    setCurrentInputValue(value);
  }

  const blurHandle = (e: React.FocusEvent<HTMLInputElement>) => {
    const val = e.target.value;
    if (!isValid(val)) {
      setCurrentInputValue(current + "");
    }
  }

  return <input type="text" value={currentInputValue} onChange={keyUpHandle} onKeyDown={keyDownHandle} onKeyUp={keyUpHandle} onBlur={blurHandle} />;
}

export default React.memo(PageInput);