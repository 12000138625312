import React, { useState, useEffect, useContext } from 'react'
import { useHistory } from 'react-router-dom'
import { useIntl } from 'react-intl'
import MainContext from '@/context/MainContext'
import acountApi from '@/api/account'
import Modal from '@/components/Modal'
import Loading from '@/components/Loading'

import style from './purchaseModal.module.scss'


let PurchaseModal: React.FC = () => {
  const intl = useIntl()
  const { store, setStore } = useContext(MainContext)
  const { userInfo } = store
  const history = useHistory()
  const [data, setData] = useState<any>([])
  const [visible, setVisible] = useState<boolean>(false)
  const buycode = window.sessionStorage.getItem('buycode')
  useEffect(() => {
    getData()
  },[])

  const getData = () => {
    setVisible(true)
    let param: any = {}
    if(userInfo.data?.code){
      param.buycode = userInfo.data.code
    }
    if(buycode){
      param.buycode = buycode
    }

    acountApi.guide(param).then((result: any)=>{
      setVisible(false)
      if(result.code === 1){
        setData(result.data)
      }
    })
  }
  const go = () => {
    setStore({value: false, type: 'purchaseVisible'})
    history.push(`/my/purchase`)
  }
  const goBuy = (type: string) => {
    if(!userInfo.userid){
      // let url = '/login'
      // if(buy){
      //   url += `?buy=${buy}`
      // }
      history.push('/login')
      setStore({value: false, type: 'purchaseVisible'})
      return
    }
    window.sessionStorage.removeItem('buycode')
    let param = {
      type,
      payType: 'paypal',
      coupon_num: ''
    }
    setVisible(true)
    acountApi.usePayPal(param).then((res: { code : number, data: any }) => {
      if(res.code === 1) {
        window.location.href = res.data.pay_url
      }else {
        setVisible(false)
      }
    })
  }
  return (
    <Modal
      visible={true}
      cancel={() => {setStore({value: false, type: 'purchaseVisible'}); window.sessionStorage.setItem('code', buycode || '');  window.sessionStorage.removeItem('buycode');}}
      width={'auto'}
    >
      <Loading visible={visible}>
        <div className={style.purchaseModal}>
          <div className={style.guide}> 
            {
              data.map((item: any)=>{
                return (
                  <div onClick={()=>goBuy(item.id + '')} className={item.amount ? style.item + ' ' + style[item.valid_date_type] : style.item + ' ' + style.active} key={item.id}>
                    <div className={style.bg}>
                      <h6 dangerouslySetInnerHTML={{__html: item.title}}></h6>
                      <p>$ {item.receipt_amount || item.amount}</p>
                      {
                        item.receipt_amount ? <s>$ {item.amount}</s> : null
                      }
                      {
                        item.save ? (
                          <em>{item.save}</em>
                        ): null
                      }
                    </div>
                      {
                        item.desc ? (
                          <p className={style.desc}>{item.desc}</p>
                        ): null
                      }
                    <div className={style.bt}><button>{item.amount ? 'Subscribe' : 'Begin Free Trial'}</button></div>
                  </div>
                )
              })
            }
          </div>
          <div className={style.more} onClick={()=>go()}>
            {intl.formatMessage({id: 'availablePackages'})} &gt;
          </div>
        </div>
      </Loading>
    </Modal>
  )

}

export default PurchaseModal;



