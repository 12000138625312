import React, { useState, useRef, useEffect } from "react"
import { useIntl } from 'react-intl'
import accountApi from '@/api/account'
import Toast from '@/components/Toast'
import { cdnsPath } from '@/utils/utils'
import styles from './feedback.module.scss'

interface PropsIn {
  closeFn: Function
}


const tools = ['rectangle', 'circle', 'arrow', 'text']

let canvas: any = {}
let url = ''
let flag = false
let textflag = true
let offset = {
  x: 0,
  y: 0
}
let polygonVertex: any = []
let arrow = {
  edge: 50,
  angle: 25
}
let angle = 0

const Feedback: React.FC<PropsIn> = (props: PropsIn) => {

  const intl = useIntl()
  const { closeFn } = props
  const textareaRef = useRef<HTMLTextAreaElement>(null);
  const canvasRef = useRef<HTMLCanvasElement>(null);
  
  const [problem, setProblem] = useState<any>([
    {
      val: intl.formatMessage({id: 'vinNotAvailable'}),
    },
    {
      val: intl.formatMessage({id: 'partNotAvailable'}),
    },
    {
      val: intl.formatMessage({id: 'lackModels'}),
    },
    {
      val: intl.formatMessage({id: 'dataNotComplete'}),
    },
    {
      val: intl.formatMessage({id: 'dataError'}),
    },
    {
      val: intl.formatMessage({id: 'dataNotNew'}),
    },
    {
      val: intl.formatMessage({id: 'slowSpeed'}),
    },
    {
      val: intl.formatMessage({id: 'noUniqueness'}),
    },
    {
      val: intl.formatMessage({id: 'other'})
    }
  ])

  const [advise, setAdvise] = useState<string>('')
  const [tool, setTool] = useState<string>('')

  useEffect(()=>{
    showCanvas()
  }, [])

  const showCanvas = ()=> {
    canvas.width = document.body.clientWidth
    canvas.height = document.body.clientWidth
    canvas.id = document.getElementById("canvas")
    canvas.context = canvas.id.getContext("2d")
    canvas.id.setAttribute('width', canvas.width)
    canvas.id.setAttribute('height', canvas.height)
    canvas.context.beginPath()
    canvas.context.fillRect(0, 0, canvas.width, canvas.height)
    canvas.context.clearRect(0, 0, canvas.width, canvas.height)
    url = canvas.id.toDataURL()
    loadImg()
  }
  const loadImg = () => {
    let img = new Image()
    img.src = url
    canvas.context.drawImage(img, 0, 0, canvas.width, canvas.height)
  }
  const canvasDown = (e: any) => {
    flag = true
    canvas.context.strokeStyle = "red"
    offset.x = e.nativeEvent.offsetX
    offset.y = e.nativeEvent.offsetY
  }
  const canvasUp = (e: any) => {
    flag = false;
    url = canvas.id.toDataURL();
    loadImg()
    if (tool == "text") {
      inputWord();
    }
  }
  const canvasMove = (e: any) => {
    if(flag){
      let x = e.nativeEvent.offsetX
      let y = e.nativeEvent.offsetY
      if(tool === 'rectangle'){
        canvas.context.clearRect(0, 0, canvas.width, canvas.height)
        loadImg()
        canvas.context.beginPath()
        canvas.context.lineWidth = 4
        canvas.context.rect(offset.x, offset.y, x - offset.x, y - offset.y)
        canvas.context.stroke()
      }
      if(tool === 'circle'){
        let rx = (x - offset.x) / 2
        let ry = (y - offset.y) / 2
        let r = Math.sqrt(rx * rx + ry * ry)

        canvas.context.clearRect(0, 0, canvas.width, canvas.height)
        loadImg()

        canvas.context.beginPath()
        canvas.context.lineWidth = 4
        canvas.context.arc(rx + offset.x, ry + offset.y, r, 0, Math.PI * 2)
        canvas.context.stroke()
      }
      if(tool === 'arrow'){
        canvas.context.clearRect(0, 0, canvas.width, canvas.height)
        loadImg()
        arrowCoord(offset, {x, y})
        sideCoord()
        canvas.context.fillStyle = "red"
        canvas.context.beginPath()
        canvas.context.moveTo(polygonVertex[0], polygonVertex[1])
        canvas.context.lineTo(polygonVertex[2], polygonVertex[3])
        canvas.context.lineTo(polygonVertex[4], polygonVertex[5])
        canvas.context.lineTo(polygonVertex[6], polygonVertex[7])
        canvas.context.lineTo(polygonVertex[8], polygonVertex[9])
        canvas.context.lineTo(polygonVertex[10], polygonVertex[11])
        canvas.context.closePath()
        canvas.context.fill()
      }
    }
  }
  const arrowCoord = (beginPoint: any, stopPoint: any) => {
    polygonVertex[0] = beginPoint.x
    polygonVertex[1] = beginPoint.y
    polygonVertex[6] = stopPoint.x
    polygonVertex[7] = stopPoint.y
    getRadian(beginPoint, stopPoint)
    polygonVertex[8] = stopPoint.x - arrow.edge * Math.cos(Math.PI / 180 * (angle + arrow.angle))
    polygonVertex[9] = stopPoint.y - arrow.edge * Math.sin(Math.PI / 180 * (angle + arrow.angle))
    polygonVertex[4] = stopPoint.x - arrow.edge * Math.cos(Math.PI / 180 * (angle - arrow.angle))
    polygonVertex[5] = stopPoint.y - arrow.edge * Math.sin(Math.PI / 180 * (angle - arrow.angle))
  }
  const sideCoord = () => {
    let midpoint: any = {};
    midpoint.x = (polygonVertex[4] + polygonVertex[8]) / 2
    midpoint.y = (polygonVertex[5] + polygonVertex[9]) / 2
    polygonVertex[2] = (polygonVertex[4] + midpoint.x) / 2
    polygonVertex[3] = (polygonVertex[5] + midpoint.y) / 2
    polygonVertex[10] = (polygonVertex[8] + midpoint.x) / 2
    polygonVertex[11] = (polygonVertex[9] + midpoint.y) / 2
  }
  const getRadian = (beginPoint: any, stopPoint: any) => {
    angle = Math.atan2(stopPoint.y - beginPoint.y, stopPoint.x - beginPoint.x) / Math.PI * 180
    arrow = {
      edge: 50,
      angle: 25
    }
    dynArrowSize(beginPoint, stopPoint)
  }
  const dynArrowSize = (beginPoint: any, stopPoint: any) => {
    let x1 = stopPoint.x - beginPoint.x
    let y1 = stopPoint.y - beginPoint.y
    let length = Math.sqrt(Math.pow(x1, 2) + Math.pow(y1, 2))
    if (length < 250) {
      arrow.edge = arrow.edge / 2
      arrow.angle = arrow.angle / 2
    } else if (length < 500) {
      arrow.edge = arrow.edge * length / 500
      arrow.angle = arrow.angle * length / 500
    }
  }
  const inputWord = () => {
    if (textflag) {
      if(textareaRef.current){
        textareaRef.current.style.top = offset.y + 'px'
        textareaRef.current.style.left = offset.x + 'px'
        textareaRef.current.style.display = 'block'
        textareaRef.current.focus()
      }
    }
  }
  const drawWord = () => {
    if(textareaRef.current){
      let words = textareaRef.current.value.trim()
      let size = 20
      if (textareaRef.current.style.display != "none" && words) {
        canvas.context.fillStyle = "red"
        canvas.context.font = size + "px 微软雅黑"
        canvas.context.fillText(words, parseInt(textareaRef.current.style.left) + 1, parseInt(textareaRef.current.style.top)+ size + 3)
        textareaRef.current.value = ''
      }
      textareaRef.current.style.display = 'none'
      textflag = false;
      setTimeout(() => {
        textflag = true
      }, 200)
    }
    
  }
  const changeProblem = (index: number) => {
    let temp = [...problem]
    temp[index].select = !temp[index].select
    setProblem(temp)
  }

  const reset = () => {
    let tempProblem = [...problem]
    setTool('')
    setAdvise('')
    tempProblem.forEach((item)=>{
      item.select = false
    })
    setProblem(tempProblem)
    flag = false
    canvas.context.clearRect(0, 0, canvas.width, canvas.height)
    url = "#"
    loadImg()
  }

  const submit = () => {
    let problemTemp: any = []
    problem.forEach((item: any)=>{
      if(item.select){
        problemTemp.push(item.val)
      }
    })
    let param = {
      advise_type: problemTemp.join(','),
      advise,
      imageData: ''
    }
    let wind: any = window
    wind.html2canvas(document.body, {
      onrendered(canvas: any) {
        let pic = canvas.toDataURL("image/png")
        pic = pic.replace(/^data:image\/(png|jpg);base64,/, "")
        param.imageData = pic
        closeFn(false)
        console.info(pic)
        accountApi.userfeedback(param).then((result: any)=>{
          if(result.code === 1 && result.msg){
            Toast(result.msg)
          }
        })
      }
    })
    
    
  }

  return (
    <div className={styles.feedback}>
      <div className={styles.tag}>
        <h4>{intl.formatMessage({id: 'selectQuestion'})}</h4>
        <ul>
          {
            problem.map((item: any, index: number)=>{
              return <li key={index} onClick={()=>changeProblem(index)} className={item.select ? styles.active : ''}>{item.val}</li>
            })
          }
        </ul>
        <textarea placeholder='Input content' onChange={(e)=>setAdvise(e.target.value)} value={advise}></textarea>
      </div>
      <div className={styles.tool}>
        {
          tools.map((item, index)=>{
            return (
              <span key={index} onClick={()=>setTool(item)}>
                <img src={cdnsPath + "/img/" + item + (item === tool ? '_selected.png' : '.png')} />
              </span>
            )
          })
        }
        <span onClick={()=>reset()} >Reset</span>
        <span style={{color: '#f82f34'}} onClick={()=>closeFn(false)}>{intl.formatMessage({id: 'close'})}</span>
        <span style={{color: '#1890ff'}} onClick={()=>submit()}>{intl.formatMessage({id: 'submit'})}</span>
      </div>
      <div className={styles.canvas}>
        <canvas ref={canvasRef} id="canvas" onMouseDown={(e)=>canvasDown(e)} onMouseUp={(e)=>canvasUp(e)} onMouseMove={(e)=>canvasMove(e)}></canvas>
        <div className={styles.canvasBorder}></div>
        <textarea ref={textareaRef} onBlur={()=>drawWord()} ></textarea>
      </div>
    </div>
  );
}

export default Feedback
