import React, { useState, useEffect, useMemo, useCallback } from 'react'
import { useIntl } from 'react-intl'
import PartList from '@/components/PartList'
import { Breadcrumb } from '@/page/Main'
import { getUrlParams } from '@/utils/utils'
import { BCrumb, Article, DataParams } from './inerface'
import { PartItemFormat, PagerType, ValueListFormat, ValueStateType } from '@/components/PartList/inerface'
import carApi from '@/api/carApi'

import styles from './cars.module.scss'

import ebay from '@/assets/images/ebay.png'
import amazon from '@/assets/images/amazon.png'
import RockAuto from '@/assets/images/RockAuto.png'


const CarsChannel: React.FC = () => {
  const intl = useIntl()
  const { brand, model, year, code, displacements } = getUrlParams()
  const [breadCrumb, setBreadCrumb] = useState<string[]>([
    'Parts brand', brand, model, year, displacements
  ])
  const [isMore, setIsMore] = useState<boolean>(false)
  const [cList, setCList] = useState<Article[]>([])
  const [cItem, setCItem] = useState<string>('')
  const [data, setData] = useState<PartItemFormat[]>([])
  const [loading, setLoading] = useState<boolean>(false)
  const [pager, setPager] = useState<PagerType>({
    page: 1,
    size: 10,
    total: 0
  })
  const [valueList, setValueList] = useState<ValueListFormat>({
    channelList: [],
    brandList: [],
    locationList: []
  })
  const [values, setValues] = useState<ValueStateType>({
    channel: [],
    brand: [],
    location: [],
  })

  const [channels, setChannels] = useState<any>({
    ebay: {
      img : ebay,
      progress: 0,
      status: 'reload'
    },
    rockauto: {
      img : RockAuto,
      progress: 0,
      status: 'reload'
    }
  })

  useEffect(() => {
    getList()
    getCList()
  }, [])

  useEffect(() => {

    if(valueList.channelList.length){
      let _pager = {...pager}
      if(_pager.page === 1){
        getData()
      }else {
        _pager.page = 1
        setPager(_pager)
      }
    }
  }, [values.channel, values.brand, values.location])

  useEffect(() => {
    if(pager.size) {
      console.info('2')
      getData()
    }
  }, [cItem, pager.page, pager.size])

  const title = useMemo(() => {
    let msg: string = breadCrumb.join(" > ");
    return msg;
  }, [breadCrumb]);

  function getData() {
    setLoading(true)
    let params: DataParams = Object.assign({}, {code: code})
    Object.assign(params, {
      ...pager,
      size: pager.size ? pager.size : 10
    })
    delete params.total
    if(cItem) {
      params = Object.assign(params, {
        second_sort: cItem
      })
    }
    if(values.channel.length) {
      params.channel = values.channel
    }
    if(values.brand.length) {
      params.brand = values.brand
    }
    if(values.location.length) {
      params.location = values.location
    }
    carApi.getData(params).then((res: any) => {
      if(res.code === 1) {
        let _pager = {...pager}
        _pager.total = res.data.total
        setData([...res.data.parts])
        setPager(_pager)
      }
      setLoading(false)
    }).catch(err => {
      setLoading(false)
    })
  }
  function getList() {
    carApi.getFilterList({
      code: code
    }).then((res: any) => {
      if(res.code === 1) {
        let _valueList = {...valueList}
        _valueList.channelList = res.data.channels ? [...res.data.channels] : []
        _valueList.brandList = res.data.brands ? [...res.data.brands] : []
        _valueList.locationList = res.data.locations ? [...res.data.locations] : []
        setValueList(_valueList)
      }
    })
  }
  function getCList() {
    carApi.getCList({
      code: code
    }).then((res: any) => {
      if(res.code === 1) {
        setCList(res.data.sort)
      }
    })
  }
  function onPagerChange(pagination: PagerType) {
    setPager({...pagination})
  }
  function cItemChange(item: string) {
    let _breadCrumb = [...breadCrumb]
    if(cItem) {
      _breadCrumb.splice(_breadCrumb.length - 1, 1, item)
    }else {
      _breadCrumb.push(item)
    }
    setCItem(item)
    setBreadCrumb(_breadCrumb)
  }

  const polling = (urlParamsOld: string, channel?: string, pidOld?: string, data?: any) => {
    const urlParamsTemp = JSON.stringify(getUrlParams()) || null
    if(urlParamsOld === urlParamsTemp){
      let param: any = {
        pid: pidOld
      }
      if(channel){
        param.channel = channel
      }
      carApi.polling(param).then((result: any) => {
        if(result.code === 1 && (urlParamsOld === urlParamsTemp)){
          let temp = JSON.parse(JSON.stringify(data || channels))
          // console.info(channels)
          let channelTemp: any = []
          Object.keys(result.data).forEach((key)=>{
            if(temp[key] && (!channel || key === channel)){
              const val = result.data[key]
              temp[key].status = val === 1 ? 'success' : 'reload'

              if(val === 1 || val === 3){
                temp[key].progress = 5
              }else {
                channelTemp.push(key)
                if(temp[key].progress < 4){
                  temp[key].progress++
                }else {
                  temp[key].progress = 1
                }
              }
            }
          })
          setChannels(temp)
          if(channelTemp.length){
            setTimeout(()=>{
              polling(urlParamsOld, channelTemp.length === 1 ? channelTemp.join(',') : channel, pidOld, temp)
            }, 5000)
          }else {
            getData()
          }
        }
      })
    }
    
  }

  return (
    <div className={styles['part-page']}>
      <div>
        <div className={styles['bread-crumb-wrap']}>
          <Breadcrumb />
          {/* <div className={styles['bread-crumb-lf']}>
            {
              breadCrumb.map((item, index) => (
                <Fragment key={index}>
                  <span className={index === 0 ? `${styles['first']}` : `${item.link ? styles['link'] : styles['default']}`}>{item.name}</span>
                  <span className={index === 0 ? `${styles['first']} ${styles['ml5']} ${styles['mr10']}` : `${styles['default']} ${styles['ml5']} ${styles['mr10']}`}> — </span>
                </Fragment>
              ))
            }
          </div> */}
          <div className={styles['bread-crumb-rt']} onClick={() => setIsMore(!isMore)}>
            <span>{intl.formatMessage({id: 'more'})}</span>
            {
              isMore ? <span className="icon iconfont" style={{color: "#333", fontSize: 14}}>&#xe7ee;</span> : <span className="icon iconfont" style={{color: "#333", fontSize: 14}}>&#xe7ed;</span>
            }
          </div>
        </div>
        <div className={styles['article']}>
          {
            cList.map((items: Article , index: number) => {
              return (
                <div key={index} className={(isMore || index < 3) ? styles['c-item-wrap'] : `${styles['c-item-wrap']} ${styles['hidden']}`}>
                  <div className={styles['c-item-lf']}>
                    {items.first_sort}:
                  </div>
                  <div className={styles['c-item-rt']}>
                    {
                      items.second_sort.map((item, n) => {
                        return (
                          <span key={n} className={cItem === item ? `${styles['c-item']} ${styles['active']}` : styles['c-item']} onClick={() => cItemChange(item)}>{item}</span>
                        )
                      })
                    }
                  </div>
                </div>
              )
            })
          }
          {/* {
            cList.length > 3 && isMore ? null : <div className={styles['c-item-wrap']}>
              <div className={styles['c-item-omit']}  onClick={() => setIsMore(true)}>……</div>  
            </div>
          } */}
        </div>
        <PartList from="cars" pager={pager} onPagerChange={onPagerChange} values={values} onValueChange={setValues} list={data} loading={loading} valueList={valueList} channels={channels} polling={polling} />
      </div>
    </div>
  )
}

export default CarsChannel