import React from "react";
import { useIntl } from 'react-intl'
import { EmptyProps } from "./interface";
import EmptySvg from "./empty.svg";
import styles from './empty.module.scss'

const Empty: React.FC<EmptyProps> = (props) => {

  const intl = useIntl()
  const { prefixCls = "xy-empty", className, style, image, imageStyle, description = intl.formatMessage({id: 'noData'}), children } = props;
  let classes = styles[`${prefixCls}`]
  if(className) {
    classes += ' ' + className
  }
  return (
    <div className={classes} style={style}>
      <div className={styles[`${prefixCls}-image`]} style={imageStyle}>
          <img className={styles[`${prefixCls}-img`]} src={image || EmptySvg} alt="empty" />
      </div>
      <p className={styles[`${prefixCls}-description`]}>{description}</p>
      {children && <div className={styles[`${prefixCls}-footer`]}>{children}</div>}
    </div>
  );
}

export default React.memo(Empty);
