import React, { useEffect, useState, useRef } from 'react'
import { useIntl } from 'react-intl'
import Upload from '@/components/Upload'
import Toast from '@/components/Toast'
import { Three } from '@/components/ThreeModel'
import { v4 as uuidv4 } from 'uuid'
import ugcApi from '@/api/ugcApi'

import styles from './ugc.module.scss'

import defaultPart from '@/assets/images/default_part.png'
import threeGif from '@/assets/images/three_icon.gif'

interface PropsIn {
  pid: string,
  brandCode: string,
  ocr: any,
  setOcr: (value: []) => void
}

const Images: React.FC<PropsIn> = (props) => {
  const { pid, brandCode, ocr, setOcr } = props
  const intl = useIntl()
  const [uploadLoading, setUploadLoading] = useState<boolean>(false)
  
  const [active, setActive] = useState<number>(0)
  const [uuid, setUuid] = useState<string>(uuidv4())
  // useEffect(() => {
  //   changeActive(0)
  // }, [])

  

  const beforeUpload = (file: File, FileList: File[]) => {
    return new Promise((resolve, reject) => {
      resolve(file)
    })
  }
  const start = (result: any) => {
    console.info(result)
    if(!result.name.endsWith('mtl')){
      setUuid(uuidv4())
    }
    
    setUploadLoading(true)
  }
  const success = (result: any, file: File) => {
    if(result.code === 1){
      if(result.data.img_url.endsWith('mtl')){
        return
      }
      let ocrTemp: any = [...ocr]
      result.data.has = ''
      result.data.love_count = 0
      result.data.bad_count = 0
      result.data.candel = 1
      result.data.pic = result.data.img_url
      ocrTemp.unshift(result.data)
      setUploadLoading(false)
      setActive(0)
      setOcr(ocrTemp)
    }else {
      setUploadLoading(false)
    }
  }
  const error = (result: any) => {
    Toast('error', 'warning')
    setUploadLoading(false)
  }

  const operate = (type: string) => {
    let ocrTemp: any = [...ocr]
    let api
    
    if(ocrTemp[active].has && JSON.stringify(ocrTemp[active].has) !== ''){
      return
    }
    if(type === 'love'){
      api = ugcApi.love
      ocrTemp[active].has = 'love'
      ocrTemp[active].love_count += 1
    }else {
      api = ugcApi.bad
      ocrTemp[active].has = 'bad'
      ocrTemp[active].bad_count += 1
    }
    setOcr(ocrTemp)
    api({pid, brandCode, ugc_id: ocr[active].ugc_id}).then((result: any)=>{
      if(result.code === 1){
        Toast('Success!')
      }
    })
  }
  const changeActive = (index: number) => {
    let ocrTemp: any = [...ocr]
    setActive(index)
    if(ocrTemp[index] && ocrTemp[index].has === undefined){
      ugcApi.hasoperate({pid, brandCode, ugc_id: ocrTemp[index].ugc_id}).then((result: any)=>{
        if(result.code === 1){
          ocrTemp[index].has = result.data ? result.data.operate : ''
          setOcr(ocrTemp)
        }
      })
    }
  }

  const picdel = (e: any, index: number) => {
    e.stopPropagation()
    let ocrTemp: any = [...ocr]
    let ids = ocrTemp[index].ugc_id
    ocrTemp.splice(index, 1)
    setOcr(ocrTemp)
    ugcApi.picdel({ids}).then((result: any)=>{
      if(result.code === 1){
        Toast('Success!')
      }
    })
  }
  return (
    <div className={styles.images}>
      <div className={styles.larger}>
        <div className={styles.img}>
          {
            ocr[active]?.pic.endsWith('gltf') ? (
              <Three file={ocr[active]?.pic} key={active} />
            ): (
              <img src={ocr[active]?.pic || defaultPart} />
            )
          }
        </div>
        {
          active ? (
            <div className={styles.l} onClick={()=>changeActive(active - 1)}><i className="iconfont iconleft" /></div>
          ): null
        }
        {
          active < ocr.length - 1 ? (
            <div className={styles.r} onClick={()=>changeActive(active + 1)}><i className="iconfont iconright" /></div>
          ): null
        }
        {
          ocr[active] ? (
            <div className={styles.b}>
              <span onClick={()=>operate('love')} className={ocr[active]?.has === 'love' ? styles.excite + ' ' + styles.active : styles.excite}><i className="iconfont icondianzan1" /> {ocr[active]?.love_count}</span>
              <span onClick={()=>operate('bad')} className={ocr[active]?.has === 'bad' ? styles.sinking + ' ' + styles.active : styles.sinking}><i className="iconfont iconmeiyong" /> {ocr[active]?.bad_count}</span>
            </div>
          ): null
        }
        
      </div>
      <div className={styles.list}>
        <div className={styles.item}>
          <div className={styles.uploadImg}>
            {
              <Upload
                multiple
                parse={ugcApi.upload}
                setLoading={setUploadLoading}
                loading={uploadLoading}
                action={ugcApi.uploadPath}
                name="img"
                data={{pid, brandCode, uuid: uuid.replace(/\-/g, '')}}
                beforeUpload={beforeUpload}
                start={start}
                success={success}
                error={error}
              >
                <div className={styles.upload}>
                  <i className="iconfont icontop" />
                  <p>{intl.formatMessage({id: 'dragFile'})}</p>
                </div>
              </Upload>
            }
          </div>
        </div>
        {
          ocr.map((item: any, index: number)=>{
            return (
              <div onClick={()=>changeActive(index)} className={active === index ? (styles.item + ' ' + styles.active) : styles.item} key={index}>
                {
                  item.candel ? (
                    <span className={styles.del}><i className="iconfont iconshanchu" onClick={(e)=>picdel(e, index)} /></span>
                  ): null
                }
                {
                  item.pic.endsWith('gltf') ? <img src={threeGif} style={{width: 48}} /> : <img src={item.pic} />
                }
                
              </div>
            )
          })
        }
      </div>
    </div>
  )
}

export default Images