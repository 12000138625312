import React, { useState, useEffect, useCallback } from 'react';
import { useIntl } from 'react-intl'
import MenuTmpl from './MenuTmpl'
import PartItem from './PartItem'
import NumInput from './NumInput'
import Radio, { RadioGroup } from '@/components/Radio'
import Pagination from '@/components/Pagination'
import Spin from '@/components/Spin'
import Empty from '@/components/Empty'
import Toast from '@/components/Toast'
import { PartListProps, ValueStateType, ValueStateAction, PartItemFormat, ValueType } from './inerface'
import styles from './partList.module.scss';


const PartList: React.FC<PartListProps> = (props) => {
  const intl = useIntl()
  const { from, loading = false, list = [], valueList = {
      channelList: [],
      brandList: [],
      locationList: []
    },
    pager =  {
      page: 1,
      size: 10,
      total: 0,
    },
    onValueChange,
    sort,
    setSort,
    values,
    channels,
    polling,
    showTrend,
    itemWidth
  } = props
  const [data, setData] = useState<PartItemFormat[]>(list)
  // const [sort, setSort] = useState<string>('')
  /* const [price, setPrice] = useState<PriceType>({
    minPrice: 0,
    maxPrice: 0
  }); */
  const [valuesState, setValues] = useState<ValueStateType>({
    channel: [],
    brand: [],
    location: [],
  })
  const sortList = [
    {
      value: '',
      label: intl.formatMessage({id: 'bestMatch'})
    },
    {
      value: 'asc',
      label: intl.formatMessage({id: 'priceLowHigh'})
    },
    {
      value: 'desc',
      label: intl.formatMessage({id: 'priceHighLow'})
    }
  ]
  /* const getData = useCallback(() => {
    return [...list]
  }, [list]) */
  useEffect(() => {
    setData([...list])
  }, [list])
  /* function priceChange(priceValue: PriceAction) {
    const { type, value } = priceValue
    let _price = {...price}
    _price[type] = value
    setPrice({..._price})
  } */
  function valuesChange(valueState: ValueStateAction) {
    const { type, value } = valueState
    let _valuesState = {...values}
    _valuesState[type] = value
    // console.info(_valuesState)
    onValueChange({..._valuesState})
    // setValues({..._valuesState})
  }
  function pagerChange(page: number) {
    props.onPagerChange({
      ...pager,
      page
    })
  }
  function sizeChange(value: string) {
    let pageSize = parseInt(value)
    if(pageSize) {
      props.onPagerChange({
        ...pager,
        page: 1,
        size: pageSize
      })
    }else {
      Toast(intl.formatMessage({id: 'pageCannotEmpty'}), 'warning')
      return false
    }
  }
  return (
    <div
    className={styles['part-list-cps']}
    >
      <div className={styles['part-cps-lf']}>
        {/* <div>
          <div className={styles['lf-title']}>Price</div>
          <div className={styles['price-con']}>
            <NumInput value={price.minPrice} type="minPrice" onChange={priceChange}/>
            <span>—</span>
            <NumInput value={price.maxPrice} type="maxPrice" onChange={priceChange}/>
            <span className={styles['arrow-rt-btn']}>
              <i className="icon iconfont" style={{fontSize: 30, color: "#777777"}}>&#xe65a;</i>
            </span>
          </div>
        </div> */}
        <MenuTmpl type="channel" title="Channel" keyProps={{name: "type", num: "amount"}} list={valueList['channelList']} values={values.channel} setValues={valuesChange}/>
        {
          valueList['brandList'].length ? <MenuTmpl type="brand" title="Brand" keyProps={{name: "type", num: "amount"}} list={valueList['brandList']} hasMore={true} values={values.brand} setValues={valuesChange}/> : null
        }
        {
          valueList['locationList'].length ? <MenuTmpl type="location" title="Location" keyProps={{name: "type", num: "amount"}} list={valueList['locationList']} values={values.location} setValues={valuesChange}/> : null
        }
      </div>
      <div className={styles['part-cps-rt']}>
        <div className={styles['part-cps-rt-head']}>
          {
            from === "detail" ? <div>
              <RadioGroup value={sort} onChange={value => setSort(value)} >
                {
                  sortList.map((item, index) => {
                    return (
                      <span key={item.value} style={{marginLeft: index > 0 ? 20 : 0}}>
                        <Radio value={item.value}>{item.label}</Radio>
                      </span>
                    )
                  })
                }
              </RadioGroup>
            </div> : <div></div>
          }
          <div className={styles['part-list-total']}>{pager ? pager.total : '0'} parts queried</div>
        </div>
        {
          loading ? <div className={styles['part-list-loading']}>
            <Spin loading={true}/>
          </div> : (data.length > 0 ? <div className={styles['part-list-wrap']}>
            {
              data.map((item: PartItemFormat, index: number) => {
                return (
                  <PartItem style={{width: itemWidth}} item={item} index={index} key={index} from={from} channels={channels} polling={polling} showTrend={showTrend} />
                )
              })
            }
          </div> : <div className={styles['part-list-no-data']}>
            <Empty/>
          </div>)
        }
        {
          pager && pager.total && from ? <div className={styles['part-list-page-wrap']}>
            <Pagination current={pager.page} pageSize={pager.size} total={pager.total} onChange={pagerChange}/>
            {/* <div className={styles['part-list-page-rt']}>
              <span>Item Per Page</span>
              <span className={styles['size-input-wrap']}>
                <input type="number" value={pager.size} onChange={e => sizeChange(e.target.value)} />
              </span>
            </div> */}
          </div> : null
        }
      </div>
    </div>
  )
}

export default PartList