import React,{ useState, useEffect, Fragment, useContext } from 'react'
import { Link } from 'react-router-dom'
import { useIntl } from 'react-intl'
import MainContext from '@/context/MainContext'
import accountApi from '@/api/account'
import styles from './my.module.scss'
import touxiang from "@/assets/images/touxiang.png"

interface Iprops {
  tab: number,
}
const Mymenu: React.FC<Iprops> = (props) => {
  const intl = useIntl()
  const { tab } = props
  const { store } = useContext(MainContext)
  const { userInfo } = store

  return (
    <Fragment>
      <div className={styles.my}>
        <div className={styles.content}>
          <div className={styles.header}>
            <img className={styles.hpic} src={userInfo.avator ? userInfo.avator : touxiang} alt="" />
            <div className={styles.pinfo}>
              <div className={styles.pname}>
                <span>{userInfo.data ? userInfo.data.full_name || userInfo.data.username : ''}</span> &nbsp;
              </div>
              <div className={styles.email}>
                {userInfo.data?.username ? userInfo.data?.username : ''}
              </div>
            </div>
          </div>
          <div className={styles.main}>
            <div className={styles.mainLeft}>
              <Link to='/my/account'><span className={tab === 1 ? styles.active : ''} ><i className='iconfont iconwode1'/>&nbsp; {intl.formatMessage({id: 'account'})}</span></Link>
              <Link to='/my/purchase'><span className={tab === 2 ? styles.active : ''} ><i className='iconfont icongoumai'/>&nbsp; {intl.formatMessage({id: 'purchase'})}</span></Link>
              <Link to='/my/message'><span className={tab === 4 ? styles.active : ''} ><i className='iconfont iconxiaoxi'/>&nbsp; {intl.formatMessage({id: 'message'})}</span></Link>
              <Link to='/my/favorite'><span className={tab === 3 ? styles.active : ''} ><i className='iconfont iconshoucangmoren'/>&nbsp; {intl.formatMessage({id: 'favorites'})}</span></Link>
              <Link to='/my/coupon'><span className={tab === 5 ? styles.active : ''} ><i className='iconfont iconbaojiadan'/>&nbsp; {intl.formatMessage({id: 'coupon'})}</span></Link>
            </div>
            <div  className={styles.mainRight}>
              {
                props.children
              }
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  )
}

export default Mymenu;