import React, { Fragment, useState, useEffect, useContext, useRef } from 'react'
import { useHistory } from 'react-router-dom'
import { useIntl } from 'react-intl'
import Tooltip from 'rc-tooltip'
import Select, { Option } from 'rc-select'
import { getUrlParams } from '@/utils/utils'
import MainContext from '@/context/MainContext'
import axios from 'axios'
import catalogApi from '@/api/catalogApi'

import styles from './catalog.module.scss'

const CancelToken = axios.CancelToken

const type = ['Original', 'Standard']



const Catalog: React.FC = () => {
  const intl = useIntl()
  const { brandcode } = getUrlParams()
  const history = useHistory()
  const { store, setStore } = useContext(MainContext)
  const { userInfo } = store
  let modelsCancelToken: any = useRef<any>(null)
  const [bread, setBread] = useState<any>({
    path: '',
    acronym: [],
    data: []
  })
  const [title, setTitle] = useState<any>([])
  const [models, setModels] = useState<any>([])

  const [acronymActive, setAcronymActive] = useState<string>('All')
  const [breadActive, setBreadActive] = useState<any>([])
  const [typeActive, setType] = useState<string>('Original')

  const [selectDataShow, setSelectDataShow] = useState<boolean>(false)
  const [selectData, setSelectData] = useState<any>([
    {
      placeholder: 'Select Brand',
      loading: false,
      val: null,
      data: []
    },
    {
      placeholder: '',
      loading: false,
      val: null,
      data: []
    },
    {
      placeholder: '',
      loading: false,
      val: null,
      data: []
    },
    {
      placeholder: '',
      loading: false,
      val: null,
      data: []
    }
  ])

  useEffect(() => {
    getBrand()
    document.body.removeEventListener('paste', pasteUpdate)
  }, [])

  useEffect(() => {
    if(breadActive.length){
      getTitle(breadActive[0])
    }
  }, [typeActive])

  useEffect(() => {
    if(!breadActive.length){
      setType('Original')
    }
  }, [breadActive.length])

  useEffect(() => {
    if(models.length){
      const item = models[models.length - 1]
      if(item.length === 1 && item[0].has_child){
        getModels(item[0], item[0].level)
      }
    }
  }, [models])
  
  const getBrand = () => {
    catalogApi.brands().then((result: any)=>{
      if(result.code === 1){
        let temp: string[] = ['All']
        let selectDataTemp = JSON.parse(JSON.stringify(selectData))
        result.acronym_keys.forEach((item: string)=>{
          // if(item.length === 1){
          //   temp.push(item)
          // }
          temp.push(item)
        })
        let param = {
          path: result.IMG_DNS,
          acronym: temp,
          data: result.data
        }

        if(brandcode){
          result.data.forEach((item: any)=>{
            if(item.brandcode === brandcode){
              getTitle(item)
            }
          })
        }else {
          // getTitle({brandcode: result.data[0].brandcode}, selectDataTemp)
        }

        selectDataTemp[0].data = result.data
        setBread(param)
        
      }
    })
  }
  const getTitle = (item: any, sd?: any) => {
    if(!sd){
      setBreadActive([item])
      setSelectDataShow(false)
      setTitle([])
    }
    
    let param: any = {
      brandCode: item.brandcode
    }
    if(typeActive === 'Standard' && !sd){
      param.model = '007'
    }
    
    catalogApi.title(param).then((result: any)=>{
      if(result.code === 1){
        if(!sd){
          setTitle(result.data)
          setModels([])
          getModels(item, 0)
        }else {
          let selectDataTemp = JSON.parse(JSON.stringify(sd))
          result.data.forEach((item: string, index: number)=>{
            if(selectDataTemp[index+1]){
              selectDataTemp[index+1].placeholder = item
            }
          })
          setSelectData(selectDataTemp)
        }
        
      }
    })
  }
  const getModels = (item: any, level: number, sd?: any) => {
    if(item.click === 0){
      setStore({value: true, type: 'purchaseVisible'})
      return
    }
    if(modelsCancelToken.current){
      modelsCancelToken.current('modelsCancelToken')
    }
    const cancelToken = new CancelToken(c => modelsCancelToken.current = c);
    item.type = 'config'
    if(item.has_child || level === 0){
      let param: any = {}
      if(level){
        param = {
          level,
          brandCode: item.brandCode,
          name: item.name,
          full_condition_path: item.full_condition_path,
          next_path_md5: item.next_path_md5
        }
      }else {
        param = {
          level,
          brandCode: item.brandcode
        }
      }
      if(typeActive === 'Standard'){
        param.model = '007'
      }

      catalogApi.models(param, {cancelToken}).then((result: any)=>{
        modelsCancelToken.current = null
        let selectDataTemp = JSON.parse(JSON.stringify(sd || '[]'))
        if(sd){
          selectDataTemp[level + 1].loading = false
        }
        if(result.code === 1){
          let tempModels = level === 0 ? [] : [...models]
          let tempBreadActive = [...breadActive]
          let tempTitle = [...title]

          item.key = ''

          if(tempModels.length > level){
            // item.key = tempBreadActive[level].key
            tempModels.splice(level, tempModels.length - level)
            tempBreadActive.splice(level, tempBreadActive.length - level)
          }
          if(level){
            tempTitle[level] = result.title
            setTitle(tempTitle)
          }
          
          tempBreadActive.push(item)
          tempModels.push(result.data)
          if(sd){
            selectDataTemp[level + 1].data = result.data
          }

          setBreadActive(tempBreadActive)
          setModels(tempModels)
          
        }
        if(sd){
          setSelectData(selectDataTemp)
        }
        
      })
    }else {
      let tempCrumb: any = [...breadActive]
      tempCrumb.push(item)
      tempCrumb.unshift({
        type: 'logo',
        img: bread.path + tempCrumb[0].img
      })
      // tempCrumb[0].type = 'logo'
      // tempCrumb[0].img = bread.path + tempCrumb[0].img
      delete tempCrumb[1].type
      
      setStore({value: tempCrumb, type: 'crumb'})
      
      window.sessionStorage.setItem('crumb', JSON.stringify(tempCrumb))
      history.push(`/group?mcid=${item.mcid.replace(/\=/g, '%3D')}&brandcode=${item.brandCode}&num=0`)
    }
  }
  const changeBreadActive = (index: number) => {
    let tempBreadActive = [...breadActive]
    tempBreadActive.splice(index, tempBreadActive.length - index)
    setBreadActive(tempBreadActive)
    // if(!index){
    //   setSelectDataShow(true)
    // }
    
  }
  const search = (val: string, index: number) => {
    let tempBreadActive = [...breadActive]
    if(!tempBreadActive[index]){
      tempBreadActive[index] = {}
    }
    tempBreadActive[index].key = val
    setBreadActive(tempBreadActive)
  }

  const changeSelectData = (val: string, level: number, i: string) => {
    let selectDataTemp = JSON.parse(JSON.stringify(selectData))
    let num = selectDataTemp.length - 1 - level
    selectDataTemp[level].val = val
    if(num){
      selectDataTemp[level + 1].loading = true
      for(let i = level + 1; i < selectDataTemp.length - 1; i++){
        selectDataTemp[i].data = []
        selectDataTemp[i].val = null
      }
    }
    setSelectData(selectDataTemp)
    
    // console.info(val, level, option)
    let item = selectDataTemp[level].data[i]
    getModels(item, level, selectDataTemp)
  }
  const pasteUpdate = () => {
    console.info('123')
  }
  return (
    <div className={styles.catalog}>
      {
        selectDataShow ? (
          <div className={styles.vehicle}>
            <h2>Find your vehicle</h2>
            <div className={styles.select}>
              {
                selectData.map((item: any, index: number)=>{
                  return (
                    <div className={styles.item}>
                      <Select
                        showSearch
                        optionFilterProp="name"
                        placeholder={item.placeholder}
                        listHeight={400}
                        disabled={item.data.length ? false : true}
                        loading={item.loading}
                        onChange={(val: string, option: any)=>changeSelectData(val, index, option.key)}
                        value={item.val}
                        style={{ width: '100%' }}
                      >
                        {
                          item.data.map((d: any, i: number)=>{
                            return (
                              <Option value={d.brandcode} key={i} style={{ color: (d.click !== 0 || !index) ? '' : '#b6b5b5' }} name={d.name}>{d.name}</Option>
                            )
                          })
                        }
                      </Select>
                    </div>
                  )
                })
              }
              {/* <div className={styles.item}>
                <button>Search</button>
              </div> */}
            </div>
          </div>
        ): null
      }
      
      <div className={styles.top}>
        <div className={styles.acronym}>
          {
            breadActive.length && !selectDataShow ? <img src={bread.path + breadActive[0].img} /> : null
          }
          <h6>{intl.formatMessage({id: 'brand'})}：</h6>
          {
            breadActive.length && !selectDataShow ? (
              <ul>
                {
                  breadActive.map((item: any, index: number)=>{
                    return (
                      <Fragment key={index}>
                        <li onClick={()=>changeBreadActive(index)}>
                          {
                            item.name.length > 36 ? (
                              <Tooltip mouseLeaveDelay={0} placement="top" overlay={item.name} overlayStyle={{width: 222}} >
                                <span>{item.show_name? item.show_name.substring(0, 36) : item.name.substring(0, 36)}...</span>
                              </Tooltip>
                            ): (
                              <>{item.show_name || item.name}</>
                            )
                          }
                        </li>
                        {
                          breadActive.length !== index + 1 ? <li>&gt;</li> : null
                        }
                        
                      </Fragment>
                    )
                  })
                }
              </ul>
            ): (
              <ul>
                {
                  bread.acronym.map((item: string, index: number)=>{
                    return (
                      <li onClick={()=>setAcronymActive(item)} key={index} className={acronymActive === item ? styles.active : ''}>{item}</li>
                    )
                  })
                }
              </ul> 
            )
          }
        </div>
        <div className={styles.type}>
          {
            breadActive[0]?.has_standard_model ? type.map((item: string, index: number)=>{
              return (
                <label key={index}>
                  <input type="radio" name="type" value={item} checked={item === typeActive} onChange={()=>setType(item)} /> {item}
                </label>
              )
            }): null
          }
        </div>
      </div>
      {
        breadActive.length && !selectDataShow ? (
          <div className={styles.model}>
            {
              title.map((item: string, index: number)=>{
                return (
                  <div className={styles.item} key={index}>
                    <h5>{item}</h5>
                    {
                      models[index] && models[index].length > 10 ? (
                        <input type="text" value={breadActive[index]?.key || ''} placeholder="Search" onChange={(e)=>search(e.target.value, index)} />
                      ): null
                    }
                    <ul>
                      {
                        models[index]?.map((model: any, i: number)=>{
                          return (
                            !breadActive[index] || !breadActive[index].key || model.name.toLowerCase().indexOf(breadActive[index].key.toLowerCase()) !== -1 ? (
                              <li className={breadActive[index + 1]?.name === model.name ? (model.click === 0 ? (styles.grey + ' ' + styles.active) : styles.active) : (model.click === 0 ? styles.grey : '') } key={i} onClick={()=>getModels(model, model.level)}>
                                {
                                  userInfo.userid ? (
                                    <>{model.show_name}</>
                                  ): (
                                    breadActive[index + 1]?.name === model.name ? (
                                      <>{model.show_name}</>
                                    ): (
                                      model.click ? (
                                        <span>
                                          {model.show_name}
                                          <div className={styles.guide}>
                                            <i></i>
                                            <div><em className='iconfont iconwangluotixing'></em>{intl.formatMessage({id: 'sampleSearch'})}</div>
                                          </div>
                                        </span>
                                      ): (
                                        <>{model.show_name}</>
                                      )
                                    )
                                  )
                                }
                                
                              </li>
                            ): null
                          )
                        })
                      }
                    </ul>
                  </div>
                )
              })
            }
          </div>
        ): (
          <div className={styles.brand}>
            <ul>
              {
                bread.data.map((item: any, index: number)=>{
                  return (
                    acronymActive === 'All' || acronymActive === item.acronym ? (
                      <li key={index} onClick={()=>getTitle(item)}>
                        <img alt={item.label} src={bread.path + item.img} />
                        <p>{item.name}</p>
                      </li>
                    ): null
                  )
                })
              }
            </ul>
          </div>
        )
      }
      
    </div>
  )
}

export default Catalog