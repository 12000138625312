import React, { useState, useRef, useEffect } from 'react'
import Spin from '@/components/Spin'
import styles from './loading.module.scss'

interface PropsIn {
  visible: boolean,
  type?: string
  color?: string
}

const Loading: React.FC<PropsIn> = (props) => {
  const { visible, children, type, color } = props
  return (
    visible ? (
      <div className={type === 'min' ? styles.min + ' ' + styles.loading : styles.loading}>
        <div className={styles.opacity}>
          {children}
        </div>
        {/* <div className={styles.transition}>
          <span style={{borderColor: color ? color : '#1890ff', borderBottomColor: 'transparent' }} ></span>
        </div> */}
        <div className={color ? styles.white : styles.general}>
          <span></span>
        </div>
      </div>
    ): (
      <>
        {children}
      </>
    )
  )
}

export default Loading