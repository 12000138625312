import React, { useState, useRef, useEffect, useContext } from 'react'
import { Link } from 'react-router-dom'
import { useIntl } from 'react-intl'
import Tooltip from 'rc-tooltip'
import Tabs, { TabPane } from 'rc-tabs'
import MainContext from '@/context/MainContext'
import Loading from '@/components/Loading'
import Empty from '@/components/Empty'
import Modal from '@/components/Modal'
import Trend from '@/components/Trend'
import { Three } from '@/components/ThreeModel'
import { PartDetail } from '@/page/Vin'
import carApi from '@/api/carApi'
import threeApi from '@/api/threeApi'
import vinApi from '@/api/vinApi'
import accountApi from '@/api/account'
import { getUrlParams } from '@/utils/utils'
import styles from './threeModel.module.scss'

import logoImg from '@/assets/images/logo.png'
import sample from '@/assets/images/sample.png'

interface PropsIn {
  vin: string,
  setTmVisible: (val: boolean) => void
}


const ThreeModel: React.FC<PropsIn> = (props) => {
  const { vincode, vinmcid, mcid, brandcode } = getUrlParams()
  const intl = useIntl()
  const { store } = useContext(MainContext)
  const { crumb } = store

  const { vin, setTmVisible } = props
  const [data, setData] = useState<any>({
    data: [],
    color: ''
  })
  const [activeMenu, setActiveMenu] = useState<number>(0)

  const [group, setGroup] = useState<any>({})
  const [groupLoading, setGroupLoading] = useState<boolean>(false)
  const [activeGroup, setActiveGroup] = useState<string>('')
  const [parts, setParts] = useState<any>({})
  const [partsLoading, setPartsLoading] = useState<boolean>(false)

  const [trendData, setTrendData] = useState<any>([])
  const [trendVisible, setTrendVisible] = useState<boolean>(false)
  const [trendLoading, setTrendLoading] = useState<boolean>(false)


  const [ detailPart, setDetailPart ] = useState<any>({})
  const [ partDetailVisible, setPartDetailVisible ] = useState<boolean>(false)

  const [treeData, setTreeData] = useState<any>([])

  useEffect(()=>{
    getModel()
  },[])

  useEffect(()=>{
    if(data.data[activeMenu]){
      setGroup({})
    }
  },[data, activeMenu])


  useEffect(()=>{
    let item = group[Object.keys(group)[Object.keys(group).length - 1]]
    setActiveGroup(item?.uuid || '')
  }, [group])
  
  useEffect(()=>{
    if(activeGroup){
      getDetail()
    }else {
      setParts({})
    }
  }, [activeGroup])

  useEffect(()=>{
    if(detailPart.pid){
      setPartDetailVisible(true)
    }
  }, [detailPart])


  const getModel = () => {
    let param = {
      mcid: mcid || vinmcid,
      vin,
      brandCode: brandcode || vincode
    }
    threeApi.getModel(param).then((result: any)=>{
      if(result.code === 1){
        setData({
          data: result.data,
          color: result.color
        })
      }
    })
  }

    
  const getDetail = () => {
    let temp = {...parts}
    let item = group[activeGroup]
    if(temp[activeGroup]){
      return
    }
    let std = {
      main: item.gid, 
      relate: ''
    }
    let param = {
      mcid: mcid || vinmcid,
      vin,
      brandCode: brandcode || vincode,
      std_label_id: window.btoa(JSON.stringify(std)),
      position: item.position,
      lever: 2
    }
    setPartsLoading(true)
    threeApi.getDetail(param).then((result: any)=>{
      setPartsLoading(false)
      if(result.code === 1 && result.data){
        temp[activeGroup] = result.data
        setParts(temp)
        getPrice(temp, activeGroup)
      }
    })
  }
  const getPrice = (ps: {}, ag: string) => {
    let tempData: any = JSON.parse(JSON.stringify(ps))
    let pid_list: any = []
    tempData[ag]?.forEach((item: {pid: string})=>{
      pid_list.push(item.pid)
    })
    
    let param = {
      vin,
      brandCode: brandcode || vincode,
      pid_list: pid_list.join(',')
    }
    
    vinApi.tag(param).then((result: any)=>{
      if(result.code === 1){
        tempData[ag]?.forEach((item: any)=>{
          if(result.data[item.pid]){
            item.tag = result.data[item.pid]
          }
        })
        // console.info(tempData)
        setParts(tempData)
      }
    })
  }

  const showTrend = (param: any) => {
    setTrendVisible(true)
    setTrendLoading(true)
    setTrendData([])
    vinApi.pricetrend(param).then((result: any)=>{
      setTrendLoading(false)
      if(result.code === 1){
        setTrendData({
          data: result.data,
          max: Math.ceil(result.max_price) + 20,
          min: Math.ceil(result.min_price) - 40 > 0 ? Math.ceil(result.min_price) - 40 : 0
        })
      }
    })
  }
  const getPras = (index: number, type: number) => {
    let tempData = {...parts}
    tempData[activeGroup][index].progress = 1
    setParts(tempData)
    carApi.getData({pid: tempData[activeGroup][index].pid}).then((res: any) => {
      if(res.code === 1) {
        polling(index, type)
      }
    })
  }
  const polling = (index: number, type: number) => {
    if(type === activeMenu){
      let param: any = {
        pid: parts[activeGroup][index].pid
      }
      carApi.polling(param).then((result: any) => {
        if(result.code === 1 && (type === activeMenu)){
          let tempData = {...parts}
          let is = false
          Object.keys(result.data).forEach((key)=>{
            const val = result.data[key]
            if(val === 1 || val === 3){
              is = true
            }
          })
          
          if(is){
            tempData[activeGroup][index].progress = 0
            getPrice(parts, activeGroup)
          }else {
            if(tempData[activeGroup][index].progress < 4){
              tempData[activeGroup][index].progress++
              console.info()
            }
            setTimeout(()=>{
              polling(index, type)
            }, 5000)
          }
          setParts(tempData)
        }
      })
    }
  }
  const setFavourite = (index: number) => {
    let temp = {...parts}
    let item = temp[activeGroup][index]
    let val = item.tag.hasfavorite ? false : true
    item.tag.hasfavorite = val
    setParts(temp)
    
    let param: any = {
      pid: item.pid,
      brandCode: brandcode
    }
    let api = accountApi.favouriteDel
    if(val){
      api = accountApi.favouriteAdd
      param.name = item.label
    }
    api(param).then()
  }

  const getTree = (modelTemp: any) => {
    threeApi.getTree().then((result: any)=>{
      if(result.code === 1){
        // console.info(modelTemp)
        result.data.forEach((item: any)=>{
          item.show = false
          item.check = true
          item.switch = false
          item.subs.forEach((s: any)=>{
            s.show = false
            s.check = true

            // console.info(modelTemp.indexOf(s.sub_id.slice(0, 4)))
            if(modelTemp.indexOf(s.sub_id.slice(0, 4)) !== -1){
              item.show = true
              s.show = true
            }
          })
        })
        setTreeData(result.data)
      }
    })
  }
  
  const downModel = (objects: any, status: boolean) => {
    if(!status) {
      let temp = {...group}
      delete temp[objects[0].object.uuid]
      setGroup(temp)
    }else {
      let name = objects[0].object.name
      if(name.indexOf('_') === 0){
        name = name.substr(1)
      }
      let nameArr = name.split('_')
      
      let std = [
        {
          gid: nameArr[0],
          position: nameArr[1]?.split('-').join(',')
        }
      ]
      let param = {
        mcid: mcid || vinmcid,
        vin,
        brandCode: brandcode || vincode,
        classlist: JSON.stringify(std),
        lever: 2
      }

      threeApi.getLabel(param).then((result: any)=>{
        if(result.code === 1 && result.data[0]){
          let temp = {...group}
          result.data[0].uuid = objects[0].object.uuid
          temp[objects[0].object.uuid] = result.data[0]
          setGroup(temp)
        }
      })
    }
  }
  return (
    <>
      <div className={styles.threeModel}>
        <div className={styles.logo}>
          <img src={logoImg} alt="logo" />
          <span></span>
          {
            crumb[0]?.img ? <img src={crumb[0].img} style={{height: 30}} /> : null
          }
          {vin}
        </div>
        <span className={styles.closed} onClick={()=>setTmVisible(false)}><i className="iconfont icondanchuangguanbi" /></span>
        <ul className={styles.menu}>
          {
            data.data.map((item: any, index: number)=>{
              return (
                <li key={index} className={index === activeMenu ? styles.active : ''} onClick={()=>setActiveMenu(index)}>{item.label}</li>
              )
            })
          }
        </ul>

        <div className={styles.content}>
          <div className={styles.l}>
            {
              activeMenu === 0 ? (
                data.data[activeMenu] ? <Three file={data.data[activeMenu]?.content.img} bodyColor={data.color} data={treeData} setData={setTreeData} sceneBack={getTree} click={downModel} ev /> : null
              ): null
            }
            {
              activeMenu === 1 ? (
                data.data[activeMenu] ? <Three file={data.data[activeMenu]?.content.img} click={downModel} ev /> : null
              ): null
            }
            {
              activeMenu === 2 ? (
                data.data[activeMenu] ? <Three file={data.data[activeMenu]?.content.img} click={downModel} ev /> : null
              ): null
            }
          </div>
          <div className={styles.r}>
            {
              JSON.stringify(parts) !== '{}' ? (
                <>
                  <Tabs activeKey={activeGroup} onChange={(val)=>setActiveGroup(val)} moreIcon="...">
                    {
                      Object.keys(group).map((key: string)=>{
                        const item = group[key]
                        return (
                          <TabPane tab={item.label + (item.position_label ? '-' + item.position_label : '')} key={item.uuid}></TabPane>
                        )
                      })
                    }
                  </Tabs>
                  <div className={styles.part}>
                    {
                      parts[activeGroup]?.map((item: any, index: number)=>{
                        return (
                          <div className={styles.item} key={index}>
                            <div className={styles.itemL}>
                              <img src={item.img} alt={item.pid} />
                            </div>
                            <div className={styles.itemR}>
                              <h5>{item.label}</h5>
                              <div className={styles.itemRP}>
                                <label>{intl.formatMessage({id: 'partOe'})}</label>
                                {item.pid}
                              </div>
                              <div className={styles.itemRP}>
                                <label>{intl.formatMessage({id: 'price'})}</label>
                                {
                                  item.pid ? (
                                    item.progress ? (
                                      <div className={styles.polling}><span style={{width: item.progress * 20 + '%'}}></span></div>
                                    ) : (
                                      item.tag?.sale_price ? (
                                        <div className={styles.trendIcon}>{item.tag.sale_price} <i className="iconfont iconlingjianzhishu" onClick={()=>showTrend({pid: item.pid, part_name: item.label})} /></div>
                                      ) : (
                                        <span className={styles.link} onClick={()=>getPras(index, activeMenu)}>Get it</span>
                                      )
                                    )
                                  ): null
                                }
                              </div>
                              <div className={styles.itemRP}>
                                <label>{intl.formatMessage({id: 'quantity'})}</label>
                                {item.quantity.replace(/\<br\/\>/g, ' ')}
                              </div>
                              <div className={styles.itemRP}>
                                <label>{intl.formatMessage({id: 'remark'})}</label>
                                {item.remark.replace(/\<br\/\>/g, ' ')}
                              </div>
                              <div className={styles.itemRP}>
                                {item.pid ? (
                                  <div className={styles.operation}>
                                    <Tooltip overlayStyle={{zIndex: 9999}} mouseLeaveDelay={0} placement="top" overlay='Detail'>
                                      <span className="iconfont iconxiangqing" style={{fontSize: 14}} onClick={()=>setDetailPart(item)}></span>
                                    </Tooltip>
                                    <Tooltip overlayStyle={{zIndex: 9999}} mouseLeaveDelay={0} placement="top" overlay='Parts Price'>
                                      <Link to={`/partsprice?pid=${item.pid}`}><span className="iconfont iconbijia"></span></Link>
                                    </Tooltip>
                                    <Tooltip overlayStyle={{zIndex: 9999}} mouseLeaveDelay={0} placement="top" overlay='Favorite'>
                                      <span className={item.tag?.hasfavorite ? 'iconfont iconyishoucang1' : 'iconfont iconshoucangmoren'} style={{fontSize: 18}} onClick={()=>setFavourite(index)}></span>
                                    </Tooltip>
                                  </div>
                                ) : ''}
                              </div>
                            </div>
                          </div>
                        )
                      })
                    }
                    {
                      (!parts[activeGroup] || !parts[activeGroup].length) && !partsLoading ? (
                        <div style={{paddingTop: 60}}><Empty /></div>
                      ): null
                    }
                  </div>
                </>
              ): (
                <img src={sample} alt="sample" className={styles.sample} />
              )
            }
            
          </div>
        </div>
        
      </div>
      
      
      <Modal
        visible={trendVisible}
        cancel={() => setTrendVisible(false)}
        width={1000}
      >
        <div className={styles.trend}>
          <h5>{intl.formatMessage({id: 'priceTrend'})}</h5>
          <Loading visible={trendLoading}>
            {
              trendData.length ? <div className={styles.chart}><Trend data={trendData} /></div> : (
                <div className={styles.no}>{intl.formatMessage({id: 'noData'})}</div>
              )
            }
            
          </Loading>
        </div>
      </Modal>
      {
        partDetailVisible ? (
          <Modal
            title={intl.formatMessage({id: 'partDetail'})}
            visible={true}
            cancel={()=>{setPartDetailVisible(false); setDetailPart({})}}
            width={1000}
          >
            <PartDetail vin={vin} pid={detailPart.pid} brandCode={brandcode || vincode} mcid={mcid || vinmcid} mid={detailPart.mid} num={detailPart.num} queryType={vin ? 'vin' : 'cars'} />
          </Modal>
          
        ) : null
      }
    </>
  )
}

export default ThreeModel