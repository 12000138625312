export const cdnsPath = 'https://cdns.007vin.com'

export const getUrlParams = () => {
  let theRequest: any = {}
  const search = window.location.search
  if (search.indexOf("?") !== -1){
    let str = search.substr(1)
    let strs = str.split("&")
    for(var i = 0; i < strs.length; i ++) {
      theRequest[strs[i].split("=")[0]] = decodeURIComponent(strs[i].split("=")[1])
    }
  }
  return theRequest
}

export const compress = (img: any) => {
  let canvas = document.createElement('canvas')
  let ctx: any = canvas.getContext('2d')
  let width = img.width
  let height = img.height
  canvas.width = width
  canvas.height = height
  ctx.fillStyle = '#fff'
  ctx.fillRect(0, 0, canvas.width, canvas.height)
  ctx.drawImage(img, 0, 0, width, height)
  let ndata = canvas.toDataURL('image/jpeg', 0.5)
  return ndata
}
export const dataURItoBlob = (base64Data: string) => {
  var byteString
  if (base64Data.split(',')[0].indexOf('base64') >= 0) {
    byteString = atob(base64Data.split(',')[1])
  } else {
    byteString = unescape(base64Data.split(',')[1])
  }
  var mimeString = base64Data.split(',')[0].split(':')[1].split(';')[0]
  var ia = new Uint8Array(byteString.length)
  for (var i = 0; i < byteString.length; i++) {
    ia[i] = byteString.charCodeAt(i)
  }
  return new Blob([ia], { type: mimeString })
}
