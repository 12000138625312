import React, { useLayoutEffect, useRef, useState, useCallback } from "react";
import { ButtonProps } from "./interface";
import styles from './button.module.scss'

const rxTwoCNChar = /^[\u4e00-\u9fa5]{2}$/;
const isTwoCNChar = rxTwoCNChar.test.bind(rxTwoCNChar);

const Button: React.FC<ButtonProps> = (props) => {
  const { prefixCls = "button", className, style, children, type, shape, size, long, ghost, icon, loading, disabled, htmlType, onClick } = props;
  const [hasTwoCNChar, setHasTwoCNChar] = useState(false);
  const ref = useRef<HTMLButtonElement>(null);
  let classString = styles[`${prefixCls}`] + (className ? ' ' + className : '')
  if(!!type) {
    classString += ' ' + styles[`${prefixCls}-type-${type}`]
  }
  if(!!shape) {
    classString += ' ' + styles[`${prefixCls}-shape-${shape}`]
  }
  if(!!size) {
    classString += ' ' + styles[`${prefixCls}-size-${size}`]
  }
  if(disabled) {
    classString += ' ' + styles[`${prefixCls}-disabled`]
  }
  if(ghost) {
    classString += ' ' + styles[`${prefixCls}-ghost`]
  }
  if(loading) {
    classString += ' ' + [`${prefixCls}-loading`]
  }
  if(long) {
    classString += ' ' + styles[`${prefixCls}-long`]
  }
  if(hasTwoCNChar) {
    classString += ' ' + styles['two-chinese-chars']
  }
  const isNeedInserted = useCallback(() => {
    return React.Children.count(children) === 1 && !icon;
  }, [children, icon])
  const refEle = useCallback(() => {
    return ref.current
  }, [ref])
  useLayoutEffect(() => {
    const button = ref.current as HTMLButtonElement | null;
    if (!button) {
      return;
    }
    const text = button.textContent || button.innerText;
    if (isNeedInserted() && isTwoCNChar(text)) {
      if (!hasTwoCNChar) {
        setHasTwoCNChar(true);
      }
    } else {
      if (hasTwoCNChar) {
        setHasTwoCNChar(false);
      }
    }
  }, [refEle, hasTwoCNChar, isNeedInserted]);

  /* function isNeedInserted() {
    return React.Children.count(children) === 1 && !icon;
  } */

  function clickHandle(e: React.MouseEvent<HTMLButtonElement>) {
    if (!disabled && !loading && onClick) {
      onClick(e);
    }
  }

  return (
    <button ref={ref} type={htmlType} className={classString} style={style} disabled={disabled} onClick={clickHandle}>
      {loading ? <span className={`icon iconfont ` + styles['icon-loading'] + ' ' + styles[`${prefixCls}-icon`]} ></span> : icon && <span className={`icon iconfont ` + styles[`${prefixCls}-icon`]}>{icon}</span>}
      {children && <span>{children}</span>}
    </button>
  );
}

export default React.memo(Button);