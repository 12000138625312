import React, { useEffect, useState } from 'react'
import { useIntl } from 'react-intl'
import Modal from '@/components/Modal'
import styles from './guide.module.scss'

import guide_home_1 from '@/assets/images/guide_home_1.png'
import guide_home_2 from '@/assets/images/guide_home_2.png'
import guide_home_3 from '@/assets/images/guide_home_3.png'
import guide_home_4 from '@/assets/images/guide_home_4.png'
import guide_group_1 from '@/assets/images/guide_group_1.png'
import guide_group_2 from '@/assets/images/guide_group_2.png'
import guide_group_3 from '@/assets/images/guide_group_3.png'
import guide_group_4 from '@/assets/images/guide_group_4.png'
import guide_group_5 from '@/assets/images/guide_group_5.png'
import guide_price_1 from '@/assets/images/guide_price_1.png'
import guide_price_2 from '@/assets/images/guide_price_2.png'
import guide_price_3 from '@/assets/images/guide_price_3.png'

interface PropsIn {
  type: string
}



const Guide: React.FC<PropsIn> = (props) => {

  const intl = useIntl()
  const guidecache = JSON.parse(localStorage.getItem('guidecache') || '{}')
  
  const { type } = props
  const [ visible, setVisible ] = useState<boolean>(true)
  const [ count, setCount ] = useState<number>(0)
  const data: any = {
    home : {
      title: intl.formatMessage({id: 'howVin'}),
      note: [
        {
          label: intl.formatMessage({id: 'howVin1'}),
          img: guide_home_1
        },
        {
          label: intl.formatMessage({id: 'howVin2'}),
          img: guide_home_2
        },
        {
          label: intl.formatMessage({id: 'howVin3'}),
          img: guide_home_3
        },
        {
          label: intl.formatMessage({id: 'howVin4'}),
          img: guide_home_4
        }
      ]
    },
    group : {
      title: intl.formatMessage({id: 'findParts'}),
      note: [
        {
          label: intl.formatMessage({id: 'findParts1'}),
          img: guide_group_1
        },
        {
          label: intl.formatMessage({id: 'findParts2'}),
          img: guide_group_2
        },
        {
          label: intl.formatMessage({id: 'findParts3'}),
          img: guide_group_3
        },
        {
          label: intl.formatMessage({id: 'findParts4'}),
          img: guide_group_4
        },
        {
          label: intl.formatMessage({id: 'findParts5'}),
          img: guide_group_5
        }
      ]
    },
    price: {
      title: intl.formatMessage({id: 'howPart'}),
      note: [
        {
          label: intl.formatMessage({id: 'howPart1'}),
          img: guide_price_1
        },
        {
          label: intl.formatMessage({id: 'howPart2'}),
          img: guide_price_2
        },
        {
          label: intl.formatMessage({id: 'howPart3'}),
          img: guide_price_3
        }
      ]
    },
  }

  useEffect(() => {
    return () => {
      guidcache()
    }
  }, [])

  useEffect(() => {
    if(!visible){
      guidcache()
    }
  }, [visible])
  
  const guidcache = () => {
    guidecache[type] = true
    localStorage.setItem('guidecache', JSON.stringify(guidecache))
  }

  return (
    <Modal
      title={data[type]?.title}
      visible={visible}
      cancel={()=>setVisible(false)}
      width={460}
    >
      <div className={styles.guide}>
        <div className={styles.content}>
          <div className={styles.count}>
            <p style={{marginRight: 10}}><em>{count + 1}</em>/{data[type]?.note.length}</p>
            <p>{data[type]?.note[count].label}</p>
          </div>
          <div className={styles.image}>
            <img src={data[type]?.note[count].img}></img>
          </div>
        </div>
        <div className={styles.footer}>
          {
            count !== data[type]?.note.length - 1 ? <span onClick={()=>setVisible(false)}>{intl.formatMessage({id: 'noHelp'})}</span> : null
          }
          {
            count === data[type]?.note.length - 1 ? (
              <button onClick={()=>setVisible(false)}>{intl.formatMessage({id: 'understood'})}</button>
            ): (
              <button onClick={()=>setCount(count + 1)}>{intl.formatMessage({id: 'nextStep'})}</button>
            )
          }

        </div>
      </div>
    </Modal>
  )
}

export default Guide