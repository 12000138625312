import React, { useEffect, useState, useRef } from 'react'
import { useIntl } from 'react-intl'
import Menu from './Mymenu'
import styles from './my.module.scss'
import Input from '@/components/Input'
import Button from '@/components/Button'
import Spin from '@/components/Spin'
import Pagination from '@/components/Pagination'
import Toast from '@/components/Toast'
import Copy from '@/components/Copy'
import accountApi from '@/api/account'

interface pagerInt {
  page: number,
  pageSize: number,
  total: number
}

const Coupon: React.FC = () => {
  const intl = useIntl()
  const [keyword, setKeyword] = useState<string>("")
  const [list, setList] = useState<any[]>([])
  const [loading, setLoading] = useState<boolean>(false)
  const [pager, setPager] = useState<pagerInt>({
    page: 1,
    pageSize: 10,
    total: 0
  })
  const listRef = useRef<HTMLDivElement>(null)

  useEffect(() => {
    if(listRef.current) {
      listRef.current.scrollTop = 0
    }
    getList()
  },[pager])

  const getList = () => {
    setLoading(true)
    let param = JSON.parse(JSON.stringify(pager))
    Object.assign(param, {
      search: keyword || ''
    })
    delete param.total
    accountApi.getCoupon(param).then((res: any) => {
      setLoading(false)
      if(res.code === 1) {
        pager.page = res.page
        pager.total = res.total
        setList(res.data)
        // setList([...list])
      }
    })
  }
  const pageChange = (page: number, pageSize: number) => {
    if(page !== pager.page) {
      setPager({page, pageSize, total: pager.total})
    }
  }
  const search = () => {
    setPager({
      page: 1,
      pageSize: 10,
      total: 0
    })
  }
  const copy = (info: string) => {
    let oDiv = document.createElement('textarea')
    oDiv.value = info
    oDiv.innerHTML = info
    document.body.appendChild(oDiv)
    oDiv.select()
    document.execCommand("Copy")
    document.body.removeChild(oDiv)
    Toast('Copy succeeded！')
  }
  return (
    <Menu
      tab={5}
    >
      <div className={`${styles['coupon']}`}>
        <div className={styles['search']}>
          <Input placeholder={intl.formatMessage({id: 'enterContent'})} onChange={(val: string) => setKeyword(val)} value={keyword}  />
          <Button onClick={() => search()}><span className="iconfont iconsousuo"></span></Button>
        </div>
        <div className={styles['table']}>
          <Spin loading={loading}></Spin>
          {
            !list.length ? (
              <div className={styles['empty']}>
                <span className={'iconfont iconyouhuiquan'}></span>
                <p>{intl.formatMessage({id: 'noCoupons'})}</p>
              </div>
            ): (
              <>
                <table className={styles['table-header']}>
                  <thead>
                    <tr>
                      <th style={{width: "20%"}}>{intl.formatMessage({id: 'discountOperation'})}</th>
                      <th style={{width: "20%"}}>{intl.formatMessage({id: 'preferentialContent'})}</th>
                      <th style={{width: "20%"}}>{intl.formatMessage({id: 'serviceLife'})}</th>
                      <th style={{width: "20%"}}>{intl.formatMessage({id: 'user'})}</th>
                      <th style={{width: "20%"}}>{intl.formatMessage({id: 'remarks'})}</th>
                    </tr>
                  </thead>
                </table>
                <div className={styles['list']} ref={listRef} style={{paddingBottom: 40}}>
                  {
                    list.map((item, index) => {
                      return (
                        <div key={index} className={styles['item']}>
                          <span style={{width: "20%"}}>
                            {/* {item.coupon_num} <i  className="iconfont iconcopy"/> */}
                            <Copy
                              title={item.coupon_num}
                              icon={true}
                            />
                          </span>
                          <span style={{width: "20%"}}>{item.title}</span>
                          <span style={{width: "20%"}} className={styles['life']}>{item.valid_time_start} - {item.valid_time_end}</span>
                          <span style={{width: "20%"}}>{item.user_phone_ex || '-'}</span>
                          <span style={{width: "20%"}}>{item.remark}</span>
                        </div>
                      )
                    })
                  }
                </div>
              </>
            )
          }
          <div className={styles['pager']}>
            <Pagination
              pageSize={pager.pageSize}
              total={pager.total}
              current={pager.page}
              onChange={(page, pageSize) => pageChange(page, pageSize)}
            />
          </div>
        </div>
      </div>
    </Menu>
  )
}

export default Coupon