import React from "react";
import { InputGroupProps } from "./interface";
import styles from './input.module.scss'

const InputGroup: React.FC<InputGroupProps> = (props) => {
  const { prefixCls = "input-group", className, style, compact = false, children } = props;
  let classString = styles[`${prefixCls}`] + (className ? ' ' + className : '') 
  if(compact) {
    classString += ' ' + styles[`${prefixCls}-compact`]
  }

  return (
    <div className={classString} style={style}>
      {children}
    </div>
  );
}

export default React.memo(InputGroup);
