import React from 'react'
import { SpinProps } from './interface'
import styles from './spin.module.scss'

const Spin: React.FC<SpinProps> =(props)=> {
  const {loading} =props;
  if(loading){
    return(
      <div className={styles["spin_wrapper"]}>
        <p className={styles["click_block"]}/>
      </div>
      )
    }
  return <></>
}
export default React.memo(Spin);