import React from "react";
import { useControllableValue } from "ahooks";
import { InputProps } from "./interface";
import InputGroup from "./InputGroup";
import styles from './input.module.scss';

const Input = React.forwardRef((props: InputProps, ref: React.MutableRefObject<any> | any) => {
  const { prefixCls = "input", className, style, type = "text", defaultValue, size = "default", onChange, clearable = false, onClean, addonBefore, addonAfter, prefix, suffix, onBlur, ...genericProps } = props;
  const isControll = typeof(props.value) == 'undefined' ? false : true
  const [value, setValue] = useControllableValue<string>(props, {
    defaultValue: ''
  });
  let classString = styles[`${prefixCls}`] + (className ? ' ' + className : '')
  if(props.disabled) {
    classString += ' ' + styles[`${prefixCls}-disabled`]
  }
  if(size !== 'default') {
    classString += ' ' + styles[`${prefixCls}-${size}`]
  }

  function changeValue(val: string) {
    if (props.disabled) {
      return;
    }

    if (!isControll) {
      setValue(val);
    }

    if (onChange) {
      onChange(val);
    }
  }

  function changeHandle(event: React.ChangeEvent<HTMLInputElement>) {
    changeValue(event.target.value);
  }

  function blurHandle(e: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement>) {
    if (onBlur) {
      onBlur(e);
    }
    if (/webOS|iPhone|iPod/i.test(navigator.userAgent)) {
      // 移动端, 防止ios键盘底部突出
      document.body.scrollTop = 0;
      document.documentElement.scrollTop = 0;
    }
  }

  function cleanHandle() {
    changeValue("");
    if (onClean) {
      onClean();
    }
  }

  function cleanBtn() {
    return (
      <i className={styles[`${prefixCls}-clear`]} onClick={cleanHandle}>
        <i className="icon iconfont">&#xe7fc;</i>
      </i>
    );
  }

  function renderAffix(renderStyle?: boolean) {
    if (prefix || suffix || clearable) {
      const _suffix = clearable && value ? cleanBtn() : suffix;
      return (
        <div className={styles[`${prefixCls}-affix-wrapper`]} style={renderStyle ? style : {}} ref={ref}>
          {prefix && <span className={styles[`${prefixCls}-prefix`]}>{prefix}</span>}
          {renderInput()}
          {_suffix && <span className={styles[`${prefixCls}-suffix`]}>{_suffix}</span>}
        </div>
      );
    } else {
      return renderInput(true);
    }
  }
  
  
  function renderInput(renderStyle?: boolean) {
    if (renderStyle) {
      genericProps["style"] = style;
      genericProps["ref"] = ref;
    }
    return (
      <input 
      {...genericProps} 
      type={type} 
      {...(isControll || clearable ? { value: value || "" } : { defaultValue: value })} 
      aria-disabled={props.disabled} 
      className={classString} 
      onBlur={blurHandle} 
      onChange={changeHandle} 
      />
    )
  }

  if (addonBefore || addonAfter) {
    return (
      <div className={styles[`${prefixCls}-group-wrapper`]} style={style} ref={ref}>
        <InputGroup>
          {addonBefore && <span className={styles[`${prefixCls}-addon`]}>{addonBefore}</span>}
          {renderAffix()}
          {addonAfter && <span className={styles[`${prefixCls}-addon`]}>{addonAfter}</span>}
        </InputGroup>
      </div>
    );
  } else {
    return renderAffix(true);
  }
});

export default React.memo(Input);
