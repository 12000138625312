import React, { useEffect, useContext } from 'react'
import { useHistory } from 'react-router-dom'
import { useIntl } from 'react-intl'
import MainContext from '@/context/MainContext'
import axios from 'axios'
import Toast from '@/components/Toast'
const w: any = window

const otherCode = [1, 4005, 4001, 205, 10]

let ResInterceptors: React.FC = () => {
  const intl = useIntl()
  const history = useHistory();
  const { setStore } = useContext(MainContext)
  
  useEffect(()=>{
    init()
  },[])

  const init = () => {
    axios.interceptors.request.use((config: any) => {
      config.headers['language'] = w.hostname || 'en'
      if(/get/i.test(config.method)){
        const date: any = new Date()
        config.params = config.params || {}
        config.params.noCacheTime = Date.parse(date)/1000
      }
      return config;
    }, err => {
      return Promise.reject(err);
    })

    axios.interceptors.response.use((response) => {
      if(response.data && response.config){
        if(otherCode.indexOf(response.data.code) === -1 && !response.config?.url?.includes('/user/info') && !response.config?.url?.includes('/search/process') && response.data.msg && typeof response.data.msg === 'string') {
          Toast(response.data.msg, 'warning')
        }
  
        if(response.data.code === -999 || response.data.code === -998){
          setStore({value: response.config?.url?.includes('/search/process') ? intl.formatMessage({id: 'pleaseRegisterSignin'}) : true, type: 'purchaseVisible'})
        }
        
        if(response.data.code === 205 && !response.config?.url?.includes('/user/info') && !response.config?.url?.includes('/part/favourite/list')){
          setStore({value: true, type: 'loginVisible'})
        }
        if(response.data.code === 10){
          setStore({value: response.data.msgstr || intl.formatMessage({id: 'pleaseRegisterSignin'}), type: 'loginVisible'})
        }
      }
      return response.data || {};
    }, err => {
      return Promise.reject(err);
    })
  }

  return null
}

export default ResInterceptors;



