import React, { useEffect, useCallback } from "react";
import { useControllableValue } from "ahooks";
import { PaginationProps, SimplePageProps } from "./interface";
import PageInput from "./PageInput";
import Pager from "./Pager";
import SimplePage from "./SimplePage";
import { clamp } from './utils';
import styles from './pagination.module.scss'

const DefaultPageSizeOptions = [10, 20, 30, 40, 50];


const Pagination: React.FC<PaginationProps> = (props) => {
  const { prefixCls = "pagination", className, style, pageSizeOptions = DefaultPageSizeOptions, total = 0, showQuickJumper = false, showSizeChanger = false, simple = false, itemRender, onChange, onPageSizeChange } = props;
  const isControll = typeof(props.current) == 'undefined' ? false : true
  const [current, setCurrent] = useControllableValue<number>(props, {
    defaultValue: 1,
    valuePropName: 'current'
  });
  const isPageSizeControll = typeof(props.pageSize) == 'undefined' ? false : true
  const [pageSize, setPageSize] = useControllableValue<number>(props, {
    defaultValue: 1,
    valuePropName: 'pageSize'
  });
  const getPageCount = useCallback(() => {
    return Math.ceil(total / pageSize)
  }, [total, pageSize])
  const pageProps: SimplePageProps = { current, pageCount: getPageCount(), onChange: changeCurrent };

  function changeCurrent(num: number) {
    const pageNum = clamp(num, 1, total);
    if (!isControll) {
      setCurrent(pageNum);
    }

    if (onChange) {
      onChange(pageNum, pageSize);
    }
  }
  
  function changePageSize(e: React.FormEvent<HTMLSelectElement>) {
    let size = ((e.target) as any).value;
    if (!isPageSizeControll) {
      setPageSize(parseInt(size));
    }
    if (onPageSizeChange) {
      onPageSizeChange(parseInt(size));
    }
  }

  /* function getPageCount() {
    return Math.ceil(total / pageSize);
  } */
  
  useEffect(() => {
    const pageCount = getPageCount();
    if (current > getPageCount() && pageCount !== 0) {
      changeCurrent(getPageCount());
    }
  }, [pageSize, current, getPageCount]);

  function renderPager() {
    return (
      <React.Fragment>
        <Pager {...pageProps} itemRender={itemRender} />
        {showSizeChanger && (
          <select className={styles[`${prefixCls}-select`]} value={pageSize} onChange={changePageSize}>
            {pageSizeOptions.map((x) => (
              <option key={x} value={x} label={`${x} /page`}>
                {x} /page
              </option>
            ))}
          </select>
        )}
        {showQuickJumper && (
          <span className={styles[`${prefixCls}-jump`]}>
            Go to
            <PageInput {...pageProps} />
          </span>
        )}
      </React.Fragment>
    );
  }
  const classes = styles[`${prefixCls}`] + ' ' + className
  return (
    <div className={classes} style={style}>
      {simple ? <SimplePage {...pageProps} /> : renderPager()}
    </div>
  );
}

export default React.memo(Pagination);