import React, { useState, useReducer, useEffect } from 'react'
import { useLocation } from 'react-router-dom'
import accountApi from '@/api/account'
import Routes from '@/Routes'
import MainContext from '@/context/MainContext'
import ResInterceptors from '@/components/ResInterceptors'
import { Header, Search } from '@/page/Main'
import css from './main.module.scss'
import 'rc-tooltip/assets/bootstrap.css'
import 'rc-slider/assets/index.css'
import '@/assets/css/rcselect.css'


const reducer = (state: any, action: any) => {
  switch(action.type) {
    case 'userInfo':
      return {
        ...state,
        userInfo: action.value
      };
    case 'purchaseVisible':
      return {
        ...state,
        purchaseVisible: action.value
      };
    case 'loginVisible':
      return {
        ...state,
        loginVisible: action.value
      };
    case 'crumb':
      return {
        ...state,
        crumb: action.value
      };
    case 'watchTotal':
      return {
        ...state,
        watchTotal: action.value
      };
    case 'freecount':
      return {
        ...state,
        freecount: action.value
      };
    default:
      return state
  }
}

const Main: React.FC = () => {
  const other = ['/home', '/login', '/register', '/forgetPwd', '/tools/gltf']
  const otherSearch = ['/my/account', '/my/purchase', '/my/favorite', '/my/coupon', '/my/paypal', '/my/message', '/catalog']
  const { pathname } = useLocation()
  const [key, setKey] = useState<number>(1)
  const [store, setStore] = useReducer(reducer, {
    userInfo: {},
    purchaseVisible: false,
    loginVisible: false,
    crumb: JSON.parse(window.sessionStorage.getItem('crumb') || '[]'),
    watchTotal: 0,
    freecount: {}
  })
  
  useEffect(()=>{
    getUserInfo()
  },[])

  const update = () => {
    setKey(key+1)
  }

  const getUserInfo = () => {
    accountApi.getUserInfo().then((result: {code: number, data: object}) => {
      if(result.code === 1) {
        setStore({value: result.data, type: 'userInfo'})
        // getMsg(result.data)
        queryCount()
      }
    })
  }
  const getMsg = (userInfo: any) => {
    accountApi.msg().then((result: {code: number, data: object}) => {
      if(result.code === 1) {
        userInfo.msg = result.data
      }
      // console.info(userInfo)
      setStore({value: userInfo, type: 'userInfo'})
    })
  }

  const queryCount = () => {
    accountApi.queryCount().then((result: any)=>{
      if(result.code === 1 && result.data?.freecount) {
        setStore({value: result.data.freecount, type: 'freecount'})
      }
    })
  }

  return (
    <MainContext.Provider value={{store, setStore}}>
      <>
      <ResInterceptors />
      {  
        other.indexOf(pathname) === -1 && pathname !== '/' ? (
          <>
            
            
            <Header type='main' />
            {
              otherSearch.indexOf(pathname) === -1 ? (
                <div className={css['main-box']} >
                  <Search update={update} />
                </div>
              ): null
            }
            <Routes key={key} />
          </>
        ): (
          <Routes />
        )
      }
      <div><div className={css.test}></div></div>
      </>
    </MainContext.Provider>
  );
}

export default Main;