import axios from 'axios'

const catalogApi: any = {}

catalogApi.brands = (params: {}): Promise<{}> => {
  return axios.get("/v1/epc/cars/brands", {params})
}
catalogApi.title = (params: {}): Promise<{}> => {
  return axios.get("/v1/epc/cars/brand/title", {params})
}
catalogApi.models = (params: {}, cancelToken: any): Promise<{}> => {
  return axios.get("/v1/epc/cars/models", {...{params}, ...cancelToken})
}

export default catalogApi