import axios from 'axios';

const account: any = {
  banner: (params: {}): Promise<{}>=>{
    return axios.get('/v2/user/ugc/part/alias/banner', params)
  },
  register: (params: {}): Promise<{}> => {
    return axios.post('/v1/user/mregister', params)
  },
  login: (params: {}): Promise<{}> => {
    return axios.post('/v1/user/login', params)
  },
  logout: (params: {}): Promise<{}> => {
    return axios.post('/v1/api/user/logout', params)
  },
  sendVcode: (params: {}): Promise<{}> => {
    return axios.post('/v1/api/user/email', params)
  },
  resetPwd: (params: {}): Promise<{}> => {
    return axios.post('/v1/api/user/reset/pwd', params)
  },
  getUserInfo: (params: {}): Promise<{}> => {
    return axios.get("/v1/api/user/info", {params})
  },
  changeInfo: (params: {}): Promise<{}> => {
    return axios.post("/v1/user/profile_edit", params)
  },
  postUserInfo: (params: {}): Promise<{}> => {
    return axios.post("/v1/api/user/info", params)
  },
  getPackage: (params: {}): Promise<{}> => {
    return axios.get('/v1/api/common/member/package', {params})
  },
  getPackInfo: (params: {}): Promise<{}> => {
    return axios.get("/v1/user/showrolenew", {params})
  },
  buyPackage: (params: {}): Promise<{}> => {
    return axios.post("/v1/api/common/member/package/buy", params)
  },
  buyResult: (params: {}): Promise<{}> => {
    return axios.post("/v1/api/common/pay/result", params)
  },
  getFavorite: (params: {}): Promise<{}> => {
    return axios.get("/v1/user/part/favourite/list", {params})
  },
  delFavorite: (params: {}): Promise<{}> => {
    return axios.get("/v1/user/part/favourite/del", {params})
  },
  getCommodity: (params: {}): Promise<{}> => {
    return axios.get("/v1/order/classify/commodity", {params})
  },
  getCommodityVin: (params: {}): Promise<{}> => {
    return axios.get("/v1/order/classify/commodity/vin", {params})
  },
  checkCode: (params: {}): Promise<{}> => {
    return axios.get("/v1/pays/coupon/detail", {params})
  },
  usePayPal: (params: {}): Promise<{}> => {
    return axios.post("/v1/pays/paypal", params)
  },
  checkPayPal: (params: {}): Promise<{}> => {
    return axios.get("/v1/pays/paypal_check", {params})
  },
  favouriteAdd: (params: {}): Promise<{}> => {
    return axios.get("/v1/user/part/favourite/add", {params})
  },
  favouriteDel: (params: {}): Promise<{}> => {
    return axios.get("/v1/user/part/favourite/del", {params})
  },
  guide: (params: {}): Promise<{}> => {
    return axios.get("/v1/user/guide/daily", {params})
  },
  buymore: (params: {}): Promise<{}> => {
    return axios.get("/v1/user/guide/buymore", {params})
  },
  vinHistory: (params: {}): Promise<{}> => {
    return axios.get("/v1/user/history/vin", {params})
  },
  partsHistory: (params: {}): Promise<{}> => {
    return axios.get("/v1/parts/history", {params})
  },
  history: (params: {}): Promise<{}> => {
    return axios.get("/v1/user/history/vin_parts", {params})
  },
  vinHistoryAll: (params: {}): Promise<{}> => {
    return axios.get("/v1/user/history/vin/all", {params})
  },
  partHistoryAll: (params: {}): Promise<{}> => {
    return axios.get("/v1/user/history/part/all", {params})
  },
  vinHistoryBrand: (params: {}): Promise<{}> => {
    return axios.get("/v1/user/history/vin/brands", {params})
  },
  partHistoryBrand: (params: {}): Promise<{}> => {
    return axios.get("/v1/user/history/part/brands", {params})
  },
  getCoupon: (params: {}): Promise<{}> => {
    return axios.get("/v1/user/pays/coupon/list", {params})
  },
  getUserMsg: (params: {}): Promise<{}> => {
    return axios.get("/v1/user/msglocal", {params})
  },
  getFeedMsg: (params: {}): Promise<{}> => {
    return axios.get("/v1/user/msglocal/feedback", {params})
  },
  getFeedDetail: (params: {}): Promise<{}> => {
    return axios.get("/v1/userfeedback/detail", {params})
  },
  setFeedRead: (params: {}): Promise<{}> => {
    return axios.get("/v1/user/feedbackread", {params})
  },
  setLoaclRead: (params: {}): Promise<{}> => {
    return axios.get("/v1/user/msglocalread", {params})
  },
  editFeed: (params: {}): Promise<{}> => {
    return axios.get("/v1/userfeedback/edit", {params})
  },
  finshFeed: (params: {}): Promise<{}> => {
    return axios.get("/v1/userfeedback/finish", {params})
  },
  hasFree: (params: {}): Promise<{}> => {
    return axios.get("/v1/user/guide/hasfree", {params})
  },
  userfeedback: (params: {}): Promise<{}> => {
    return axios.post('/v1/userfeedback', params);
  },
  paypalOrder: (params: {}): Promise<{}> => {
    return axios.get('/v1/pays/subscribe_paypal_call', {params});
  },
  paycheck: (params: {}): Promise<{}> => {
    return axios.get('/v1/pays/subscribe_paycheck', {params});
  },
  getUserVins: (params: {}): Promise<{}> => {
    return axios.get("/v1/user/get_user_vins", {params})
  },
  addUserVins: (params: {}): Promise<{}> => {
    return axios.get("/v1/user/add_user_vins", {params})
  },
  cancelRenewal: (params: {}): Promise<{}> => {
    return axios.get("/v1/pays/cancel/subscribe", {params})
  },
  statistical: (params: {}): Promise<{}> => {
    return axios.get("/v1/api/channel/statistical", {params})
  },
  msg: (params: {}): Promise<{}> => {
    return axios.get("/v1/user/activity/msg", {params})
  },
  recommend: (params: {}): Promise<{}> => {
    return axios.get("/v1/api/home/data", {params})
  },
  getTrial: (params: {}): Promise<{}> => {
    return axios.get("/v1/api/permissions/active", {params})
  },
  getVedio: (params: {}): Promise<{}> => {
    return axios.get("/v1/api/vedio/introduce", {params})
  },
  sendInvite: (params: {}): Promise<{}> => {
    return axios.get("/v1/api/invite/send", {params})
  },
  getInvite: (params: {}): Promise<{}> => {
    return axios.get("/v1/api/invite/list", {params})
  },
  getConvert: (params: {}): Promise<{}> => {
    return axios.get("/v1/api/invite/convert/list", {params})
  },
  useConvert: (params: {}): Promise<{}> => {
    return axios.get("/v1/api/invite/convert", {params})
  },
  queryCount: (params: {}): Promise<{}> => {
    return axios.get("/v1/user/times/count", {params})
  },
}


export default account