import React, { useEffect, useRef, useState } from 'react'
import { withRouter } from 'react-router-dom'
import { useIntl } from 'react-intl'
import Menu from './Mymenu'
import Pagination from '@/components/Pagination'
import Spin from '@/components/Spin'
import Modal from '@/components/Modal'
import styles from './my.module.scss'
import accountApi from '@/api/account'
import msgTx from '@/assets/images/msg_tx.png'
import mePng from '@/assets/images/me.png'
import noMsg from '@/assets/images/nomsg.png'
import Button from '@/components/Button'

interface pagerInt {
  page: number,
  pageSize?: number,
  total?: number
}

const Message: React.FC = (props) => {
  const intl = useIntl()
  const [tab, setTab] = useState<number>(1)
  const [pageType, setPageType] = useState<number>(1)
  const [userList, setUserList] = useState<Array<any>>([])
  const [loading, setLoading] = useState<boolean>(false)
  const [change, setChange] = useState<boolean>(false)
  const [visible, setVisible] = useState<boolean>(false)
  const [sloading, setSloading] = useState<boolean>(false)
  const [detail, setDetail] = useState<any>({})
  const [pager, setPager] = useState<pagerInt>({
    page: 1,
    total: 0,
    pageSize: 10
  })
  const tabRef = useRef<any>(tab)
  const textareaRef = useRef<HTMLTextAreaElement>(null)

  useEffect(() => {
    if(tabRef.current && tabRef.current !== tab) pager.page = 1
    getList()
    tabRef.current = tab
  },[tab, pager])

  const getList = () => {
    setLoading(true)
    let api: any
    let param: pagerInt = JSON.parse(JSON.stringify(pager))
    delete param.total
    if(tab === 1) {
      api = accountApi.getUserMsg(param)
    }else if(tab === 2) {
      api = accountApi.getFeedMsg(param)
    }
    api && api.then((res: any) => {
      setLoading(false)
      if(res.code === 1) {
        // setUserList([{},{}])
        pager.total = res.total_count
        setUserList(res.data)
      }
    })
  }
  
  const pageChange = (page: number, pageSize: number) => {
    if(page !== pager.page) {
      setPager({page, pageSize, total: pager.total})
    }
  }
  const setRead = (item: {fid?: number, inid?: number, status: number}) => {
    let api: any 
    let fid: number = item.fid || 0
    let inid: number = item.inid || 0
    if(item.fid) {
      api = accountApi.setFeedRead({fid})
    }else if(item.inid) {
      api = accountApi.setLoaclRead({inid})
    }
    api.then((res: any) => {
      if(res.code === 1) {
        item.status = 2
        setChange(change => !change)
      }
    })
  }

  const getDetail = ({ fid }: any) => {
    setVisible(true)
    accountApi.getFeedDetail({fid}).then((res: any) => {
      if(res.code === 1) {
        setDetail(res.data)
      }else {
        setDetail({})
      }
    })
  }
  const submit = () => {
    let advise = textareaRef.current?.value
    let param = {
      advise,
      fid: detail.fid || '',
      imageData: ''
    }
    setSloading(true)
    accountApi.editFeed(param).then((res: any) => {
      setSloading(false)
      if(res.code === 1) {
        if(textareaRef.current) {
          textareaRef.current.value = ''
        }
        getDetail(detail)
        setPageType(1)
      }
    })
  }
  const finsh = () => {
    let fid = detail.fid
    accountApi.finshFeed({fid}).then((res: any) => {
      if(res.code === 1) {
        setVisible(false)
        setPageType(1)
      }
    })
  }
  return (
    <Menu tab={4} >
      <div className={styles['message']}>
        <ul className={styles['nav']}>
          <li className={`${tab === 1 ? styles['active'] : ''}`} onClick={() => setTab(1)}>{intl.formatMessage({id: 'message'})}</li>
          <li className={`${tab === 2 ? styles['active'] : ''}`} onClick={() => setTab(2)}>{intl.formatMessage({id: 'feedbackMessage'})}</li>
        </ul>
        <div className={styles['list']}>
          <Spin loading={loading}></Spin>
          {
            userList.length ? (
              userList?.map((item: any, index: number) => {
                let isClick: any = item.status === 1 ?  {
                  onClick: () => setRead(item)
                }: {}
                return (
                  <div className={styles['item']} key={index} {...isClick}>
                    <h3>
                      <span className={styles['title']}>{item.title}{item.status === 1 ? <span className={styles['dot']}></span> : null}</span>
                      <span className={styles['time']}>{item.magtime || item.updatetime}</span>
                    </h3>
                    <p>{item.msg_text || item.text}</p>
                    {tab === 2 && <span className={styles['detail']} onClick={() => {getDetail(item);setPageType(1)}} >{intl.formatMessage({id: 'detail'})}</span>}
                  </div>
                )
              })
            ): (
              <div className={styles['empty']}>
                <img src={noMsg} alt="" />
                <p>{intl.formatMessage({id: 'noNews'})}</p>
              </div>
            )
          }
        </div>
        <div className={styles['pager']}>
          <Pagination
            current={pager.page}
            total={pager.total}
            pageSize={pager.pageSize}
            onChange={(page, pageSize) => pageChange(page, pageSize)}
          />
        </div>
        <Modal
          visible={visible}
          cancel={setVisible}
          title={intl.formatMessage({id: 'feedbackDetails'})}
          width={520}
        >
          <div className={styles['feedback-details']}>
            <div className={styles['info']}>
              <img src={detail.feedback_imgurl} alt="" />
              <div className={styles['r']}>
                <p>{intl.formatMessage({id: 'feedbackStatus'})}: {detail.status ? <span style={{color: '#00B930'}}>{intl.formatMessage({id: 'solved'})}</span> : <span>{intl.formatMessage({id: 'pending'})}</span>}</p>
                <p>{intl.formatMessage({id: 'feedbackTime'})}: {detail.feedback_time}</p>
                <p>{intl.formatMessage({id: 'questionType'})}: {detail.advise_type}</p>
                <p>{intl.formatMessage({id: 'problemDescription'})}: {detail.advise || '-'}</p>
              </div>
            </div>
            {
              pageType === 1 ? (
                <>
                  <div className={styles['reply']}>
                    {
                      detail.reply?.map((item: any, index: number) => {
                        return (
                          <div key={index} className={`${item.type === 'add' ? styles['item'] + ' ' + styles['me'] : styles['item']}`}>
                            <img src={item.type !== 'add' ? msgTx : mePng} alt="" />
                            <div className={styles['cont']}>
                              <span>{item.time}</span>
                              <p>{item.content}</p>
                            </div>
                          </div>
                        )
                      })
                    }
                  </div>
                  {
                    !detail.status ? (
                      <div className={styles['func']}>
                        <Button onClick={() => setPageType(2)}>{intl.formatMessage({id: 'supplementaryQuestion'})}</Button>
                        <Button type="primary" onClick={() => finsh()}>{intl.formatMessage({id: 'endFeedback'})}</Button>
                      </div>
                    ): null
                  }
                </>
              ): pageType === 2 ? (
                <div className={styles['supple']}>
                  <h4>Supplementary questions:</h4>
                  <textarea ref={textareaRef} placeholder="Please enter your question！" name="" id="" ></textarea>
                  <div className={styles['btn']}>
                    <Button type="primary" loading={sloading} onClick={() => submit()}>{intl.formatMessage({id: 'submit'})}</Button>
                    <Button onClick={() => setPageType(1)}>{intl.formatMessage({id: 'cancel'})}</Button>
                  </div>
                </div>
              ): null
            }
          </div>
        </Modal>
      </div>
    </Menu>
  )
}
export default withRouter(Message)