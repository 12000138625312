import React, { useReducer, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { useIntl } from 'react-intl'
import { Header } from '@/page/Main'
import Button from '@/components/Button'
import Input, { InputPassword } from '@/components/Input'
import Toast from '@/components/Toast'
import loginLogo from '@/assets/images/login_logo.png'
import accountApi from '@/api/account'
import styles from './lrf.module.scss'

type ForgetPwdState = {
  username: string,
  password: string,
  verify_code: string
  c_password: string,
}

type ForgetPwdAction = 
| { type: "username", value: string }
| { type: "password", value: string }
| { type: "c_password", value: string}
| { type: "verify_code", value: string }

const reducer = (state: ForgetPwdState, action: ForgetPwdAction) => {
  switch(action.type) {
    case 'username':
      return {
        ...state,
        username: action.value
      };
    case 'password':
      return {
        ...state,
        password: action.value
      };
    case 'c_password':
      return {
        ...state,
        c_password: action.value
      };
    case 'verify_code':
      return {
        ...state,
        verify_code: action.value
      };
    default:
      return state
  }
}
const ForgetPwd: React.FC = () => {
  const history = useHistory()
  const intl = useIntl()
  const [formValue, setFormValue] = useReducer(reducer, {
    username: '',
    password: '',
    c_password: '',
    verify_code: ''
  })
  const [num, setNum] = useState<number>(60)
  const [isDown, setIsDown] = useState<boolean>(false)
  let timer: any;
  function forgetPwdSubmit(e: React.ChangeEvent<HTMLFormElement>) {
    e.preventDefault();
    if(!formValue.username) {
      Toast(intl.formatMessage({id: 'inputEmail'}), 'warning')
      return false
    }
    if(formValue.username.indexOf('@') === -1) {
      Toast(intl.formatMessage({id: 'emailCorrectly'}), 'warning')
      return false
    }
    if(!formValue.password) {
      Toast(intl.formatMessage({id: 'inputPassword'}), 'warning')
      return false
    }
    if(formValue.c_password !== formValue.password) {
      Toast(intl.formatMessage({id: 'passwordsInconsistent'}), 'warning')
      return false
    }
    accountApi.resetPwd({
      username: formValue.username,
      password: formValue.password,
      verify_code: formValue.verify_code
    }).then((res: any) => {
      if(res.code === 1) {
        history.push("/login")
      }
    })
  }
  function countDown() {
    accountApi.sendVcode({
      username: formValue.username,
      email_type: 'user_reset_pwd'
    }).then((res: any) => {
      if(res.code === 1) {

      }
    })
    setIsDown(true)
    downNum(num)
  }
  function downNum(n: number) {
    timer = window.setTimeout(() => {
      if(n > 0) {
        let _n = n - 1
        setNum(_n)
        downNum(_n)
      }else if(n <= 0) {
        window.clearTimeout(timer)
        setNum(60)
        setIsDown(false)
      }
    }, 1000)
  }
  const disabled = (formValue.username && formValue.username.indexOf("@") > 0 && !isDown) ? false : true
  const submitDisabled = (formValue.username && formValue.password && formValue.c_password && formValue.verify_code) ? false : true
  return (
    <div className={styles['LRF-page']}>
      <Header />
      <div className={styles['form-login']}>
        <div className={styles['form-wrap-img']}>
          <img src={loginLogo} alt="" />
        </div>
        <form className={styles['form-wrap']} name="forgetPwd" onSubmit={forgetPwdSubmit}>
          <div className={styles['form-item']}>
            <Input type="text" size="large" placeholder={intl.formatMessage({id: 'email'})} name="username" onChange={(value: string) => setFormValue({value, type: 'username'})} />
          </div>
          <div className={styles['form-item']}>
            <Input type="text" size="large" placeholder={intl.formatMessage({id: 'vCode'})} style={{width: 189}} name="verify_code" onChange={(value: string) => setFormValue({value, type: 'verify_code'})} />
            <Button type="primary" size="large" disabled={disabled} style={{marginLeft: 12, width: 119}} onClick={countDown}>{isDown ? intl.formatMessage({id:'countdown'}, {num: num}) : intl.formatMessage({id: 'getCode'})}</Button>
          </div>
          <div className={styles['form-item']}>
            <InputPassword size="large" placeholder={intl.formatMessage({id: 'nPassword'})} name="password" onChange={(value: string) => setFormValue({value, type: 'password'})}/>
          </div>
          <div className={styles['form-item']}>
            <InputPassword size="large" placeholder={intl.formatMessage({id: 'cNPassword'})} name="c_password" onChange={(value: string) => setFormValue({value, type: 'c_password'})}
            />
          </div>
          <div className={styles['form-item-btns']}>
            <Button type="primary" htmlType="submit" size="large" disabled={submitDisabled} style={{width: "100%"}}>{intl.formatMessage({id: 'submit'})}</Button>
          </div>
        </form>
      </div>
    </div>
  )
}
export default ForgetPwd