import React, { useState, useRef, useEffect } from 'react';
import css from './modal.module.scss';

interface PropsIn {
  title?: string,
  visible: boolean,
  cancel: Function,
  width?: number|string,
  className?: string
}

const Modal: React.FC<PropsIn> = (props) => {
  return (
    props.visible ? (
      <div className={css['model']}>
        <div className={props.className ? props.className + ' ' + css['model-box'] : css['model-box']} style={{width: props.width ? props.width : 500}}>
          <i className={`iconfont iconclose ${css['close-icon']}`} onClick={()=>props.cancel(false)}></i>
          {
            props.title ? <div className={css['model-box-header']}>
              <h6>{props.title}</h6>
            </div> : null
          }
          <div className={css['model-box-content']}>
            {props.children}
          </div>
        </div>
      </div>
    ): null
  )
}

export default Modal;