import React, { useState } from "react";
import { InputProps } from "./interface";
import Input from './Input'

const InputPassword = React.forwardRef((props: InputProps, ref: React.MutableRefObject<any> | any) => {
  const [visible, setVisible] = useState<boolean>(false)
  const uvSuffix = (
    <span className="icon iconfont" style={{cursor: "pointer"}} onClick={() => setVisible(true)}>&#xe8ff;</span>
  )
  const vSuffix = (
    <span className="icon iconfont" style={{cursor: "pointer"}} onClick={() => setVisible(false)}>&#xe78f;</span>
  )
  return (
    <Input 
    {...props}
    type={visible ? "text" : "password"}
    suffix={visible ? vSuffix : uvSuffix}
    ref={ref}
    />
  )
})

export default InputPassword
