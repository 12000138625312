import React, { useState, useEffect, useRef } from 'react'
import { v4 as uuidv4 } from 'uuid'
import Modal from '@/components/Modal'
import accountApi from '@/api/account'
import commonApi from '@/api/commonApi'
import css from './helpvideo.module.scss'

interface Parameter {
  visible: boolean,
  setVisible: Function,
  activeIndex: number,
  setActiveIndex: Function
}

const HelpVideo: React.FC<Parameter> = (props: Parameter) => {
  const videoRef = useRef<HTMLVideoElement>(null)
  const { visible, setVisible, activeIndex, setActiveIndex } = props
  const [ vedioData, setVedioData ] = useState<any>([])
  
  useEffect(()=>{
    getVedio()
    if(!window.localStorage.getItem('uuid')){
      window.localStorage.setItem('uuid', uuidv4())
    }
  },[])

  useEffect(()=>{
    if(!visible && vedioData.length){
      let param = {
        device_id: window.localStorage.getItem('uuid'),
        video_process: vedioData
      }
      commonApi.vedioMark(param).then()
    }
  },[visible])
  
  const getVedio = () => {
    accountApi.getVedio().then((result: any)=>{
      if(result.code === 1){
        setVedioData(result.data)
      }
    })
  }
  const setVedioTime = () => {
    vedioData[activeIndex].video_process = videoRef.current?.currentTime
  }
  return (
    <Modal
      title='Help Videos'
      visible={visible}
      cancel={()=>setVisible(false)}
      width={900}
    >
      <div className={css.vedio}>
        <ul>
          {
            vedioData.map((item: any, index: number)=>{
              return (
                <li className={index === activeIndex ? css.active : ''} onClick={()=>setActiveIndex(index)} key={index}>{item.title}</li>
              )
            })
          }
        </ul>
        <video ref={videoRef} width={880} height={464} src={vedioData[activeIndex]?.vedio_url} controls onTimeUpdate={()=>setVedioTime()}>
          Your browser does not support video。
        </video>
      </div>
    </Modal>
  )
}

export default HelpVideo