import axios from 'axios';

const searchApi: any = {}

searchApi.cars = (params: {}): Promise<{}>=>{
  return axios.get('/v1/api/epc/cars/associate', {params})
}
searchApi.process = (params: {}): Promise<{}>=>{
  return axios.get('/v1/api/search/process', {params})
}
export default searchApi