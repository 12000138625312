import axios from 'axios';

const vinApi: any = {}

vinApi.vin = (params: {}): Promise<{}>=>{
  return axios.get('/v1/api/epc/cars/vin', { params })
}
vinApi.group = (params: {}): Promise<{}>=>{
  return axios.get('/v1/api/epc/cars/group', { params })
}
vinApi.structure = (params: {}): Promise<{}>=>{
  return axios.get('/v1/api/epc/cars/structure', { params })
}
vinApi.part = (params: {}): Promise<{}>=>{
  return axios.get('/v1/api/epc/cars/part', { params })
}
vinApi.config = (params: {}): Promise<{}>=>{
  return axios.get('/v1/api/epc/config/search', { params })
}

vinApi.partSearch = (params: {}): Promise<{}>=>{
  return axios.get('/v1/api/epc/engine/parts/search_key', { params })
}
vinApi.partNameSearch = (params: {}): Promise<{}>=>{
  return axios.get('/v1/api/epc/engine/parts/search_key_name', { params })
}

vinApi.tag = (params: {}): Promise<{}>=>{
  return axios.get('/v1/epc/cars/part/tags', { params })
}
vinApi.partnew = (params: {}): Promise<{}>=>{
  return axios.get('/v1/api/epc/cars/partnew', { params })
}
vinApi.points = (params: {}): Promise<{}>=>{
  return axios.get('/v1/api/epc/cars/structimgs/points', { params })
}
vinApi.baseinfo = (params: {}): Promise<{}>=>{
  return axios.get('/v1/epc/parts/baseinfo', { params })
}
vinApi.replace = (params: {}): Promise<{}>=>{
  return axios.get('/v1/epc/parts/replace', { params })
}
vinApi.applicable = (params: {}): Promise<{}>=>{
  return axios.get('/v1/epc/parts/generacars', { params })
}
vinApi.ocr = (params: {}): Promise<{}>=>{
  return axios.post(vinApi.ocrPath, params)
}
vinApi.pricetrend = (params: {}): Promise<{}>=>{
  return axios.get('/v1/api/user/part/pricetrend', { params })
}

vinApi.recentPid = (params: {}): Promise<{}>=>{
  return axios.get('/v1/api/user/recent/pids', { params })
}
vinApi.recentVin = (params: {}): Promise<{}>=>{
  return axios.get('/v1/api/user/recent/vins', { params })
}
vinApi.findVin = (params: {}): Promise<{}>=>{
  return axios.get('/v1/api/search/vin', { params })
}


vinApi.ocrPath = '/v1/epc/vin/ocr'

export default vinApi