import React from 'react'
import { Chart, Geom, Tooltip, Line } from 'bizcharts'

interface PropsIn {
  data: {
    data: [],
    max: number,
    min: number
  }
}

const Trend: React.FC<PropsIn> = (props) => {
  const { data } = props
  console.info(data)
  return (
    <Chart
      autoFit
      scale={{ price: { min: data.min, max: data.max} }}
      data = {data.data}
    >
      <Line shape="smooth" position="date*price" color="price_type" />
      <Tooltip shared={true} showCrosshairs />
      <Geom
        type="point"
        position="date*price"
        size={4}
        shape={"circle"}
        color={"price_type"}
        style={{
          stroke: "#fff",
          lineWidth: 1,
        }}
      />
    </Chart>
  )
}

export default Trend