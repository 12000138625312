import React, { useState, useRef, useEffect, useContext } from 'react'
import Slider from 'rc-slider'
import * as THREE from 'three'
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls'
import { DRACOLoader } from 'three/examples/jsm/loaders/DRACOLoader'
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader'
import styles from './threeModel.module.scss'

interface PropsIn {
  file: string,
  bodyColor?: string,
  activeColor?: string,
  data?: [],
  setData?: Function,
  sceneBack?: Function,
  click?: Function,
  ev?: boolean
}

const Three: React.FC<PropsIn> = (props) => {
  let TV: any = useRef<any>({
    camera: null, 
    scene: null, 
    renderer: null, 
    mouseCoords: null, 
    raycaster: null,
    controls: null,
    color: [],
    moveState: false,
    raf: 1
  })

  const { file, bodyColor, activeColor, data, setData, sceneBack, click, ev } = props

  const modelRef = useRef<HTMLInputElement>(null)
  const progressRef = useRef<HTMLInputElement>(null)

  const [ sliderVal, setSliderVal ] = useState<number>(0)
  const [ progressVisible, setProgressVisible ] = useState<boolean>(true)

  const [ treeSwitch, setTreeSwitch ] = useState<boolean>(true)


  useEffect(()=>{
    // animate()
    window.addEventListener( 'resize', onWindowResize )

    init()
    return () => {
      // cancelAnimationFrame(TV.current.raf)
      TV.current.raf = null
      destroyed()
    }
  },[])

  const clearCache = (item: any) => {
    item.geometry?.dispose()
    item.material?.dispose()
    item.geometry = null
    item.material = null
  };

  const clearScene = () => {
    removeObj(TV.current.scene)
  };

  const removeObj = (obj: any) => {
    let arr = obj.children.filter((x: any) => x)
    arr.forEach((item: any) => {
      if (item.children.length) {
        removeObj(item)
      } else {
        clearCache(item)
        item.clear()
        item = null
      }
    })
    
    obj.clear()
    obj = null
    arr = null
  }

  const destroyed = () => {
    window.removeEventListener( 'resize', onWindowResize )
    clearScene()
    // TV.current.renderer.context.clear(16640)
    TV.current.renderer.renderLists.dispose()
    TV.current.renderer.dispose()
    TV.current.renderer.forceContextLoss()
    TV.current.renderer.domElement = null
    TV.current.renderer.content = null
    TV.current.renderer = null
    
    TV.current.scene.clear()
    
    THREE.Cache.clear()

    TV.current.camera = null
    TV.current.scene = null
    TV.current.mouseCoords = null
    TV.current.raycaster = null
    TV.current.controls = null
    if(modelRef.current){
      modelRef.current.remove()
    }
  }

  const init = () => {
    
    let model: any = {}
    if(modelRef.current){
      model = modelRef.current.getBoundingClientRect()
    }
    TV.current.camera = new THREE.PerspectiveCamera( 45, model.width / model.height, 0.1, 1000 )
    
    TV.current.camera.position.set( -18, 6, 18 )
    TV.current.camera.up.x = 0
    TV.current.camera.up.y = 1
    TV.current.camera.up.z = 0
    
    TV.current.scene = new THREE.Scene()
    TV.current.scene.background = new THREE.Color( 0xffffff )
    TV.current.scene.fog = new THREE.Fog( 0xffffff, 70, 100 )

    TV.current.mouseCoords = new THREE.Vector2()
    TV.current.raycaster = new THREE.Raycaster()

    const hemiLight = new THREE.HemisphereLight( 0xffffff, 0x444444, 0.6 )
    hemiLight.position.set( 0, 200, 0 )
    TV.current.scene.add( hemiLight )

    const dirLight = new THREE.DirectionalLight( 0xffffff, 0.8 )
    dirLight.position.set( 0, 20, 10 )
    TV.current.scene.add( dirLight )

    const dirLightR = new THREE.DirectionalLight( 0xffffff, 0.8 )
    dirLightR.position.set( 0, 20, -10 )
    TV.current.scene.add( dirLightR )

    const dracoLoader = new DRACOLoader()
    const loader = new GLTFLoader()

    dracoLoader.setDecoderPath('/portal/js/')
    dracoLoader.setDecoderConfig({type: 'js'})
    dracoLoader.preload()

    loader.setDRACOLoader(dracoLoader)
    loader.load( file, ( gltf ) => {
      if(!TV.current.raf){
        return
      }


      let modelTemp: any = []
      gltf.scene.traverse( ( child: any ) => {
        if(child.isMesh){
          
          if(sceneBack){
            let name = child.name
            if(name.indexOf('_') === 0){
              name = name.substr(1)
            }
            name = name.slice(0, 4)
            modelTemp.push(name)
          }
          child.material = child.material.clone()
          if(bodyColor){
            if(child.material.name.indexOf('cheshen') !== -1){
              child.material = new THREE.MeshPhysicalMaterial({
                color: `#${bodyColor}`,
                metalness: .9,
                roughness: .6,
                flatShading: true
              });
              child.geometry?.computeVertexNormals()
              child.material.shading = THREE.SmoothShading
            }
          }
          if(child.material.name.indexOf('deng') !== -1){
            child.material = new THREE.MeshPhongMaterial({
              color: `#111111`,
              opacity: 0.94,
              transparent: true
            })
          }
          if(child.material.name.indexOf('hei') !== -1){
            child.material = new THREE.MeshPhongMaterial({
              color: `#000000`
            })
          }
          if(child.material.name.indexOf('boli') !== -1){
            child.material = new THREE.MeshPhongMaterial({
              color: `#000000`,
              opacity: 0.6,
              transparent: true
            })
          }
        }
      })

      let box: any = new THREE.Box3().setFromObject( gltf.scene )
      let maxDiameter = Math.max((box.max.x - box.min.x), (box.max.y - box.min.y), (box.max.z - box.min.z));
			let scaleValue = TV.current.camera.position.z / maxDiameter
      if(!TV.current.raf){
        return
      }
      sceneBack && sceneBack(modelTemp)
      gltf.scene.scale.set(scaleValue, scaleValue, scaleValue)
      
      let modelBox3 = new THREE.Box3()
      modelBox3.expandByObject(gltf.scene)

      let modelWorldCenter = new THREE.Vector3().addVectors(modelBox3.max, modelBox3.min).multiplyScalar(0.5)
      let childBox = new THREE.Box3()
      
      gltf.scene.traverse( ( item: any ) => {
        if((item.isMesh) && item.name){
          childBox.setFromObject(item)
          let childCenter = new THREE.Vector3().addVectors(childBox.max, childBox.min).multiplyScalar(0.5)
          if(isNaN(childCenter.x)) return
          item.childCenter = new THREE.Vector3().subVectors(childCenter, modelWorldCenter).normalize()
          item.userData.position = item.position.clone()
        }
      })

      TV.current.scene.add( gltf.scene )
      setProgressVisible(false)
      render()
    }, (xhr) => {
      if(progressRef.current){
        let val = Math.ceil(xhr.loaded/xhr.total*100)
        val = val > 99 ? 99 : val
        progressRef.current.style.width = val + '%'
        progressRef.current.innerText = val + '%'
      }
      
    })

    if(!TV.current.raf){
      return
    }

    TV.current.renderer = new THREE.WebGLRenderer({ antialias: true })
    TV.current.renderer.setPixelRatio( window.devicePixelRatio )
    TV.current.renderer.setSize( model.width, model.height )
    TV.current.renderer.toneMapping = THREE.ACESFilmicToneMapping
    TV.current.renderer.toneMappingExposure = 1
    TV.current.renderer.outputEncoding = THREE.sRGBEncoding
    TV.current.renderer.shadowMap.enabled = true

    if(modelRef.current){
      // modelRef.current.appendChild( container )
      modelRef.current.appendChild( TV.current.renderer.domElement )
    }

    TV.current.controls = new OrbitControls( TV.current.camera, TV.current.renderer.domElement )
    TV.current.controls.enablePan = false
    TV.current.controls.minDistance = 5
    TV.current.controls.maxDistance = 50
    TV.current.controls.update()
    TV.current.controls.addEventListener('change', controlsChange)
    render()
  }

  const controlsChange = () => {
    TV.current.moveState = true
    render()
  }
  const animate = () => {
    TV.current.raf = requestAnimationFrame( animate )
    render()
  }

  const render = () => {
    if(TV.current.renderer){
      TV.current.renderer.render( TV.current.scene, TV.current.camera )
    }
  }
    
  const onWindowResize = () => {
    let model: any = {}
    if(modelRef.current){
      model = modelRef.current.getBoundingClientRect()
    }
    if(TV.current.camera){
      TV.current.camera.aspect = model.width / model.height
      TV.current.camera.updateProjectionMatrix()

      TV.current.renderer.setSize( model.width, model.height )

      render()
    }
  }
  const down = (event: any) => {
    event.preventDefault()
    TV.current.moveState = false
    animate()
  }
  const up = (event: any) => {
    cancelAnimationFrame(TV.current.raf)
    
    if(!TV.current.mouseCoords || TV.current.moveState) return
    
    let model: any = {}
    if(modelRef.current){
      model = modelRef.current.getBoundingClientRect()
    }
    
    TV.current.mouseCoords.set(
      ( (event.clientX - model.left) / model.width ) * 2 - 1,
      - ( (event.clientY - model.top) / model.height ) * 2 + 1
    )

    TV.current.raycaster.setFromCamera( TV.current.mouseCoords, TV.current.camera )
    let intersects = TV.current.raycaster.intersectObjects( TV.current.scene.children, true )
    
    if(intersects[0]){
      click && click(intersects, (TV.current.color[intersects[0].object.uuid]) ? false : true)
      if(TV.current.color[intersects[0].object.uuid]) {
        intersects[0].object.material.color.setStyle( TV.current.color[intersects[0].object.uuid] )
        delete TV.current.color[intersects[0].object.uuid]
      }else {
        TV.current.color[intersects[0].object.uuid] = intersects[0].object.material.color.getStyle()
        intersects[0].object.material.color.set( activeColor || `#1890FF` )
      }
      render()
    }
  }
  const changeslider = (value: any) => {
    setSliderVal(value)
    TV.current.scene.traverse((item: any) => {
      if(!item.isMesh || !item.childCenter) return
      item.position.copy(new THREE.Vector3().copy(item.userData.position).add(new THREE.Vector3().copy(item.childCenter).multiplyScalar(value * 2)))
    })
    render()
  }
  
  const changeTreeSwitch = (index: number) => {
    let temp = JSON.parse(JSON.stringify(data))
    temp[index].switch = temp[index].switch ? false : true
    setData && setData(temp)
  }
  const changeTreeCheck = (index: number, i?: number) => {
    let temp = JSON.parse(JSON.stringify(data))
    let val = (i === undefined ? (temp[index].check ? false : true) : (temp[index].subs[i].check ? false : true))
    if(i === undefined){
      temp[index].check = val
      temp[index].subs.forEach((s: any)=>{
        s.check = val
      })
    }else {
      temp[index].subs[i].check = val
      if(val){
        temp[index].check = val
      }else {
        let is = true
        temp[index].subs.forEach((s: any)=>{
          if(s.check){
            is = false
          }
        })
        if(is){
          temp[index].check = val
        }
      }
    }

    let id = (i === undefined ? temp[index].main_id : temp[index].subs[i].sub_id)
    TV.current.scene.traverse((item: any)=>{
      let name = item.name
      if(name.indexOf('_') === 0){
        name = name.substr(1)
      }
      name = name.slice(0, i === undefined ? 2 : 4)
      if(item.isMesh && name === id){
        item.material.visible = val
      }
    })
    render()
    setData && setData(temp)
  }
  return (
    <div className={styles.three}>
      <div className={styles.model} ref={modelRef} onPointerDown={(e: any)=>down(e)} onPointerUp={(e: any)=>up(e)} ></div>
      {
        progressVisible ? (
          <div className={styles.progress}>
            <div className={styles.polling}><span ref={progressRef} style={{width: 0}}></span></div>
          </div>
        ): (
          ev ? (
            <div className={styles.sliderBox}>
              <div className={styles.slider}>
                <Slider min={0} max={100} value={sliderVal} onChange={(val)=>changeslider(val)} />
              </div>
            </div>
          ): null
        )
      }
      {
        data && data.length ? (
          <>
            {
              !treeSwitch ? (
                <span className={styles.treeTools} onClick={()=>setTreeSwitch(true)}>
                  <i className='iconfont iconright' />
                </span>
              ): null
            }
            {
              treeSwitch ? (
                <div className={styles.tree}>
                  <span className={styles.tools} onClick={()=>setTreeSwitch(false)}>
                    <i className='iconfont iconleft' />
                  </span>
                  {
                    data.map((item: any, index: number)=>{
                      return (
                        item.show ? (
                          <div className={styles.item} key={index}>
                            <h6><i className={item.switch ? 'iconfont iconright' : 'iconfont icondown'} onClick={()=>changeTreeSwitch(index)} /> <label><input type='checkbox' checked={item.check} onChange={()=>changeTreeCheck(index)} /> {item.name}</label></h6>
                            {
                              item.switch ? (
                                <ul>
                                  {
                                    item.subs.map((sub: any, i: number)=>{
                                      return (
                                        sub.show ? <li key={i}><label><input type='checkbox' checked={sub.check} onChange={()=>changeTreeCheck(index, i)} /> {sub.name}</label></li> : null
                                      )
                                    })
                                  }
                                </ul>
                              ): null
                            }
                          </div>
                        ): null
                      )
                    })
                  }
                </div>
              ): null
            }
            
          </>
        ): null
      }
    </div>
    
  )
}

export default Three