import React from "react";
import { PageItemType, PagerProps } from './interface'
import { clamp } from './utils'
import styles from './pager.module.scss';

// 大于等于多少页时显示省略符号
const MaxEllipsisNumber: number = 7;
// 省略号相隔边界多个少元素
const EllipsisBoundDist: number = 2;

const Pager: React.FC<PagerProps> = (props) => {
  const { prefixCls = "page", current = 1, pageCount = 0, onChange, itemRender } = props;
  const jump = (page: number) => {
    const num = clamp(page, 1, pageCount);
    if (onChange) {
      onChange(num);
    }
  }

  const getPages = () => {
    let showPrevMore = false;
    let showNextMore = false;
    const array = [];
    if (pageCount === 0) {
      return [1];
    }
    // 总页数大于 MaxEllipsisNumber 则可以显示省略
    if (pageCount > MaxEllipsisNumber) {
      if (current > MaxEllipsisNumber - EllipsisBoundDist) {
        showPrevMore = true;
      }
      if (current < pageCount - EllipsisBoundDist) {
        showNextMore = true;
      }
    }
    if (showPrevMore && !showNextMore) {
      const startPage = pageCount - (MaxEllipsisNumber - EllipsisBoundDist);
      for (let i = startPage; i < pageCount; ++i) {
        array.push(i);
      }
    } else if (!showPrevMore && showNextMore) {
      for (let i = EllipsisBoundDist; i < MaxEllipsisNumber; ++i) {
        array.push(i);
      }
    } else if (showPrevMore && showNextMore) {
      const offset = Math.floor(MaxEllipsisNumber / EllipsisBoundDist) - 1;
      for (let i = current - offset; i <= current + offset; ++i) {
        array.push(i);
      }
    } else {
      for (let i = EllipsisBoundDist; i < pageCount; ++i) {
        array.push(i);
      }
    }
    return array;
  }
  const createPageItem = (current: number, type: PageItemType, content: React.ReactNode) => {
    if (itemRender) {
      return itemRender(current, type, content);
    } else {
      return content;
    }
  }
  const firstPageItem = () => {
    if (pageCount >= 1) {
      let classes = styles[`${prefixCls}-item`]
      if(current === 1) {
        classes += ' ' + styles[`${prefixCls}-active`]
      }
      return (
        <li 
          className={classes} 
          onClick={() => jump(1)} title="1"
        >
          {createPageItem(1, "page", <a>1</a>)}
        </li>
      );
    } else {
      return null;
    }
  }
  const lastPageItem = () => {
    if (pageCount > 1) {
      let classes = styles[`${prefixCls}-item`]
      if(current === pageCount) {
        classes += ' ' + styles[`${prefixCls}-active`]
      }
      return (
        <li 
          className={classes} 
          onClick={() => jump(pageCount)} title={`${pageCount}`}
        >
          {createPageItem(pageCount, "page", <a>{pageCount}</a>)}
        </li>
      );
    } else {
      return null;
    }
  }
  const prevMoreItem = () => {
    if (pageCount > MaxEllipsisNumber && current > MaxEllipsisNumber - EllipsisBoundDist) {
      return (
        <li 
          className={styles[`${prefixCls}-more`]} 
          title="Previous 5 Pages" 
          onClick={() => jump(current - 5)}
        >
          <a>
            <div className={styles[`${prefixCls}-item-container`]}>
              <span className={`icon iconfont ${styles['icon']}`}>&#xe7ef;</span>
              <span className={styles[`${prefixCls}-item-ellipsis`]}>•••</span>
            </div>
          </a>
        </li>
      );
    } else {
      return null;
    }
  }
  const nextMoreItem = () => {
    if (pageCount > MaxEllipsisNumber && current < pageCount - EllipsisBoundDist) {
      return (
        <li className={styles[`${prefixCls}-more`]} title="Next 5 Pages" onClick={() => jump(current + 5)}>
          <a>
            <div className={styles[`${prefixCls}-item-container`]}>
              <span className={`icon iconfont ${styles['icon']}`}>&#xe7f0;</span>
              <span className={styles[`${prefixCls}-item-ellipsis`]}>•••</span>
            </div>
          </a>
        </li>
      );
    } else {
      return null;
    }
  }
  let classesPrev = styles[`${prefixCls}-prev`]
  let classesNext = styles[`${prefixCls}-next`]
  if(current <= 1) {
    classesPrev += ' ' + styles[`${prefixCls}-disabled`]
  }
  if(current >= pageCount) {
    classesNext += ' ' + styles[`${prefixCls}-disabled`]
  }
  return (
    <ul className={styles[`${prefixCls}-pager`]}>
      <li className={classesPrev} onClick={() => jump(current - 1)} title="Previous Page">
        {createPageItem(
          0,
          "prev",
          <a className={styles[`${prefixCls}-item-link`]}>
            <span className="icon iconfont">&#xe7ec;</span>
          </a>,
        )}
      </li>
      {firstPageItem()}
      {prevMoreItem()}

      {getPages().map((page) => {
        let classes = styles[`${prefixCls}-item`]
        if(current === page) {
          classes += ' ' + styles[`${prefixCls}-active`]
        }
        return (
          <li key={page} onClick={() => jump(page)} className={classes} title="Page">
            {createPageItem(page, "page", <a>{page}</a>)}
          </li>
        )
      })}

      {nextMoreItem()}
      {lastPageItem()}
      <li className={classesNext} onClick={() => jump(current + 1)} title="Next Page">
        {createPageItem(
          0,
          "next",
          <a className={styles[`${prefixCls}-item-link`]}>
            <span className="icon iconfont">&#xe7eb;</span>
          </a>,
        )}
      </li>
    </ul>
  );
}

export default React.memo(Pager);