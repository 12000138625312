import React, {} from 'react'
import styles from './copy.module.scss'
import Toast from '@/components/Toast'

interface Iprops {
  title: any,
  icon?: boolean
}

const Copy: React.FC<Iprops> = (props) => {
  const { icon = false, title } = props
  const copy = () => {
    let oDiv = document.createElement('textarea')
    oDiv.value = title
    oDiv.innerHTML = title
    document.body.appendChild(oDiv)
    oDiv.select()
    document.execCommand("Copy")
    document.body.removeChild(oDiv)
    Toast('Copy succeeded！')
  }
  return (
    <span style={{cursor: 'pointer'}} onClick={() => copy()}>{title} {icon ? <i className="iconfont iconcopy" /> : null}</span>
  )
}

export default Copy

